import { ArrayHelper } from "../../../ArrayHelper/static/impl/ArrayHelper";
import { Datapoint } from "../../../Diagram/interfaces/Datapoints";
import { Constructor } from "../../interfaces/Constructor";
import { EventMember } from "../../../EventMember/interfaces/EventMember";
import { MemberType } from "../../interfaces/MemberType";
import { Method } from "../../interfaces/Method";
import { Numl } from "../../interfaces/Numl";
import { Property } from "../../interfaces/Property";
import { DefaultNuml } from "./DefaultNuml";
import { MemberParser } from "./MemberParser";

export class MutableNuml implements Numl {
  Name: string;
  Properties: Property[] = [];
  Events: EventMember[] = [];
  Methods: Method[] = [];
  Constructors: Constructor[] = [];
  Expands: string[];
  Position: Datapoint;

  public get Id(): string {
    return this.id;
  }

  private id: string;
  constructor(numl: Numl) {
    this.Name = numl.Name;
    numl.Properties.forEach((m) =>
      this.AddMemberByString(m.Name + ": " + m.ReturnType.FullType)
    );
    numl.Events.forEach((m) =>
      this.AddMemberByString(m.Name + "<= (" + m.ParamTypes.join(", ") + ")")
    );
    numl.Methods.forEach((m) =>
      this.AddMemberByString(
        m.Name +
          "(" +
          m.ParamTypes.map((p) => p.FullType).join(", ") +
          ")" +
          ":" +
          m.ReturnType.FullType
      )
    );
    numl.Constructors.forEach((m) => {
      if (m.ConcreteGenerics.length > 0) {
        this.AddMemberByString(
          m.Name +
            "(" +
            m.ParamTypes.map((p) => p.FullType).join(", ") +
            ")" +
            "<" +
            m.ConcreteGenerics.map((p) => p.FullType).join(", ") +
            ">"
        );
      } else {
        this.AddMemberByString(
          m.Name + "(" + m.ParamTypes.map((p) => p.FullType).join(", ") + ")"
        );
      }
    });
    this.Expands = [...numl.Expands];
    this.Position = { ...numl.Position };
    this.id = "numl-" + DefaultNuml.NextIdentifier++;
  }
  SwapPropertyToPrev = (index: number) =>
    ArrayHelper.SwapToPrev(this.Properties, index);
  SwapEventToPrev = (index: number) =>
    ArrayHelper.SwapToPrev(this.Events, index);
  SwapConstructorToPrev = (index: number) =>
    ArrayHelper.SwapToPrev(this.Constructors, index);
  SwapMethodToPrev = (index: number) =>
    ArrayHelper.SwapToPrev(this.Methods, index);

  // SwapPropertyToNext = (index: number) => ArrayHelper.SwapToNext(this.properties, index);
  SwapPropertyToNext(index: number) {
    ArrayHelper.SwapToNext(this.Properties, index);
  }
  SwapEventToNext = (index: number) =>
    ArrayHelper.SwapToNext(this.Events, index);
  SwapConstructorToNext = (index: number) =>
    ArrayHelper.SwapToNext(this.Constructors, index);
  SwapMethodToNext = (index: number) =>
    ArrayHelper.SwapToNext(this.Methods, index);

  public AddMember(
    member: Constructor | Property | Method | EventMember
  ): void {
    switch (member.Type) {
      case MemberType.Constructor:
        this.Constructors.push(member as Constructor);
        break;
      case MemberType.Property:
        this.Properties.push(member as Property);
        break;
      case MemberType.Method:
        this.Methods.push(member as Method);
        break;
      case MemberType.Event:
        this.Events.push(member as EventMember);
        break;
      default:
        throw new Error("unknown member");
    }
  }

  public Rename(newName: string): void {
    const splits: string[] = newName.trim().split(":");
    let expands: string[] = [];
    if (splits[1]) {
      expands = splits[1].split(",").map((s: string) => s.trim());
    }
    this.Name = splits[0];
    this.Expands = expands;
  }

  public AddMemberByString(s: string): void {
    let member:
      | Property
      | Constructor
      | Method
      | EventMember = MemberParser.ParseFromString(s);
    this.AddMember(member);
  }

  public MoveTo(x: number, y: number) {
    this.Position.x = x;
    this.Position.y = y;
  }

  public DeleteMember(
    member: Property | Constructor | Method | EventMember
  ): void {
    switch (member.Type) {
      case MemberType.Property:
        const prop: Property = member as Property;
        this.Properties = this.Properties.filter(
          (e) => e.Name !== prop.Name || e.ReturnType !== prop.ReturnType
        );
        break;
      case MemberType.Constructor:
        const ctor: Constructor = member as Constructor;
        this.Constructors = this.Constructors.filter(
          (e) => e.Name !== ctor.Name || e.ParamTypes !== ctor.ParamTypes
        );
        break;
      case MemberType.Method:
        const method: Method = member as Method;
        // tslint:disable-next-line: max-line-length
        this.Methods = this.Methods.filter(
          (e) =>
            e.Name !== method.Name ||
            e.ParamTypes !== method.ParamTypes ||
            e.ReturnType !== method.ReturnType
        );
        break;
    }
  }
}
