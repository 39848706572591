import React from "react";

interface IProps {
  title: string;
  subtitle: string;
}

export default (props: IProps) => (
  <div className="mainContent">
    <div className="hero terra-cotta">
      <h1>{props.title}</h1>
      <h3>{props.subtitle}</h3>
    </div>
  </div>
);
