import React from "react";
import "./App.css";
import Environment from "./Environment/view/Environment";
import Test from "./Test";

function App(): JSX.Element {
  return (
    <div className="App">
      {/*<Test /> */}
      <Environment />
    </div>
  );
}

export default App;
