import React from "react";

export default () => (
  <div className="indented section">
    Peer Nissen
    <br />
    Graumanssweg 49
    <br />
    Hamburg 22087
    <br />
    <br />
    info@peni4142.com
    <br />
    <br />
    peni4142.com
    <br />
    Umsatzsteueridentifikationsnummer: DE331 897 341 <br />
  </div>
);
