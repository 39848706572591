import React from "react";
import ReorderableMember from "../../Numl/view/ReorderableMember/ReorderableMember";
import LazyInput from "../../Controls/view/LazyInput/LazyInput";
import SingleLineEditor from "../../Controls/view/SingleLineEditor/SingleLineEditor";
import { Datapoint } from "../../Diagram/interfaces/Datapoints";
import { AddCircle } from "../../VisualElements/view/Elements";
import { DefaultDragHandler } from "../../Environment/handler/DefaultDragHandler";
import { DragHandler } from "../../Environment/interface/DragHandler";
import { DatapointObserver } from "../../Environment/model/DatapointObserver";
import "./ViewDefinition.css";
import { ObserveableViewDefinition } from "../interfaces/ObserveableViewDefinition";

interface IProps {
  view: ObserveableViewDefinition;
  Delete: () => void;
}
interface IState {
  mode: string;
}
export default class ViewDefinitionView extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      mode: "string",
    };
    this.dragHandler = new DefaultDragHandler(
      new DatapointObserver(
        () => {
          this.setState({ mode: "grab" });
          this.props.view.RaiseNext();
        },
        this.move.bind(this),
        undefined
      )
    );
  }
  private dragHandler: DragHandler;
  private offset: Datapoint = {
    x: 0,
    y: 0,
  };

  handleMouseDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    this.offset = {
      x: this.props.view.Position.x,
      y: this.props.view.Position.y,
    };
    this.setState({ mode: "grabbing" });
    this.dragHandler.handleDrag(event);
  }

  MoveTo(x: number, y: number): void {
    this.element = this.getElement();
    this.element.style.left = x + "px";
    this.element.style.top = y + "px";
  }

  move(delta: Datapoint): void {
    this.offset.x = this.offset.x - delta.x;
    this.offset.y = this.offset.y - delta.y;

    this.element = this.getElement();
    this.element.style.left = this.offset.x + "px";
    this.element.style.top = this.offset.y + "px";

    this.props.view.MoveTo(this.offset.x, this.offset.y);
  }

  private element: HTMLElement | null = null;
  private getElement(): HTMLElement {
    if (this.element) {
      // this.descriptiveData.width = this.element.offsetWidth;
      return this.element;
    }
    this.element = document.getElementById(this.props.view.Id);
    if (this.element) {
      // this.descriptiveData.width = this.element.offsetWidth;
      return this.element;
    } else {
      throw new Error("Element with Id " + this.props.view.Id + " not found");
    }
  }

  render() {
    return (
      <div
        className="View DragableItem"
        id={this.props.view.Id}
        style={{
          left: this.props.view.Position.x + "px",
          top: this.props.view.Position.y + "px",
        }}
      >
        <div
          className={"header " + this.state.mode}
          onMouseDown={this.handleMouseDown.bind(this)}
        >
          <SingleLineEditor
            Delete={() => this.props.Delete()}
            OnDidChange={(s: string) => {
              this.props.view.Rename(s);
              this.forceUpdate();
            }}
            Title={<div className="title">{this.props.view.Name}</div>}
            preSet={this.props.view.Name}
          />
        </div>
        <div className="section Type">
          {this.props.view.Dependencies.map((s, index) => (
            <ReorderableMember
              SwapToNext={() => this.props.view.SwapValueToNext(index)}
              SwapToPrev={() => this.props.view.SwapValueToPrev(index)}
              Member={
                <SingleLineEditor
                  Delete={() => this.props.view.Remove(s)}
                  OnDidChange={(value: string) => {
                    this.props.view.ChangeDependency(s, value);
                    this.forceUpdate();
                  }}
                  Title={s.FullType}
                  preSet={s.FullType}
                />
              }
            />
          ))}
        </div>
        <LazyInput
          yieldMode={true}
          label={<AddCircle color="white" />}
          onSubmit={(s: string) => this.props.view.Add(s)}
        />
      </div>
    );
  }
}

