import React from "react";
import { DiagramProvider } from "../../../DiagramProvider/interfaces/DiagramProvider";
import { LocalStorageDiagramProvider } from "../../../DiagramProvider/model/impl/LocalStorageDiagramProvider";
import { Environment } from "../../../Environment/model/Environment";
import { JsonParser } from "../../../JsonParser/interfaces/JsonParser";
import { DefaultJsonParser } from "../../../JsonParser/model/impl/DefaultJsonParser";
import { DefaultHorizontalLayer } from "../../../Layer/model/impl/DefaultHorizontalLayer";
import { DefaultVerticalLayer } from "../../../Layer/model/impl/DefaultVerticalLayer";
import PageBuilder from "../../../Page/view/PageBuilder";
import Thumbnail from "../../../Thumbnail/view/Thumbnail";
import { Diagram } from "../../interfaces/Diagram";
import { DefaultDiagram } from "../../model/impl/DefaultDiagram";
import { AddCircle } from "../../../VisualElements/view/Elements";
import AddItemm from "../AddItem/AddItem";
import "./Diagrams.css";

interface IProps {
  setPage: (e: JSX.Element) => void;
  getDiagramPage: (d: Diagram, save: (diagram: Diagram) => void) => JSX.Element;
}

interface IState {}

export default class DiagramView extends React.Component<IProps, IState> {
  private diagramProvider: DiagramProvider;
  private jsonParser: JsonParser;

  constructor(props: IProps) {
    super(props);
    // this.diagramProvider = new DummyDiagramProvider();
    this.jsonParser = new DefaultJsonParser();
    this.diagramProvider = new LocalStorageDiagramProvider();
  }

  AddDiagram() {
    const p: {
      foo(s: string): void;
      title: string;
    } = {
      foo: (s: string) => {
        let diagram = new DefaultDiagram(s);
        diagram.AddHorizontalLayer(
          new DefaultHorizontalLayer("Technical Layer", 600)
        );
        diagram.AddVerticalLayer(
          new DefaultVerticalLayer("Domain Layer", 600)
        );
        this.diagramProvider.AddDiagram(diagram);
        this.forceUpdate();
      },
      title: "Add diagram",
    };
    let closeFoo: () => void = PageBuilder.OpenPenetratingWindow(
      <AddItemm AddItem={(s: string) => p.foo(s)} />,
      p.title,
      300
    );
    let extendCloseFoo = (foo: (...params: any) => void) =>
      Environment.CombineFoos(closeFoo, foo);
    p.foo = extendCloseFoo(p.foo);
  }

  private async handleDrop(
    event: React.DragEvent<HTMLDivElement>
  ): Promise<void> {
    this.preventStandard(event);
    if (event.dataTransfer.files.length !== 1) {
      alert("Can only handle the upload of one file");
    }
    let text: string = (await event.dataTransfer.files
      .item(0)
      // @ts-ignore
      ?.text()) as string;
    let diagram: Diagram = this.jsonParser.ParseFromJsonString(text);
    this.diagramProvider.AddDiagram(diagram);
    this.forceUpdate();
  }

  private preventStandard(event: React.DragEvent<HTMLDivElement>): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private HandleRename(s: string, d: Diagram) {
    d.Name = s;
    this.diagramProvider.AddDiagram(d);
    this.diagramProvider.DeleteDaiagram(d);
    this.forceUpdate();
  }

  private HandleDelete(d: Diagram) {
    this.diagramProvider.DeleteDaiagram(d);
    this.forceUpdate();
  }

  render() {
    return (
      <div
        className="Diagrams"
        onDrop={(e) => this.handleDrop(e)}
        onDragEnter={(e) => this.preventStandard(e)}
        onDragExit={(e) => this.preventStandard(e)}
        onDragOver={(e) => this.preventStandard(e)}
      >
        <AddCircle Action={() => this.AddDiagram()} color="white" />
        <div className="diagrams">
          {this.diagramProvider.GetDiagrams().map((d, i) => (
            <Thumbnail
              key={i}
              Delete={() => this.HandleDelete(d)}
              OnClick={() =>
                this.props.setPage(
                  this.props.getDiagramPage(d, (diagram: Diagram) =>
                    this.diagramProvider.AddDiagram(diagram)
                  )
                )
              }
              Rename={(s: string) => this.HandleRename(s, d)}
              Title={d.Name}
            />
          ))}
        </div>
      </div>
    );
  }
}
