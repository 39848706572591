import React from "react";
import Header from "../../Controls/view/Header/Header";
import { Datapoint } from "../../Diagram/interfaces/Datapoints";
import { Cancel } from "../../VisualElements/view/Elements";
import "./Window.css"


interface IProps {
    close: () => void;
    element: JSX.Element;
    positioning?: Datapoint;
    width?: number | string;
    className?: string;
    position?: "static" | "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "fixed" | "-webkit-sticky" | "absolute" | "relative" | "sticky" | undefined;
    title: string
}

interface IState {

}

export default class Window extends React.Component<IProps, IState>{

    render() {

        let style: React.CSSProperties = {};
        if (this.props.positioning) {
            style = {
                ...style, ...{ top: this.props.positioning?.y, left: this.props.positioning?.x }
            }
        }
        if (this.props.width) {
            style = {
                ...style, ...{ width: this.props.width }
            }
        }
        if (this.props.position) {
            style = {
                ...style, ...{ position: this.props.position }
            }
        }

        function handleOnClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
            e.stopPropagation();
        }
        return (
            <div className={this.props.className? this.props.className: "Window"} style={style} onClick={(e) => handleOnClick(e)}>
                <Header left={this.props.title.replace(/ /g, "\u00a0")} right={<Cancel Action={() => this.props.close()} />} />
                { this.props.element}
            </div>
        )
    }
}