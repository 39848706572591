import React from "react";
import ChoiceButtonListItem from "./ChoiceButtonListItem";
import { Action } from "../../interfaces/Action";

// tslint:disable-next-line: interface-name
interface Props {
    Actions:Action[];
}

export default (props: Props): JSX.Element =>
    <div className="ChoiceButtonList">
        {props.Actions.map((action, i)=> <ChoiceButtonListItem key={i} Action={action} />)}
    </div>;