import React from "react";
import "./Environment.css";

import PageBuilder from "../../Page/view/PageBuilder";
import Diagrams from "../../Diagram/view/Diagrams/Diagrams";
import Impressum from "../../Information/view/Impressum";
import { Diagram } from "../../Diagram/interfaces/Diagram";
import Datenschutzerklärung from "../../Information/view/Datenschutzerklärung";
import PrivacyPolicy from "../../Information/view/PrivacyPolicy";
import Payment from "../../Payment/view/Payment";
import HowTo from "../../Information/view/HowTo";
import FeatureSite from "../../Information/view/FeatureSite";
import Changelog from "../../Information/view/Changelog";
import Documentation from "../../Documentation/view/Documentation";

interface IProps {}
interface IState {
  view: JSX.Element;
}

export default class Environment extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    let backAction = () =>
      this.setPage(
        this.pageBuilder.PageWithMenu(
          <Diagrams
            setPage={(e) => this.setPage(e)}
            getDiagramPage={(d: Diagram, save: (diagram: Diagram) => void) =>
              this.pageBuilder.DiagramPage(d, save, backAction)
            }
          />,
          "Diagrams"
        )
      );
    this.pageBuilder = new PageBuilder([
      {
        Label: "Home",
        Action: () =>
          this.setPage(
            this.pageBuilder.PageWithMenu(
              <FeatureSite
                watchVideo={() =>
                  this.setPage(
                    this.pageBuilder.PageWithMenu(<HowTo />, 'How-to "numl"')
                  )
                }
              />,
              "FeatureSite"
            )
          ),
      },
      /*{
        Label: "About",
        Action: () =>
          this.setPage(this.pageBuilder.PageWithMenu(<About />, "About")),
      }, */
      {
        Label: "Diagrams",
        Action: backAction,
      },
      {
        Label: "Documentation",
        Action: () =>
          this.setPage(
            this.pageBuilder.PageWithMenu(<Documentation />, "Documentation")
          ),
      },
      {
        Label: "Changelog",
        Action: () =>
          this.setPage(
            this.pageBuilder.PageWithMenu(<Changelog />, "Changelog")
          ),
      },
      {
        Label: 'How-to "NUML"',
        Action: () =>
          this.setPage(
            this.pageBuilder.PageWithMenu(<HowTo />, 'How-to "NUML"')
          ),
      },
      {
        Label: "Privacy Policy",
        Action: () =>
          this.setPage(
            this.pageBuilder.PageWithMenu(
              <PrivacyPolicy />,
              "Datenschutzerklärung"
            )
          ),
      },
      {
        Label: "Datenschutzerklärung",
        Action: () =>
          this.setPage(
            this.pageBuilder.PageWithMenu(
              <Datenschutzerklärung />,
              "Datenschutzerklärung"
            )
          ),
      },
      {
        Label: "Impressum",
        Action: () =>
          this.setPage(
            this.pageBuilder.PageWithMenu(<Impressum />, "Impresum")
          ),
      },
    ]);
    this.state = {
      view: this.pageBuilder.PageWithMenu(
        <FeatureSite
          watchVideo={() =>
            this.setPage(
              this.pageBuilder.PageWithMenu(
                <HowTo autorun={true} />,
                'How-to "numl"'
              )
            )
          }
        />,
        "FeatureSite"
      ),
    };

    // this.state = {
    //   view: this.pageBuilder.PageWithMenu(<Documentation />, "Documentation"),
    // };
  }
  private pageBuilder: PageBuilder;

  private setPage(page: JSX.Element) {
    this.setState({ view: page });
  }

  render(): JSX.Element {
    return <div className="Environment">{this.state.view}</div>;
  }
}
