import { Observer } from "../interface/Observer";
import { Datapoint } from "../../Diagram/interfaces/Datapoints";





export class DatapointObserver implements Observer<Datapoint>{
    constructor(onComplete: (() => void) | undefined, onNext: ((datapoint: Datapoint) => void) | undefined, onError: ((err: Error) => void) | undefined) {
        onComplete ? this.Complete = onComplete : this.Complete = () => { };
        onNext ? this.Next = onNext : this.Next = () => { };
        onError ? this.Error = onError : this.Error = () => { }
    }
    Complete: () => void;
    Next: (datapoint: Datapoint) => void;
    Error: (err: Error) => void
}