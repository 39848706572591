import React from "react";
import "./Information.css"

interface IProps {
	watchVideo():void;
}

export default (props: IProps) =>
    <div className="mainContent FeatureSite">
        <div className="hero terra-cotta">
            <h1>NUML - No Unified Modeling Language</h1>
            <h3>Plan your software architecture ahead of time</h3>
        </div>

        <div className="section">
            <h1>Key features</h1>

            <div className="feature-list">
                <div className="feature">
                    <div className="icon">
                        <span className="material-icons">insert_photo</span>
                    </div>
                    <div className="details">
                        <div className="name">
                            Plan and communicate your solutions effectively
                        </div>
                        <div className="description">
				Do less, get more. Even compared to the code, write fewer definitions and get a diagram on top. The diagram supports communication in a team and is a part of the documentation.
                        </div>
                    </div>
                </div>
                <div className="feature">
                    <div className="icon">
                        <span className="material-icons">code</span>
                    </div>
                    <div className="details">
                        <div className="name">
                            Translate your diagrams into code
                        </div>
                        <div className="description">
				DRY - Don't repeat yourself! What is defined in the diagram already will resolve into code.
			</div>
                    </div>
                </div>
                <div className="feature">
                    <div className="icon">
                        <span className="material-icons">flag</span>
                    </div>
                    <div className="details">
                        <div className="name">
                            Standardize the planning and development of your software
                        </div>
                        <div className="description">
				The standardization to use the layer model in all plannings will shorten the setting-in period of workmates of other teams.
			</div>
                    </div>
                </div>
                <div className="feature">
                    <div className="icon">
                        <span className="material-icons">sentiment_very_satisfied</span>
                    </div>
                    <div className="details">
                        <div className="name">
				KISS - Keep it simple stupid
			</div>
                        <div className="description">
				The use of diagrams is reduced to the essential points of planning, the interacting parts
			</div>
			</div>
                </div>
            </div>
        </div>

        <div className="hero khaki-web">
            <div className="showcase">
                <div className="details">
			<p className="text-wrapper">
				Build your first diagram today. A video will show you how to create professional diagrams easily. That will take only ~10 minutes.
				After that, you will save much time by not using overly complicated tools, Paint, PowerPoint, or similar.
				<br/>
				
				Discover design errors before programming them. So you will not have to rewrite finished implementations or be locked in, in a bad architecture.
				<br />
				If you have written TypeScript or C# before, you will feel very confident with the used syntax. For defining a property, you write "Prop: Type", for a method, you write "MethodName(ParamType1, ParamType2): ReturnType". To adding Enums, Views, Classes/Interfaces, right-click in the diagram and add it. For adding dimensions, click the add button at the end of a dimension.
			</p>
			<button className="terra-cotta primary" onClick={()=> props.watchVideo()}>watch video</button>
                </div>
                <div className="avatar"></div>
            </div>
        </div>

        <div className="section">
            <h1>Planned features</h1>

            <div className="feature-list">
                <div className="feature">
                    <div className="icon">
                        <span className="material-icons">insert_photo</span>
                    </div>
                    <div className="details">
                        <div className="name">
                            Custom and further views
                        </div>
                        <div className="description">
                            The user should be able to provide a file for views that will serve as a pattern for
                            the
                            export. Also, React, and Vue should be supported by the standard.
                        </div>
                    </div>
                </div>
                <div className="feature">
                    <div className="icon">
                        <span className="material-icons">edit</span>
                    </div>
                    <div className="details">
                        <div className="name">
                            Renaming Dependencies
                        </div>
                        <div className="description">
                            So far, only the element that is being edited has been renamed. The possibility
                            should
                            be added that the objects that are dependent are also renamed.
                        </div>
                    </div>
                </div>
                <div className="feature">
                    <div className="icon">
                        <span className="material-icons">layers</span>
                    </div>
                    <div className="details">
                        <div className="name">
                            Collapsing Layers
                        </div>
                        <div className="description">
                            For a better overview, it should be possible to minimize layers so that a better
                            overview is possible for larger projects.
                        </div>
                    </div>
                </div>
                <div className="feature">
                    <div className="icon">
                        <span className="material-icons">article</span>
                    </div>
                    <div className="details">
                        <div className="name">
                            In code documentation
                        </div>
                        <div className="description">
                            This should enable comments on members of the diagram. This should resolve into
                            comments
                            in the standard way of the respective languages. Thinking further, other tools can
                            be
                            used to generate technical documentation from the code.
                        </div>
                    </div>
                </div>
                <div className="feature">
                    <div className="icon">
                        <span className="material-icons">fast_rewind</span>
                    </div>
                    <div className="details">
                        <div className="name">
                            Step forward / back
                        </div>
                        <div className="description">
                            Implement Ctrl + Z and Ctrl + Y
                        </div>
                    </div>
                </div>
                <div className="feature">
                    <div className="icon">
                        <span className="material-icons">import_export</span>
                    </div>
                    <div className="details">
                        <div className="name">
                            Own language imports
                        </div>
                        <div className="description">
                            For example, if the return value of a function is "ICollection", the using directive
                            is
                            not set. The user can quickly fix this himself, but it would still be nice if such
                            using
                            directives were set automatically.
                        </div>
                    </div>
                </div>

                <div className="feature">
                    <div className="icon">
                        <span className="material-icons">flag</span>
                    </div>
                    <div className="details">
                        <div className="headline">
                            <div className="name">
                                Feature flags
                            </div>
                            <div className="tag">Premium Feature</div>
                        </div>
                        <div className="description">
                            Enable and disable optional features
                        </div>
                    </div>
                </div>

                <div className="feature">
                    <div className="icon">
                        <span className="material-icons">save</span>
                    </div>
                    <div className="details">
                        <div className="headline">
                            <div className="name">
                                Save to the server
                            </div>
                            <div className="tag">Premium Feature</div>
                        </div>
                        <div className="description">
                            The created diagrams are currently saved in the browser. I consider this to be not
                            safe
                            enough and therefore always recommend downloading a backup (Export -> Json). In case
                            you
                            delete your browser data.
                        </div>
                    </div>
                </div>

                <div className="feature">
                    <div className="icon">
                        <span className="material-icons">timeline</span>
                    </div>
                    <div className="details">
                        <div className="headline">
                            <div className="name">
                                GIT integration
                            </div>
                            <div className="tag">Premium Feature</div>
                        </div>
                        <div className="description">
                            It is planned that a diagram can be generated using code. This can be changed, and
                            these
                            changes can be transferred into the code and checked in with GIT.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="hero terra-cotta">
            <div className="whoami">
                <h1>$ whoami</h1>

                <div className="content">

                    <div className="text-wrapper">
                        <p>My name is Peer Nissen. Programming has become a passion for me. For me, code must be well
                            structured because searching for classes or member of a project shouldn't be part of a
                            developer's
                            job. I think it's important to document, but I catch myself neglecting it.</p>

                        <p>I started programming in 2013 while studying business informatics at the Flensburg University
                            of
                            Applied Sciences. During my studies, I continued to educate myself more comprehensively than
                            the
                            curriculum provides. During my work at Portal Systems AG, it was always essential to write
                            good
                            and
                            readable code. In short, the code of my work has always been important as a result to me.
                            Not
                            only
                            should it meet the requirements, but I also have high demands on my code. Functions must be
                            understood at once. Elements must be exchangeable without great effort.</p>

                        <p>When I'm not programming, I like to sail on the Alster or jog around the Alster. If I don't
                            program
                            and I'm not on the Alster, I want to go bouldering.</p>
                    </div>

                    <div className="avatar"></div>
                </div>


            </div>
        </div>
    </div>
