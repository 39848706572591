import React from "react";
import Ribbon from "../../Ribbon/view/Ribbon";

import { Documentation } from "../interfaces/Documentation";
import { DocumentationSection } from "../interfaces/DocumentationSection";
import { DocumentationArticle } from "../interfaces/DocumentationArticle";
import { DocumentationInfo } from "../info/DocumentationInfo";

import "./Documentation.css";

export default (): JSX.Element => {
  const visualizeArticle = (props: DocumentationArticle): JSX.Element => (
    <div className="article">
      <div className="header">
        <h2>{props.header}</h2>
      </div>
      <div className="body">
        <div className="paragraphs">
          {props.paragraphs.map((p) => (
            <p>{p}</p>
          ))}
        </div>
        <div className="example">{props.example}</div>
        <div className="codeSnippets">{props.codeSnippets}</div>
      </div>
    </div>
  );
  const visualizeSection = (props: DocumentationSection): JSX.Element => (
    <div className="section">
      <div className="header">
        <h1>{props.header}</h1>
      </div>
      <div>{props.articles.map(visualizeArticle)}</div>
    </div>
  );
  const documentation = (props: Documentation): JSX.Element => {
    return (
      <div>
        <Ribbon title={props.header} subtitle="Documentation" />
        <div className="mainContent">
          <div className="Documentation">
            {props.sections.map(visualizeSection)}
          </div>
        </div>
      </div>
    );
  };
  return documentation(DocumentationInfo);
};
