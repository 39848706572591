import React from "react";
import "./Numl.css";
import "../../../DragableItem/view/DragableItem.css";
import PropertyVisual from "../Property/PropertyVisual";
import MethodVisual from "../Method/MethodVisual";
import ConstructorVisual from "../Constructor/ConstructorVisual";
import Expands from "../../elements/Expands/Expands";
import LazyInput from "../../../Controls/view/LazyInput/LazyInput";
import { Property } from "../../interfaces/Property";
import { Method } from "../../interfaces/Method";
import { Constructor } from "../../interfaces/Constructor";
import { DefaultType } from "../../../Type/model/impl/DefaultType";
import { AddCircle } from "../../../VisualElements/view/Elements";
import { Numl } from "../../interfaces/Numl";

interface IProps {
  numl: Numl;
}

interface IState {}

export default class DeadNumlView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      mode: "anything",
    };
  }

  visualizeProperties(properties: Property[]): JSX.Element[] {
    const visualizedProperties: JSX.Element[] = [];
    properties.forEach((property: Property, index: number) => {
      visualizedProperties.push(
        <div className="ReorderableMember" key={"p" + index}>
          <PropertyVisual
            Delete={() => {
              this.props.numl.DeleteMember(property);
              this.forceUpdate();
            }}
            Change={(s: string) => {
              this.props.numl.DeleteMember(property);
              this.props.numl.AddMemberByString(s);
              this.forceUpdate();
            }}
            key={"P:" + property.Name}
            property={property}
          />
        </div>
      );
    });
    return visualizedProperties;
  }

  visualizeMethods(Methods: Method[]): JSX.Element[] {
    const visualizedMethods: JSX.Element[] = [];
    Methods.forEach((method: Method, index: number) => {
      visualizedMethods.push(
        <div className="ReorderableMember" key={"m" + index}>
          <MethodVisual
            Delete={() => {
              this.props.numl.DeleteMember(method);
              this.forceUpdate();
            }}
            Change={(s: string) => {
              this.props.numl.DeleteMember(method);
              this.props.numl.AddMemberByString(s);
              this.forceUpdate();
            }}
            key={"M:" + method.Name}
            method={method}
          />
        </div>
      );
    });
    return visualizedMethods;
  }

  visualizeConstructors(constructors: Constructor[]): JSX.Element[] {
    const visualizedConstructors: JSX.Element[] = [];
    constructors.forEach((ctor: Constructor, index: number) => {
      visualizedConstructors.push(
        <div className="ReorderableMember" key={"c" + index}>
          <ConstructorVisual
            Delete={() => {
              this.props.numl.DeleteMember(ctor);
              this.forceUpdate();
            }}
            Change={(s: string) => {
              this.props.numl.DeleteMember(ctor);
              this.props.numl.AddMemberByString(s);
              this.forceUpdate();
            }}
            key={"C:" + ctor.Name + index}
            ctor={ctor}
          />
        </div>
      );
    });
    return visualizedConstructors;
  }

  render(): JSX.Element {
    return (
      <div
        className="Numl DragableItem"
        id={this.props.numl.Id}
        style={{
          left: this.props.numl.Position.x + "px",
          top: this.props.numl.Position.y + "px",
        }}
      >
        <div className="header grab">
          <div className="title">
            {this.props.numl.Name}
            <Expands
              expands={this.props.numl.Expands.map((s) => new DefaultType(s))}
            />
          </div>
        </div>
        <div className="section">
          {this.visualizeProperties(this.props.numl.Properties)}
        </div>
        <div className="divider" />
        <div className="section">
          {this.visualizeConstructors(this.props.numl.Constructors)}
        </div>
        <div className="divider" />
        <div className="section">
          {this.visualizeMethods(this.props.numl.Methods)}
        </div>
        <LazyInput
          yieldMode={true}
          label={<AddCircle color="white" />}
          onSubmit={() => {}}
        />
      </div>
    );
  }
}
