import { Diagram } from "../../interfaces/Diagram";
import { DefaultLayers } from "../../../Layer/model/impl/DefaultLayers";
import { HorizontalLayer } from "../../../Layer/interfaces/HorizontalLayer";
import { VerticalLayer } from "../../../Layer/interfaces/VerticalLayer";
import { Datapoint } from "../../interfaces/Datapoints";
import { Section } from "../../interfaces/Sectrion";
import { Enum } from "../../../Enum/interfaces/Enum";
import { ViewDefinition } from "../../../ViewDefinition/interfaces/ViewDefinition";
import { Numl } from "../../../Numl/interfaces/Numl";
import { IsArrangeable } from "../../interfaces/IsArrangeable";
import { Layers } from "../../../Layer/interfaces/Layers";
import { ResizeListenerHoriszontalLayer } from "../../../Layer/model/impl/ResizeListenerHorizontalLayer";
import { ResizeListenerVerticalLayer } from "../../../Layer/model/impl/ResizeListenerVerticalLayer";

export class DefaultDiagram implements Diagram {
  private numles: Numl[] = [];
  public get Numles(): Numl[] {
    return this.numles;
  }

  public get HorizontalLayers(): HorizontalLayer[] {
    let sum: number = 0;
    return this.layers.HorizontalLayers.map((h) => {
      sum += h.Height;
      const actual = sum;
      return new ResizeListenerHoriszontalLayer(h, (diff: number) =>
        this.moveHorizontalyOverBy(actual, diff)
      );
    });
  }
  public get VerticalLayers(): VerticalLayer[] {
    let sum: number = 0;
    return this.layers.VerticalLayers.map((v) => {
      sum += v.Width;
      const actual = sum;
      return new ResizeListenerVerticalLayer(v, (diff: number) =>
        this.moveVerticalyOverBy(actual, diff)
      );
    });
  }

  private enums: Enum[] = [];
  public get Enums(): Enum[] {
    return [...this.enums];
  }

  private views: ViewDefinition[] = [];
  public get Views(): ViewDefinition[] {
    return [...this.views];
  }

  private layers: Layers;

  constructor(public Name: string, private basicTypes: string[] = []) {
    this.layers = new DefaultLayers();
  }

  private moveHorizontalyOverBy(over: number, by: number) {
    const arrangeables: IsArrangeable[] = this.getArrangeablesBetweenHorizonatl(
      over,
      -1
    );
    arrangeables.forEach((a) => a.MoveTo(a.Position.x, a.Position.y - by));
  }

  private moveVerticalyOverBy(over: number, by: number) {
    const arrangeables: IsArrangeable[] = this.getArrangeablesBetweenVertical(
      over,
      -1
    );
    arrangeables.forEach((a) => a.MoveTo(a.Position.x - by, a.Position.y));
  }

  public AddNuml(numl: Numl): void {
    this.numles.push(numl);
  }
  public DeleteNuml(numl: Numl): void {
    this.numles = this.numles.filter((cl) => cl.Id !== numl.Id);
  }

  public AddEnum(aEnum: Enum): void {
    this.enums.push(aEnum);
  }
  public RemoveEnum(aEnum: Enum): void {
    this.enums = this.enums.filter((e) => e.Id !== aEnum.Id);
  }

  public AddView(view: ViewDefinition): void {
    this.views.push(view);
  }
  public RemoveView(view: ViewDefinition): void {
    this.views = this.views.filter((v) => v.Id !== view.Id);
  }

  public Rename(name: string): void {
    this.Name = name;
  }

  public RemoveHorizontalLayer = (layer: HorizontalLayer) =>
    this.layers.RemoveHorizontalLayer(layer);
  public AddHorizontalLayer = (layer: HorizontalLayer) =>
    this.layers.AddHorizontalLayer(layer);

  private getArrangeablesBetweenHorizonatl(
    y1: number,
    y2: number
  ): IsArrangeable[] {
    let arrangeables: IsArrangeable[] = this.Numles.filter(
      (n) => n.Position.y > y1 && (n.Position.y < y2 || y2 < 0)
    );
    arrangeables = arrangeables.concat(
      this.Views.filter(
        (v) => v.Position.y > y1 && (v.Position.y < y2 || y2 < 0)
      )
    );
    arrangeables = arrangeables.concat(
      this.Enums.filter(
        (e) => e.Position.y > y1 && (e.Position.y < y2 || y2 < 0)
      )
    );

    return arrangeables;
  }

  public SwapHorizontalLayerToPrev = (index: number) => {
    const horizontalLayer: HorizontalLayer[] = this.HorizontalLayers;
    if (index > 0 && index < horizontalLayer.length) {
      let prevStart: number = 0;
      for (let i: number = 0; i < index - 1; i++) {
        prevStart += horizontalLayer[i].Height;
      }
      let actualStart: number = prevStart + horizontalLayer[index - 1].Height;
      const firstNumles: IsArrangeable[] = this.getArrangeablesBetweenHorizonatl(
        prevStart,
        actualStart
      );
      const secondNumles: IsArrangeable[] = this.getArrangeablesBetweenHorizonatl(
        actualStart,
        actualStart + horizontalLayer[index].Height
      );
      firstNumles.forEach((n) =>
        n.MoveTo(n.Position.x, n.Position.y + horizontalLayer[index].Height)
      );
      secondNumles.forEach((n) =>
        n.MoveTo(n.Position.x, n.Position.y - horizontalLayer[index - 1].Height)
      );
      this.layers.SwapHorizontalLayerToPrev(index);
    }
  };

  public SwapHorizontalLayerToNext = (index: number) => {
    const horizontalLayer: HorizontalLayer[] = this.HorizontalLayers;
    if (index >= 0 && index < horizontalLayer.length - 1) {
      let prevStart: number = 0;
      for (let i: number = 0; i < index; i++) {
        prevStart += horizontalLayer[i].Height;
      }
      let actualStart: number = prevStart + horizontalLayer[index + 1].Height;
      const firstNumles: IsArrangeable[] = this.getArrangeablesBetweenHorizonatl(
        prevStart,
        actualStart
      );
      const secondNumles: IsArrangeable[] = this.getArrangeablesBetweenHorizonatl(
        actualStart,
        actualStart + horizontalLayer[index].Height
      );
      firstNumles.forEach((n) =>
        n.MoveTo(n.Position.x, n.Position.y + horizontalLayer[index + 1].Height)
      );
      secondNumles.forEach((n) =>
        n.MoveTo(n.Position.x, n.Position.y - horizontalLayer[index].Height)
      );
      this.layers.SwapHorizontalLayerToNext(index);
    }
  };

  public RemoveVerticalLayer = (layer: VerticalLayer) =>
    this.layers.RemoveVerticalLayer(layer);
  public AddVerticalLayer = (layer: VerticalLayer) =>
    this.layers.AddVerticalLayer(layer);

  private getArrangeablesBetweenVertical(
    x1: number,
    x2: number
  ): IsArrangeable[] {
    let arrangeables: IsArrangeable[] = this.Numles.filter(
      (n) => n.Position.x > x1 && (n.Position.x < x2 || x2 < 0)
    );
    arrangeables = arrangeables.concat(
      this.Views.filter(
        (v) => v.Position.x > x1 && (v.Position.x < x2 || x2 < 0)
      )
    );
    arrangeables = arrangeables.concat(
      this.Enums.filter(
        (e) => e.Position.x > x1 && (e.Position.x < x2 || x2 < 0)
      )
    );

    return arrangeables;
  }

  public SwapVerticalLayerToPrev = (index: number) => {
    const verticalLayer: VerticalLayer[] = this.VerticalLayers;
    if (index > 0 && index < verticalLayer.length) {
      let prevStart: number = 0;
      for (let i: number = 0; i < index - 1; i++) {
        prevStart += verticalLayer[i].Width;
      }
      let actualStart: number = prevStart + verticalLayer[index - 1].Width;
      const firstNumles: IsArrangeable[] = this.getArrangeablesBetweenVertical(
        prevStart,
        actualStart
      );
      const secondNumles: IsArrangeable[] = this.getArrangeablesBetweenVertical(
        actualStart,
        actualStart + verticalLayer[index].Width
      );
      firstNumles.forEach((n) =>
        n.MoveTo(n.Position.x + verticalLayer[index].Width, n.Position.y)
      );
      secondNumles.forEach((n) =>
        n.MoveTo(n.Position.x - verticalLayer[index - 1].Width, n.Position.y)
      );
      this.layers.SwapVerticalLayerToPrev(index);
    }
  };

  public SwapVerticalLayerToNext = (index: number) => {
    const verticalLayer: VerticalLayer[] = this.VerticalLayers;
    if (index >= 0 && index < verticalLayer.length - 1) {
      let prevStart: number = 0;
      for (let i: number = 0; i < index; i++) {
        prevStart += verticalLayer[i].Width;
      }
      let actualStart: number = prevStart + verticalLayer[index + 1].Width;
      const firstNumles: IsArrangeable[] = this.getArrangeablesBetweenVertical(
        prevStart,
        actualStart
      );
      const secondNumles: IsArrangeable[] = this.getArrangeablesBetweenVertical(
        actualStart,
        actualStart + verticalLayer[index].Width
      );
      firstNumles.forEach((n) =>
        n.MoveTo(n.Position.x + verticalLayer[index + 1].Width, n.Position.y)
      );
      secondNumles.forEach((n) =>
        n.MoveTo(n.Position.x - verticalLayer[index].Width, n.Position.y)
      );
      this.layers.SwapVerticalLayerToNext(index);
    }
  };

  public GetSection(datapoint: Datapoint): Section {
    let horizontalDimension: string = "";
    let summedHeight: number = 0;
    for (
      let i: number = 0;
      i < this.HorizontalLayers.length && summedHeight < datapoint.y;
      i++
    ) {
      horizontalDimension = this.HorizontalLayers[i].Name;
      summedHeight += this.HorizontalLayers[i].Height;
    }

    let verticalDimension: string = "";
    let summedWidth: number = -30;
    for (
      let i: number = 0;
      i < this.VerticalLayers.length && summedWidth < datapoint.x;
      i++
    ) {
      verticalDimension = this.VerticalLayers[i].Name;
      summedWidth += this.VerticalLayers[i].Width;
    }
    return {
      HorizontalDimension: horizontalDimension,
      VerticalDimension: verticalDimension,
    };
  }

  public FindNumlByName(name: string): Numl | undefined {
    return this.Numles.find((e) => e.Name === name);
  }

  public FindEnumByName(name: string): Enum | undefined {
    return this.Enums.find((e) => e.Name === name);
  }

  public FindViewByName(name: string): ViewDefinition | undefined {
    return this.Views.find((v) => v.Name === name);
  }

  public IsValidType(type: string): boolean {
    if (this.basicTypes.includes(type)) {
      return true;
    }
    for (let i: number = 0; i < this.numles.length; i++) {
      if (this.numles[i].Name === type) {
        return true;
      }
    }
    return false;
  }
}
