import React from "react";
import "./Params.css";
import TypeView from "../Type/Type";
import { Type } from "../../../Type/interfaces/Type";

// tslint:disable-next-line: interface-name
interface Props {
    params: Type[];
}


function visualizeParams(params: Type[]): (JSX.Element | string)[] {
    let visualizedParams: (JSX.Element | string)[] = [];
    visualizedParams.push("(");
    params.forEach((param: Type, i: number) => {
        visualizedParams.push(<TypeView type={param} />);
        if (i !== params.length - 1) {
            visualizedParams.push(",\u00A0")
        }
    });
    visualizedParams.push(<div>)</div>);

    return visualizedParams;
}

export default (props: Props): JSX.Element => <div className="Params">{visualizeParams(props.params)}</div>;



