import React from "react";
import "./Layer.css";
import Layer from "./HorizontalLayer";
import { AddCircle } from "../../../VisualElements/view/Elements";
import { HorizontalLayer } from "../../interfaces/HorizontalLayer";
import AddItemm from "../../../Diagram/view/AddItem/AddItem";
import { Environment } from "../../../Environment/model/Environment";
import PageBuilder from "../../../Page/view/PageBuilder";
import Button from "../../../Button/view/Button";

interface IProps {
  layers: HorizontalLayer[];
  width: number;
  AddLayer(name: string): void;
  DeleteLayer(layer: HorizontalLayer): void;
  SwapToPrevLayer(index: number): void;
  SwapToNextLayer(index: number): void;
}

export default (props: IProps) => {
  function AddHorizontalLayer() {
    const p: {
      foo(s: string): void;
      title: string;
    } = {
      foo: (s: string) => {
        props.AddLayer(s);
      },
      title: "Add Horizontal Layer",
    };
    let closeFoo: () => void = PageBuilder.OpenPenetratingWindow(
      <AddItemm AddItem={(s: string) => p.foo(s)} />,
      p.title,
      300
    );
    let extendCloseFoo = (foo: (...params: any) => void) =>
      Environment.CombineFoos(closeFoo, foo);
    p.foo = extendCloseFoo(p.foo);
  }

  function visualizeLayers() {
    return props.layers.map((l, index) => (
      <Layer
        layer={l}
        delete={() => props.DeleteLayer(l)}
        swapToNext={() => props.SwapToNextLayer(index)}
        swapToPrev={() => props.SwapToPrevLayer(index)}
      />
    ));
  }

  return (
    <div className="Layers HorizontalLayers" style={{ width: props.width }}>
      {visualizeLayers()}
      <div className="LayersButton">
        <Button
          Action={{
            Label: <AddCircle color="white" />,
            Action: () => AddHorizontalLayer(),
          }}
        />
      </div>
    </div>
  );
};

