import { Diagram } from "../../../Diagram/interfaces/Diagram";
import { DefaultDiagram } from "../../../Diagram/model/impl/DefaultDiagram";
import { JsonParser } from "../../../JsonParser/interfaces/JsonParser";
import { DefaultJsonParser } from "../../../JsonParser/model/impl/DefaultJsonParser";
import { DefaultHorizontalLayer } from "../../../Layer/model/impl/DefaultHorizontalLayer";
import { DefaultVerticalLayer } from "../../../Layer/model/impl/DefaultVerticalLayer";
import { DiagramProvider } from "../../interfaces/DiagramProvider";

export class LocalStorageDiagramProvider implements DiagramProvider {
    private diagramParser: JsonParser = new DefaultJsonParser();

    private diagrams: { [key: string]: Diagram };

    constructor() {
        let storage: string | null = localStorage.getItem("diagrams");
        if (storage) {
            this.diagrams = this.diagramParser.ParseDiagramsFromJsonString(storage as string);
        } else {
            this.diagrams = {};
            let diagram = new DefaultDiagram("Example")
            diagram.AddHorizontalLayer(new DefaultHorizontalLayer("Technical Layer", 600));
            diagram.AddVerticalLayer(new DefaultVerticalLayer("Domain Layer", 600));
            this.AddDiagram(diagram)
        }
    }

    DeleteDaiagram(diagram: string | Diagram): void {
        let nameToDelete: string
        if ((diagram as Diagram).Name) {
            nameToDelete = (diagram as Diagram).Name;
        } else {
            nameToDelete = diagram as string;
        }
        delete this.diagrams[nameToDelete];
        this.updateStorage();
    }

    GetDiagrams(): Diagram[] {
        return Object.values(this.diagrams).sort((a, b) => a.Name.toLowerCase() < b.Name.toLowerCase() ? 1 : -1);
    }

    AddDiagram(diagram: Diagram): void {
        this.diagrams[diagram.Name] = diagram;
        this.updateStorage();
    }

    private updateStorage(){
        localStorage.setItem("diagrams", this.diagramParser.ParseDiagramsToJsonString(this.diagrams));
    }

}