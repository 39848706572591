import { Datapoint } from "../../Diagram/interfaces/Datapoints";
import { DefaultObserveable } from "../../Environment/model/DefaultObserveable";
import { Type } from "../../Type/interfaces/Type";
import { ObserveableViewDefinition } from "../interfaces/ObserveableViewDefinition";
import { ViewDefinition } from "../interfaces/ViewDefinition";



export class DefaultObserveableViewDefinition extends DefaultObserveable<ViewDefinition> implements ObserveableViewDefinition {
    public get Dependencies(): Type[] { return this.viewDefinition.Dependencies }
    public get Position(): Datapoint { return this.viewDefinition.Position };
    public get Id(): string { return this.viewDefinition.Id }
    public get Name(): string { return this.viewDefinition.Name }

    constructor(private viewDefinition: ViewDefinition) {
        super(viewDefinition);
    }
    SwapValueToPrev(index: number): void {
        this.viewDefinition.SwapValueToPrev(index);
        this.raiseNext(this);
    }
    SwapValueToNext(index: number): void {
        this.viewDefinition.SwapValueToNext(index);
        this.raiseNext(this);
    }

    Add(s: string): void {
        this.viewDefinition.Add(s);
        this.raiseNext(this);
    }
    ChangeDependency(prev: Type, newStr: string): void {
        this.viewDefinition.ChangeDependency(prev, newStr);
        this.raiseNext(this);
    }


      /**
     * Does not raise event Next!
     * @param x new x
     * @param y new y
     */    
    MoveTo(x: number, y: number) {
        this.viewDefinition.MoveTo(x, y);
    }

    public RaiseNext() {
        this.raiseNext(this);
    }

    Remove(s: Type): void {
        this.viewDefinition.Remove(s);
        this.raiseNext(this);
    }

    Rename(name: string): void {
        this.viewDefinition.Rename(name);
        this.raiseNext(this);
    }
}