import React from "react";
import { ArrowDown, ArrowUp } from "../../../VisualElements/view/Arrows";
import { IsReorderable } from "../../interfaces/IsReorderable";
import "./ReorderableMember.css"




interface IProps extends IsReorderable {
    Member: JSX.Element;
}


export default (props: IProps): JSX.Element =>
    <div className="ReorderableMember">
        <div className="reorderControls">
            <ArrowUp Scale={3} Action={() => props.SwapToPrev()} />
            <ArrowDown Action={() => props.SwapToNext()} />
        </div>
        {props.Member}
    </div>












//  <div style={{height:10, width: 10, backgroundColor:"red"}} onClick={()=>props.SwapToPrev()} />
//  <Params params={props.ctor.ParamTypes} />            
//  <div style={{height:10, width: 10, backgroundColor:"blue"}} onClick={()=>props.SwapToNext()} />