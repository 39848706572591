import { DefaultObserveable } from "../../../Environment/model/DefaultObserveable";
import { HorizontalLayer } from "../../interfaces/HorizontalLayer";



export class DefaultObserveableHorizontalLayer extends DefaultObserveable<HorizontalLayer> implements HorizontalLayer {

    Height: number = this.original.Height;
    public Name: string = this.original.Name;
    public readonly Id:string = this.original.Id;

    Rename(name: string): void {
        this.original.Rename(name);
        this.raiseNext(this.original);
    }

    ResizeHeight(newHeight: number): void {
        this.original.ResizeHeight(newHeight)
        this.raiseNext(this.original)
    }

}