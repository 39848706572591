import { ArrayHelper } from "../../../ArrayHelper/static/impl/ArrayHelper";
import { ElementType } from "../../../Environment/enums/ElementType";
import { IFile } from "../../../FileStructure/interfaces/IFile";
import { DefaultFile } from "../../../FileStructure/model/impl/DefaultFile";
import { Constructor } from "../../../Numl/interfaces/Constructor";
import { ImportInformation } from "../../../Numl/interfaces/ImportInformation";
import { Method } from "../../../Numl/interfaces/Method";
import { Property } from "../../../Numl/interfaces/Property";
import { Type } from "../../../Type/interfaces/Type";
import { DefaultType } from "../../../Type/model/impl/DefaultType";
import { ClassExportInformation } from "../../interfaces/ClassExportInformation";
import { EnumExportInformation } from "../../interfaces/EnumExportInformation";
import { ExportInformation } from "../../interfaces/ExportInformation";
import { FileCreator2 } from "../../interfaces/FileCreator2";
import { InterfaceExpotInformation } from "../../interfaces/InterfaceExportInformation";
import { ViewExportInformation } from "../../interfaces/ViewExportInformation";
import { ViewFileCreator } from "../../interfaces/ViewFileCreator";

export class TypeScriptFileCreator implements FileCreator2 {
  private fileExtension: string = ".ts";

  constructor(private viewFileCreator: ViewFileCreator) {}

  CreateViewFile(exportInformation: ViewExportInformation): IFile {
    const file: IFile = this.viewFileCreator.CreateViewFile(exportInformation);
    file.Content = this.createImportBlock(exportInformation) + file.Content;
    return file;
  }

  CreateEnumFile(exportInformation: EnumExportInformation): IFile {
    let content: string = this.createImportBlock(exportInformation);
    content += "export enum " + exportInformation.Name + " {";
    content += this.enumValuesToString(exportInformation.Values);
    return new DefaultFile(
      exportInformation.Name + this.fileExtension,
      content
    );
  }

  CreateInterfaceFile(exportInformation: InterfaceExpotInformation): IFile {
    let content = this.createImportBlock(exportInformation);
    content +=
      "export interface " +
      exportInformation.Name +
      this.createExtends(exportInformation.Expands) +
      " {\n";
    content += this.createPropsBlockInterface(exportInformation.Properties);
    content += this.createMethodsBlockInterface(exportInformation.Methods);
    content += "\n}\n";
    return new DefaultFile(
      new DefaultType(exportInformation.Name).BaseType + this.fileExtension,
      content
    );
  }

  CreateClassFile(exportInformation: ClassExportInformation): IFile {
    let content =
      "import { " +
      new DefaultType(exportInformation.Name).BaseType +
      ' } from "../interfaces/' +
      new DefaultType(exportInformation.Name).BaseType +
      '";\n';
    content += this.createImportBlock(exportInformation);
    content +=
      "export class " +
      exportInformation.Constructors[0].Name +
      " implements " +
      exportInformation.Name +
      " {\n";
    content += this.createPropsBlockClass(exportInformation.Properties);
    content += this.createConstructor(exportInformation.Constructors);
    content += this.createMethodsBlockClass(exportInformation.Methods);
    content += "\n}\n";
    return new DefaultFile(
      new DefaultType(exportInformation.Constructors[0].Name).BaseType +
        this.fileExtension,
      content
    );
  }

  private createExtends(expands: string[]): string {
    if (expands.length === 0) {
      return "";
    } else {
      return " extends " + expands.join(",");
    }
  }

  private createMethodsBlockInterface(methods: Method[]) {
    const meths: Method[][] = ArrayHelper.GroupBy(methods, "Name");
    return (
      meths.map((m) => this.stringifyMethodInterface(m)).join("\n\n") + "\n\n"
    );
  }

  private createPropsBlockInterface(props: Property[]): string {
    function stringifyProps(prop: Property) {
      return "\t" + prop.Name + ": " + prop.ReturnType.FullType + ";";
    }
    return props.map((p) => stringifyProps(p)).join("\n") + "\n\n";
  }

  private stringifyMethodInterface(methods: Method[]) {
    const stringifiedParams: string = this.stringifyParams(
      methods.map((m) => m.ParamTypes)
    );
    const returnType: string = methods
      .map((m) => m.ReturnType.FullType)
      .filter(ArrayHelper.UniqueFilter)
      .join(" | ");
    return "\t" + methods[0].Name + stringifiedParams + ": " + returnType + ";";
  }

  private createMethodsBlockClass(methods: Method[]) {
    const meths: Method[][] = ArrayHelper.GroupBy(methods, "Name");
    return meths.map((m) => this.stringifyMethodClass(m)).join("\n\n") + "\n\n";
  }

  private stringifyMethodClass(methods: Method[]) {
    const stringifiedParams: string = this.stringifyParams(
      methods.map((m) => m.ParamTypes)
    );
    const returnType: string = methods
      .map((m) => m.ReturnType.FullType)
      .filter(ArrayHelper.UniqueFilter)
      .join(" | ");
    return (
      "\t" +
      methods[0].Name +
      stringifiedParams +
      ": " +
      returnType +
      ' {\n\t\tthrow new Error("Method not implemented.");\n\t}'
    );
  }

  private createPropsBlockClass(props: Property[]): string {
    function stringifyProps(prop: Property) {
      return "\tpublic " + prop.Name + ": " + prop.ReturnType.FullType + ";";
    }
    return props.map((p) => stringifyProps(p)).join("\n") + "\n\n";
  }

  private createImportBlock(exportInformation: ExportInformation): string {
    const stringify = (sourceInfo: ImportInformation) =>
      this.ImportInformationToString(
        {
          HorizontalDimension: exportInformation.HorizontalDimension,
          VerticalDimension: exportInformation.VerticalDimension,
          Name: exportInformation.Name,
        },
        sourceInfo
      );
    return (
      exportInformation.Imports.map((i) => stringify(i)).join("\n") + "\n\n"
    );
  }

  private ImportInformationToString(
    targetInfo: ImportInformation,
    sourceInfo: ImportInformation
  ): string {
    const baseName: string = new DefaultType(sourceInfo.Name).BaseType;
    let path: string = "";
    let virtualDimension = sourceInfo.HorizontalDimension;
    if (sourceInfo.Type === ElementType.Interface) {
      virtualDimension = "interfaces";
    }
    if (targetInfo.VerticalDimension !== sourceInfo.VerticalDimension) {
      path =
        '"../../' +
        sourceInfo.VerticalDimension +
        "/" +
        virtualDimension +
        "/" +
        baseName +
        '";';
    } else if (virtualDimension === targetInfo.VerticalDimension) {
      path = '"./' + baseName + '";';
    } else {
      path = '"../' + virtualDimension + "/" + baseName + '";';
    }
    return "import { " + baseName + " } from " + path;
  }

  private enumValuesToString(values: string[]) {
    return "\n\t" + values.join(",\n\t") + "\n}\n";
  }

  private createConstructor(ctors: Constructor[]) {
    const params = this.stringifyParams(ctors.map((c) => c.ParamTypes));
    return "\n\tconstructor" + params + " { }\n";
  }

  private stringifyParams(params: Type[][]) {
    if (
      params.length === 0 ||
      (params.length === 1 && params[0].length === 0)
    ) {
      return "()";
    }
    const sorted: Type[][] = params.sort((a, b) => b.length - a.length);
    const start: Type[][] = sorted[0].map((e) => [e]);
    const minParams: number = sorted[sorted.length - 1].length;
    for (let i = 1; i < sorted.length; i++) {
      sorted[i].forEach((e, j) => {
        start[j].push(e);
      });
    }
    const filtered: Type[][] = start.map((types) =>
      types.filter(ArrayHelper.UniqueFilter)
    );

    const typesWritten: string[] = filtered.map((types) =>
      types.map((t) => t.FullType).join(" | ")
    );
    const usedNames: string[] = filtered
      .flat()
      .filter(ArrayHelper.UniqueFilter)
      .map((f) => f.BaseType);
    const nameSuggestions: string[] = filtered[0].map(
      (t) => t.BaseType[0].toLowerCase() + t.BaseType.substring(1)
    );

    return (
      "(" +
      nameSuggestions
        .map((suggestedName, index) => {
          let paramName = suggestedName;
          for (let i: number = 1; usedNames.includes(paramName); i++) {
            paramName = suggestedName + i;
          }
          usedNames.push(paramName);
          let optional: string = "";
          if (filtered[0][index].IsOptional || minParams <= index) {
            optional = "?";
          }
          return paramName + optional + ": " + typesWritten[index];
        })
        .join(", ") +
      ")"
    );
  }
}
