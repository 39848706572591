import React from "react";
import "./Error.css"

interface IProps{
    errorMessage: string
}

export default (props:IProps)=>  
<div>
        {props.errorMessage}
</div>