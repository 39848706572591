import { Layers } from "../../interfaces/Layers";
import { VerticalLayer } from "../../interfaces/VerticalLayer";
import { HorizontalLayer } from "../../interfaces/HorizontalLayer";
import { ArrayHelper } from "../../../ArrayHelper/static/impl/ArrayHelper";





export class DefaultLayers implements Layers {
    constructor() {
        this.HorizontalLayers = [];
        this.VerticalLayers = [];
    }
    GetHorizontalLayers(): HorizontalLayer[] {
        return this.HorizontalLayers;
    }
    GetVerticalLayers(): VerticalLayer[] {
        return this.VerticalLayers;
    }

    public SwapHorizontalLayerToPrev = (index: number) => ArrayHelper.SwapToPrev(this.HorizontalLayers, index);
    public SwapVerticalLayerToPrev = (index: number) => ArrayHelper.SwapToPrev(this.VerticalLayers, index);
    public SwapHorizontalLayerToNext = (index: number) => ArrayHelper.SwapToNext(this.HorizontalLayers, index);
    public SwapVerticalLayerToNext= (index: number)=> ArrayHelper.SwapToNext(this.VerticalLayers, index);


    public HorizontalLayers: HorizontalLayer[];
    public VerticalLayers: VerticalLayer[];

    public AddHorizontalLayer(layer: HorizontalLayer) {
    this.HorizontalLayers.push(layer);
}

    public AddVerticalLayer(layer: VerticalLayer): void {
    this.VerticalLayers.push(layer);
}
    



    public RemoveHorizontalLayer(layer: HorizontalLayer): void {
    this.HorizontalLayers = this.HorizontalLayers.filter(l => l.Id !== layer.Id);
}

    public RemoveVerticalLayer(layer: VerticalLayer): void {
    this.VerticalLayers = this.VerticalLayers.filter(l => l.Id !== layer.Id);
}
}
