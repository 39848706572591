import { ArrayHelper } from "../../../ArrayHelper/static/impl/ArrayHelper";
import { Section } from "../../../Diagram/interfaces/Sectrion";
import { ImportInformation } from "../../../Numl/interfaces/ImportInformation";
import { Method } from "../../../Numl/interfaces/Method";
import { Numl } from "../../../Numl/interfaces/Numl";
import { Property } from "../../../Numl/interfaces/Property";
import { Type } from "../../../Type/interfaces/Type";
import { DefaultType } from "../../../Type/model/impl/DefaultType";
import { InterfaceExpotInformation } from "../../interfaces/InterfaceExportInformation";



export class DefaultInterfaceExportInformation implements InterfaceExpotInformation {

    public get Imports(): ImportInformation[] { return this.getImports() };
    public get VerticalDimension(): string { return this.section.VerticalDimension; }
    public get HorizontalDimension(): string { return this.section.HorizontalDimension; }
    public get Name(): string { return this.numl.Name; }

    public get Expands(): string[] { return this.numl.Expands; }
    public get Methods(): Method[] { return this.numl.Methods; }
    public get Properties(): Property[] { return this.numl.Properties; }

    constructor(private numl: Numl, private section: Section, protected getImportInformation: ((element: string) => ImportInformation | undefined)) {

    }

    private getImports(): ImportInformation[] {
        const nonGenericTypes: string[] = [];
        const mixedTpes: Type[] = [...this.Properties.map(p => p.ReturnType)].concat([...this.Methods.flatMap(m => {
            const t: Type[] = [...m.ParamTypes];
            t.push(m.ReturnType);
            return t;
        })]);

        function addType(t: Type) {
            nonGenericTypes.push(t.TechnicalType);
            t.FurtherTypes.forEach(addType);
        }

        this.numl.Expands.forEach(e => addType(new DefaultType(e)));
        mixedTpes.forEach(addType);

        return nonGenericTypes.filter(ArrayHelper.UniqueFilter).map(this.getImportInformation).filter(i => i) as ImportInformation[];
    }


}