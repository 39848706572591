import React from "react";

export default () => (
  <div className="indented section">
    <h4> Privacy Policy </h4>

    <p>
      {" "}
      Thank you for your interest in our company. Data protection is of a
      particularly high priority for the management of the Peer Nissen. In
      principle, it is possible to use the Peer Nissen website without providing
      any personal data. However, if a data subject wishes to make use of our
      company's special services via our website, it may be necessary to process
      personal data. If the processing of personal data is necessary and there
      is no legal basis for such processing, we generally obtain the consent of
      the person concerned.{" "}
    </p>

    <p>
      {" "}
      The processing of personal data, for example the name, address, email
      address or telephone number of a data subject, is always carried out in
      accordance with the General Data Protection Regulation and in accordance
      with the country-specific data protection regulations applicable to Peer
      Nissen. With this privacy policy, our company would like to inform the
      public about the type, scope and purpose of the personal data we collect,
      use and process. Furthermore, data subjects are informed about their
      rights by means of this data protection declaration.{" "}
    </p>

    <p>
      {" "}
      As the controller, Peer Nissen has implemented numerous technical and
      organizational measures to ensure the most complete protection of personal
      data processed via this website. Nevertheless, internet-based data
      transmissions can generally have security gaps, so that absolute
      protection cannot be guaranteed. For this reason, every person concerned
      is free to transmit personal data to us in alternative ways, for example
      by telephone.{" "}
    </p>

    <h4> 1. Definitions </h4>
    <p>
      {" "}
      The data protection declaration of Peer Nissen is based on the terms used
      by the European legislator for directives and regulations when the General
      Data Protection Regulation (GDPR) was adopted. Our data protection
      declaration should be easy to read and understand for the public as well
      as for our customers and business partners. To ensure this, we would like
      to explain the terms used in advance.{" "}
    </p>

    <p> We use the following terms in this data protection declaration: </p>

    <ul style={{ listStyle: "none" }}>
      <li>
        {" "}
        <h4> a) personal data </h4>
        <p>
          {" "}
          Personal data is all information that relates to an identified or
          identifiable natural person (hereinafter "data subject"). A natural
          person is considered to be identifiable who, directly or indirectly,
          in particular by means of assignment to an identifier such as a name,
          to an identification number, to location data, to an online identifier
          or to one or more special characteristics that express the physical,
          physiological, genetic, psychological, economic, cultural or social
          identity of this natural person can be identified.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> b) data subject </h4>
        <p>
          {" "}
          Affected person is any identified or identifiable natural person whose
          personal data is processed by the person responsible for processing.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> c) Processing </h4>
        <p>
          {" "}
          Processing is any process carried out with or without the help of
          automated processes or any such series of processes in connection with
          personal data such as the collection, recording, organization,
          ordering, storage, adaptation or change, reading, querying, the use,
          disclosure by transmission, distribution or any other form of
          provision, comparison or linking, restriction, deletion or
          destruction.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> d) Restriction of processing </h4>
        <p>
          {" "}
          Restriction of processing is the marking of stored personal data with
          the aim of restricting their future processing.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> e) Profiling </h4>
        <p>
          {" "}
          Profiling is any type of automated processing of personal data that
          consists of using this personal data to evaluate certain personal
          aspects that relate to a natural person, in particular to assess
          aspects relating to work performance, economic situation, To analyze
          or predict the health, personal preferences, interests, reliability,
          behavior, whereabouts or relocation of this natural person.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> f) Pseudonymization </h4>
        <p>
          {" "}
          Pseudonymization is the processing of personal data in a way in which
          the personal data can no longer be assigned to a specific data subject
          without the use of additional information, provided that this
          additional information is stored separately and is subject to
          technical and organizational measures that ensure that the personal
          data are not assigned to an identified or identifiable natural person.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> g) Responsible person or person responsible for processing </h4>
        <p>
          {" "}
          The person responsible or the person responsible for the processing is
          the natural or legal person, authority, institution or other body that
          alone or jointly with others decides on the purposes and means of
          processing personal data. If the purposes and means of this processing
          are specified by Union law or the law of the member states, the person
          responsible or the specific criteria for his appointment can be
          provided for in accordance with Union law or the law of the member
          states.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> h) Processor </h4>
        <p>
          {" "}
          Processor is a natural or legal person, public authority, agency or
          other body that processes personal data on behalf of the person
          responsible.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> i) Recipient </h4>
        <p>
          {" "}
          Recipient is a natural or legal person, authority, institution or
          other body to which personal data is disclosed, regardless of whether
          it is a third party or not. Authorities that may receive personal data
          as part of a specific investigation according to Union law or the law
          of the member states are not considered recipients.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> j) third party </h4>
        <p>
          {" "}
          Third party is a natural or legal person, authority, institution or
          other body apart from the person concerned, the person responsible,
          the processor and the persons who are authorized to process the
          personal data under the direct responsibility of the person
          responsible or the processor.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> k) Consent </h4>
        <p>
          {" "}
          Consent is any voluntary declaration of intent given by the data
          subject in an informed manner and unequivocally in the form of a
          declaration or other unambiguous affirmative action with which the
          data subject indicates that they are processing the data concerning
          them personal data.{" "}
        </p>
      </li>
    </ul>

    <h4> 2. Name and address of the person responsible for processing </h4>
    <p>
      {" "}
      The person responsible within the meaning of the General Data Protection
      Regulation, other data protection laws applicable in the member states of
      the European Union and other provisions of a data protection nature is:{" "}
    </p>

    <p> Peer Nissen </p>
    <p> Graumannsweg 49 </p>
    <p> 22087 Hamburg </p>
    <p> Germany </p>
    <p> Tel .: +491751037788 </p>
    <p> Email: info@peni4142.com </p>
    <p> Website: peni4142.com </p>

    <h4> 3. Collection of general data and information </h4>
    <p>
      {" "}
      The website of the Peer Nissen collects a series of general data and
      information every time the website is accessed by a data subject or an
      automated system. These general data and information are stored in the
      server's log files. The (1) browser types and versions used, (2) the
      operating system used by the accessing system, (3) the website from which
      an accessing system reaches our website (so-called referrer), (4) the
      sub-websites that are accessed via an accessing system on our website can
      be controlled, (5) the date and time of access to the website, (6) an
      internet protocol address (IP address), (7) the internet service provider
      of the accessing system and (8) other similar data and information that
      serve to avert danger in the event of attacks on our information
      technology systems.{" "}
    </p>

    <p>
      {" "}
      When using this general data and information, the Peer Nissen does not
      draw any conclusions about the person concerned. Rather, this information
      is required to (1) correctly deliver the content of our website, (2)
      optimize the content of our website and the advertising for it, (3) ensure
      the long-term functionality of our information technology systems and the
      technology of our website and ( 4) to provide law enforcement authorities
      with the information necessary for law enforcement in the event of a cyber
      attack. This anonymously collected data and information is therefore
      statistically and further evaluated by the Peer Nissen with the aim of
      increasing data protection and data security in our company in order to
      ultimately ensure an optimal level of protection for the personal data
      processed by us. The anonymous data in the server log files are stored
      separately from all personal data provided by a data subject.{" "}
    </p>

    <h4> 4. Contact option via the website </h4>
    <p>
      {" "}
      The website of Peer Nissen contains information that enables quick
      electronic contact to our company and direct communication with us, which
      also includes a general address for so-called electronic mail (e-mail
      address). If a person concerned contacts the person responsible for
      processing by email or a contact form, the personal data transmitted by
      the person concerned will be automatically saved. Such personal data
      transmitted on a voluntary basis by a data subject to the person
      responsible for processing are stored for the purpose of processing or
      contacting the data subject. This personal data will not be passed on to
      third parties.{" "}
    </p>

    <h4> 5. Routine deletion and blocking of personal data </h4>
    <p>
      {" "}
      The person responsible for the processing processes and stores personal
      data of the data subject only for the period of time necessary to achieve
      the storage purpose or if this is done by the European directives and
      regulations or another legislator in laws or regulations which the for the
      processing is subject to, has been provided.{" "}
    </p>

    <p>
      {" "}
      If the purpose of the storage is no longer applicable or if a storage
      period prescribed by the European directives and regulations or another
      responsible legislator expires, the personal data will be routinely
      blocked or deleted in accordance with the statutory provisions.{" "}
    </p>

    <h4> 6. Rights of the data subject </h4>
    <ul style={{ listStyle: "none" }}>
      <li>
        {" "}
        <h4> a) Right to confirmation </h4>
        <p>
          {" "}
          Every person concerned has the right granted by the European directive
          and regulation giver to request confirmation from the person
          responsible for the processing as to whether personal data concerning
          them are being processed. If a person concerned wishes to exercise
          this right to confirmation, they can contact an employee of the person
          responsible for processing at any time.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> b) Right to information </h4>
        <p>
          {" "}
          Every person affected by the processing of personal data has the right
          granted by the European legislator of directives and regulations to
          receive free information about the personal data stored about him and
          a copy of this information from the person responsible for processing
          at any time. Furthermore, the European directives and regulations
          grant the data subject access to the following information:{" "}
        </p>
        <ul style={{ listStyle: "none" }}>
          <li> the processing purposes </li>
          <li> the categories of personal data that are processed </li>
          <li>
            {" "}
            the recipients or categories of recipients to whom the personal data
            have been disclosed or are still being disclosed, in particular for
            recipients in third countries or international organizations{" "}
          </li>
          <li>
            {" "}
            If possible, the planned duration for which the personal data will
            be stored or, if this is not possible, the criteria for determining
            this duration{" "}
          </li>
          <li>
            {" "}
            the existence of a right to correction or deletion of personal data
            concerning you or to restriction of processing by the person
            responsible or a right to object to this processing{" "}
          </li>
          <li> the right to lodge a complaint with a supervisory authority </li>
          <li>
            {" "}
            if the personal data are not collected from the data subject: All
            available information on the origin of the data{" "}
          </li>
          <li>
            {" "}
            the existence of automated decision-making including profiling in
            accordance with Article 22 Paragraphs 1 and 4 GDPR and - at least in
            these cases - meaningful information about the logic involved and
            the scope and intended effects of such processing for the data
            subject{" "}
          </li>
        </ul>
        <p>
          {" "}
          Furthermore, the data subject has a right to information as to whether
          personal data has been transmitted to a third country or to an
          international organization. If this is the case, the data subject has
          the right to receive information about the appropriate guarantees in
          connection with the transmission.{" "}
        </p>
        <p>
          {" "}
          If a person concerned would like to exercise this right to
          information, they can contact an employee of the person responsible
          for processing at any time.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> c) Right to rectification </h4>
        <p>
          {" "}
          Every person affected by the processing of personal data has the right
          granted by the European legislator of directives and regulations to
          request the immediate correction of incorrect personal data concerning
          them. Furthermore, the data subject has the right, taking into account
          the purposes of the processing, to request the completion of
          incomplete personal data - including by means of a supplementary
          declaration.{" "}
        </p>
        <p>
          {" "}
          If a data subject wishes to exercise this right to correction, they
          can contact an employee of the controller at any time.{" "}
        </p>{" "}
      </li>
      <li>
        <h4> d) Right to deletion (right to be forgotten) </h4>
        <p>
          {" "}
          Every person affected by the processing of personal data has the right
          granted by the European directive and regulation giver to demand that
          the person responsible delete the personal data concerning them
          immediately, provided that one of the following reasons applies and
          insofar as the processing is not required is:{" "}
        </p>

        <ul style={{ listStyle: "none" }}>
          <li>
            {" "}
            The personal data were collected or otherwise processed for purposes
            for which they are no longer necessary.{" "}
          </li>
          <li>
            {" "}
            The data subject revokes their consent on which the processing was
            based in accordance with Art. 6 Paragraph 1 Letter a GDPR or Art. 9
            Paragraph 2 Letter a GDPR, and there is no other legal basis for the
            Processing.{" "}
          </li>
          <li>
            {" "}
            The data subject objects to the processing in accordance with Art.
            21 Paragraph 1 GDPR, and there are no overriding legitimate reasons
            for the processing, or the data subject objects in accordance with
            Art. 21 Paragraph 2 GDPR Objection to the processing.{" "}
          </li>
          <li> The personal data was processed unlawfully. </li>
          <li>
            {" "}
            The deletion of personal data is necessary to fulfill a legal
            obligation under Union law or the law of the member states to which
            the person responsible is subject.{" "}
          </li>
          <li>
            {" "}
            The personal data was collected in relation to the information
            society services offered in accordance with Art. 8 Para. 1 GDPR.{" "}
          </li>
        </ul>
        <p>
          {" "}
          If one of the above reasons applies and a data subject wishes to have
          personal data stored at Peer Nissen deleted, they can contact an
          employee of the person responsible for processing at any time. The
          employee of the Peer Nissen will arrange for the deletion request to
          be complied with immediately.{" "}
        </p>

        <p>
          {" "}
          If the personal data has been made public by the Peer Nissen and our
          company, as the person responsible, is obliged to delete the personal
          data in accordance with Art. 17 Paragraph 1 GDPR, the Peer Nissen
          shall take appropriate measures, taking into account the available
          technology and the implementation costs Measures, including of a
          technical nature, to inform other data processors who process the
          published personal data that the data subject has requested the
          deletion of all links to this personal data or copies or replications
          from these other data processors this has requested personal data,
          insofar as the processing is not necessary. The employee of the Peer
          Nissen will arrange the necessary in individual cases.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> e) Right to restriction of processing </h4>
        <p>
          {" "}
          Every person affected by the processing of personal data has the right
          granted by the European legislator of directives and regulations to
          require the controller to restrict the processing if one of the
          following conditions is met:{" "}
        </p>
        <ul style={{ listStyle: "none" }}>
          <li>
            {" "}
            The correctness of the personal data is contested by the data
            subject for a period that enables the person responsible to check
            the correctness of the personal data.{" "}
          </li>
          <li>
            {" "}
            The processing is unlawful, the data subject refuses to delete the
            personal data and instead requests that the use of the personal data
            be restricted.{" "}
          </li>
          <li>
            {" "}
            The person responsible no longer needs the personal data for the
            purposes of processing, but the data subject needs them to assert,
            exercise or defend legal claims.{" "}
          </li>
          <li>
            {" "}
            The person concerned has lodged an objection to the processing in
            accordance with Art. 21 Para. 1 GDPR and it has not yet been
            determined whether the legitimate reasons of the person responsible
            outweigh those of the person concerned.{" "}
          </li>
        </ul>
        <p>
          {" "}
          If one of the above conditions is met and a person concerned would
          like to request the restriction of personal data stored at Peer
          Nissen, they can contact an employee of the person responsible for
          processing at any time. The employee of the Peer Nissen will arrange
          the restriction of the processing.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> f) Right to data portability </h4>
        <p>
          {" "}
          Every person affected by the processing of personal data has the right
          granted by the European legislator of directives and regulations to
          receive the personal data concerning them, which have been made
          available to a responsible party by the person concerned, in a
          structured, common and machine-readable format. You also have the
          right to transfer this data to another person responsible without
          hindrance from the person responsible to whom the personal data was
          provided, provided that the processing is based on the consent in
          accordance with Art. 6 Para. 1 Letter a GDPR or Art. 9 Para . 2 letter
          a DS-GVO or on a contract according to Art. 6 para. 1 letter b DS-GVO
          and the processing is carried out using automated procedures, unless
          the processing is necessary for the performance of a task that is in
          the public interest or takes place in the exercise of public
          authority, which was transferred to the person responsible.{" "}
        </p>
        <p>
          {" "}
          Furthermore, when exercising their right to data portability in
          accordance with Art. 20 Para. 1 GDPR, the person concerned has the
          right to have the personal data transmitted directly from one
          responsible person to another responsible person, as far as this is
          technically feasible and provided that this does not affect the rights
          and freedoms of other persons.{" "}
        </p>
        <p>
          {" "}
          To assert the right to data portability, the person concerned can
          contact an employee of Peer Nissen at any time.{" "}
        </p>
      </li>
      <li>
        <h4> g) Right to object </h4>
        <p>
          {" "}
          Every person affected by the processing of personal data has the right
          granted by the European legislator of directives and regulations, for
          reasons that arise from their particular situation, at any time to
          object to the processing of personal data relating to them, which is
          based on Art. 6 Para. 1 letter e or f DS-GVO takes place, to object.
          This also applies to profiling based on these provisions.{" "}
        </p>

        <p>
          {" "}
          In the event of an objection, Peer Nissen will no longer process the
          personal data, unless we can prove compelling legitimate reasons for
          the processing that outweigh the interests, rights and freedoms of the
          data subject, or the processing serves to assert, Exercise or defense
          of legal claims.{" "}
        </p>

        <p>
          {" "}
          If the Peer Nissen processes personal data in order to operate direct
          mail, the person concerned has the right to object at any time to the
          processing of personal data for the purpose of such advertising. This
          also applies to profiling insofar as it is related to such direct
          mail. If the data subject objects to the processing of the Peer Nissen
          for direct marketing purposes, the Peer Nissen will no longer process
          the personal data for these purposes.{" "}
        </p>

        <p>
          {" "}
          In addition, the data subject has the right, for reasons that arise
          from their particular situation, to object to the processing of
          personal data concerning them that is carried out by Peer Nissen for
          scientific or historical research purposes or for statistical purposes
          in accordance with Art. 89 Para. 1 DS-GVO, to object, unless such
          processing is necessary to fulfill a task in the public interest.{" "}
        </p>

        <p>
          {" "}
          To exercise the right to object, the person concerned can contact any
          Peer Nissen employee or another employee directly. The data subject is
          also free, in connection with the use of information society services,
          notwithstanding Directive 2002/58 / EC, to exercise their right of
          objection by means of automated processes that use technical
          specifications.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4>
          {" "}
          h) Automated decisions in individual cases including profiling{" "}
        </h4>
        <p>
          {" "}
          Every person affected by the processing of personal data has the
          right, granted by the European legislator of directives and
          regulations, not to be subjected to a decision based solely on
          automated processing - including profiling - which has legal effect on
          them or in a similar way significantly affected, provided that the
          decision (1) is not necessary for the conclusion or performance of a
          contract between the data subject and the person responsible, or (2)
          is permissible based on legal provisions of the Union or of the member
          states to which the person responsible is subject and this Legislation
          contains appropriate measures to safeguard the rights and freedoms as
          well as the legitimate interests of the data subject or (3) takes
          place with the express consent of the data subject.{" "}
        </p>
        <p>
          {" "}
          If the decision (1) is necessary for the conclusion or fulfillment of
          a contract between the data subject and the person responsible or (2)
          it is made with the express consent of the data subject, the Peer
          Nissen takes appropriate measures to safeguard the rights and freedoms
          as well as to protect the legitimate interests of the data subject,
          including at least the right to obtain the intervention of a person on
          the part of the person responsible, to express their own point of view
          and to contest the decision.{" "}
        </p>
        <p>
          {" "}
          If the data subject would like to assert rights with regard to
          automated decisions, they can contact an employee of the controller at
          any time.{" "}
        </p>
      </li>
      <li>
        {" "}
        <h4> i) Right to withdraw consent under data protection law </h4>
        <p>
          {" "}
          Every person affected by the processing of personal data has the right
          granted by the European directives and regulations to revoke their
          consent to the processing of personal data at any time.{" "}
        </p>
        <p>
          {" "}
          If the person concerned wishes to assert their right to withdraw
          consent, they can contact an employee of the controller at any time.{" "}
        </p>
      </li>
    </ul>
    <h4>
      {" "}
      7. Data protection for applications and in the application process{" "}
    </h4>
    <p>
      {" "}
      The person responsible for processing collects and processes the personal
      data of applicants for the purpose of handling the application process.
      The processing can also be done electronically. This is particularly the
      case if an applicant sends the relevant application documents
      electronically, for example by email or via a web form on the website, to
      the person responsible for processing. If the person responsible for
      processing concludes an employment contract with an applicant, the data
      transmitted will be stored for the purpose of processing the employment
      relationship in compliance with the statutory provisions. If the person
      responsible for processing does not conclude an employment contract with
      the applicant, the application documents will be automatically deleted two
      months after notification of the rejection decision, provided that
      deletion does not conflict with any other legitimate interests of the
      person responsible for processing. Other legitimate interest in this sense
      is, for example, a burden of proof in proceedings under the General Equal
      Treatment Act (AGG).{" "}
    </p>

    <h4>
      {" "}
      8. Payment method: Data protection provisions for PayPal as a payment
      method{" "}
    </h4>
    <p>
      {" "}
      The person responsible for processing has integrated components from
      PayPal on this website. PayPal is an online payment service provider.
      Payments are processed via so-called PayPal accounts, which are virtual
      private or business accounts. PayPal also offers the option of processing
      virtual payments via credit cards if a user does not have a PayPal
      account. A PayPal account is managed via an e-mail address, which is why
      there is no classic account number. PayPal makes it possible to initiate
      online payments to third parties or to receive payments. PayPal also acts
      as a trustee and offers buyer protection services.{" "}
    </p>

    <p>
      {" "}
      The European operating company of PayPal is PayPal (Europe) S.à.r.l. et
      Cie, S.C.A., 22-24 Boulevard Royal L-2449, Luxembourg.{" "}
    </p>

    <p>
      {" "}
      If the person concerned selects "PayPal" as the payment option during the
      ordering process in our online shop, the data of the person concerned will
      be automatically transmitted to PayPal. By selecting this payment option,
      the person concerned consents to the transfer of personal data required
      for payment processing.{" "}
    </p>

    <p>
      {" "}
      The personal data transmitted to PayPal is usually first name, last name,
      address, email address, IP address, telephone number, mobile phone number
      or other data that are necessary for payment processing. In order to
      process the purchase contract, personal data related to the respective
      order are also necessary.{" "}
    </p>

    <p>
      {" "}
      The purpose of transmitting the data is to process payments and prevent
      fraud. The person responsible for processing will transmit personal data
      to PayPal in particular if there is a legitimate interest in the
      transmission. The personal data exchanged between PayPal and the person
      responsible for processing may be transmitted by PayPal to credit
      agencies. The purpose of this transmission is to check identity and
      creditworthiness.{" "}
    </p>

    <p>
      {" "}
      PayPal may pass on the personal data to affiliated companies and service
      providers or subcontractors, insofar as this is necessary to fulfill the
      contractual obligations or the data are to be processed on behalf of.{" "}
    </p>

    <p>
      {" "}
      The person concerned has the option to revoke their consent to the
      handling of personal data at any time vis-à-vis PayPal. A revocation does
      not affect personal data that must be processed, used or transmitted for
      (contractual) payment processing.{" "}
    </p>

    <p>
      {" "}
      The applicable data protection provisions of PayPal can be found at
      https://www.paypal.com/de/webapps/mpp/ua/privacy-full.{" "}
    </p>

    <h4> 9. Legal basis for processing </h4>
    <p>
      {" "}
      Art. 6 I lit. a DS-GVO serves our company as the legal basis for
      processing operations for which we obtain consent for a specific
      processing purpose. If the processing of personal data is necessary to
      fulfill a contract to which the data subject is a party, as is the case,
      for example, with processing operations that are necessary for the
      delivery of goods or the provision of other services or consideration, the
      processing is based on Art. 6 I lit. b GDPR. The same applies to
      processing operations that are necessary to carry out pre-contractual
      measures, for example in cases of inquiries about our products or
      services. If our company is subject to a legal obligation which requires
      the processing of personal data, for example to fulfill tax obligations,
      the processing is based on Art. 6 I lit. c GDPR. In rare cases, the
      processing of personal data may be necessary to protect the vital
      interests of the data subject or another natural person. This would be the
      case, for example, if a visitor to our company were injured and his name,
      age, health insurance data or other vital information would have to be
      passed on to a doctor, hospital or other third party. Then the processing
      would be based on Art. 6 I lit. d GDPR. Ultimately, processing operations
      could be based on Art. 6 I lit.f GDPR. Processing operations that are not
      covered by any of the aforementioned legal bases are based on this legal
      basis if the processing is necessary to safeguard a legitimate interest of
      our company or a third party, provided that the interests, fundamental
      rights and freedoms of the data subject do not prevail. We are
      particularly permitted to carry out such processing operations because
      they have been specifically mentioned by the European legislator. In this
      respect, he took the view that a legitimate interest could be assumed if
      the person concerned is a customer of the person responsible (recital 47
      sentence 2 GDPR).
    </p>

    <h4>
      {" "}
      10. Legitimate interests in the processing that are being pursued by the
      controller or a third party{" "}
    </h4>
    <p>
      {" "}
      If the processing of personal data is based on Article 6 I lit.f DS-GVO,
      our legitimate interest is the conduct of our business activities for the
      benefit of all our employees and our shareholders.{" "}
    </p>

    <h4> 11. Duration for which the personal data is stored </h4>
    <p>
      {" "}
      The criterion for the duration of the storage of personal data is the
      respective statutory retention period. After the deadline has expired, the
      relevant data is routinely deleted, provided that it is no longer required
      to fulfill the contract or to initiate a contract.{" "}
    </p>

    <h4>
      {" "}
      12. Legal or contractual provisions for the provision of personal data;
      Necessity for the conclusion of the contract; Obligation of the data
      subject to provide the personal data; possible consequences of failure to
      provide{" "}
    </h4>
    <p>
      {" "}
      We would like to inform you that the provision of personal data is partly
      required by law (e.g. tax regulations) or that it may result from
      contractual regulations (e.g. information on the contractual partner). In
      order to conclude a contract, it may sometimes be necessary for a data
      subject to provide us with personal data that we subsequently have to
      process. For example, the person concerned is obliged to provide us with
      personal data when our company concludes a contract with them. Failure to
      provide personal data would mean that the contract could not be concluded
      with the person concerned. Before the person concerned provides personal
      data, the person concerned must contact one of our employees. Our employee
      explains to the person concerned on a case-by-case basis whether the
      provision of the personal data is required by law or contract or is
      necessary for the conclusion of the contract, whether there is an
      obligation to provide the personal data and what consequences the failure
      to provide the personal data would have.
    </p>

    <h4> 13. Existence of automated decision-making </h4>
    <p>
      {" "}
      As a responsible company, we do not use automatic decision-making or
      profiling.{" "}
    </p>

    <p>
      {" "}
      This data protection declaration was translated by Google Translate after
      it was created by the data protection declaration generator of the DGD
      Deutsche Gesellschaft für Datenschutz GmbH, which is called{" "}
      <a
        href="https://dg-datenschutz.de/datenschutz-dienstleistungen/externer-datenschutzbeauftragter/"
        rel="nofollow"
      >
        {" "}
        External data protection officer Kaufbeuren{" "}
      </a>{" "}
      works in cooperation with the{" "}
      <a href="https://www.wbs-law.de/it-recht/datenschutzrecht" rel="nofollow">
        {" "}
        lawyer for data protection{" "}
      </a>{" "}
      Christian Solmecke created.
    </p>
  </div>
);
