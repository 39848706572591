import React from "react";
import HorzizontalLayers from "./HorzizontalLayers";
import VerticalLayers from "./VerticalLayers";
import { HorizontalLayer } from "../../interfaces/HorizontalLayer";
import { VerticalLayer } from "../../interfaces/VerticalLayer";
import { ViewableLayers } from "../../interfaces/ViewableLayers";

interface IProps {
    Layers: ViewableLayers;
}


export default (props: IProps) => {


    return (<div className="Layers" >
        <div className="corner" />
        <HorzizontalLayers
            SwapToPrevLayer={(index) => props.Layers.SwapHorizontalLayerToPrev(index)}
            SwapToNextLayer={(index) => props.Layers.SwapHorizontalLayerToNext(index)}
            width={props.Layers.Width}
            layers={props.Layers.HorizontalLayers}
            AddLayer={props.Layers.AddHorizontalLayer} DeleteLayer={(layer: HorizontalLayer) => props.Layers.RemoveHorizontalLayer(layer)} />
        <VerticalLayers
            SwapToNextLayer={(index) => props.Layers.SwapVerticalLayerToNext(index)}
            SwapToPrevLayer={(index) => props.Layers.SwapVerticalLayerToPrev(index)}
            height={props.Layers.Height} layers={props.Layers.VerticalLayers}
            AddLayer={props.Layers.AddVerticalLayer}
            DeleteLayer={(layer: VerticalLayer) => props.Layers.RemoveVerticalLayer(layer)} />
    </div>)


}
