import { ImportInformation } from "../../../Numl/interfaces/ImportInformation";
import { EnumExportInformation } from "../../interfaces/EnumExportInformation";


export class DefaultEnumExportInformation implements EnumExportInformation {
    Imports: ImportInformation[] = [];
    VerticalDimension: string;
    HorizontalDimension: string;
    Name: string;

    constructor(importInformation: ImportInformation, public Values: string[]) {
        this.Name = importInformation.Name;
        this.VerticalDimension = importInformation.VerticalDimension;
        this.HorizontalDimension = importInformation.HorizontalDimension;
    }
}