import React from "react";
import "./Arrow.css";

import { Arrow } from "../interfaces/Arrow";
import HorizontalArrow from "./HorizontalArrow";
import { RelationType } from "../../Diagram/interfaces/RelationType";
import VerticalArrow from "./VerticalArrow";

interface IProps extends Arrow {}

export default (props: IProps): JSX.Element => {
  if (props.RelationType === RelationType.Inheritance) {
    return <VerticalArrow {...props} />;
  }
  return <HorizontalArrow {...props} />;
};

