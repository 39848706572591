import { ExportBuilder } from "../../interfaces/ExportBuilder";
import { IFile } from "../../interfaces/IFile";
import { Directory } from "../../interfaces/Directory";
import { DefaultDirectory } from "./DefaultDirectory";




export class DefaultExportBuilder implements ExportBuilder {

    private root: Directory;
    constructor(root: string, private interfaceDirectory: string, verticalDimensions: string[], horizontalDimensions: string[]) {
        this.root = new DefaultDirectory(root);
        this.addVerticalDimensions(verticalDimensions.map(s=> s.replace(/\s+/g, "_")));
        this.addHorizontalDimesions(verticalDimensions.map(s=> s.replace(/\s+/g, "_")), horizontalDimensions.map(s=> s.replace(/\s+/g, "_")))
    }

    private addVerticalDimensions(verticalDimensions: string[]) {
        verticalDimensions.forEach(name => {
            const dir: Directory = new DefaultDirectory(name)
            dir.Add(new DefaultDirectory(this.interfaceDirectory));
            this.root.Add(dir);

        });
    }

    private addHorizontalDimesions(verticalDimensions: string[], horizontalDimensions: string[]) {
        horizontalDimensions.forEach(horizontalDimension => {
            verticalDimensions.forEach(verticalDimension => {
                (this.root.Content[verticalDimension] as Directory).Add(new DefaultDirectory(horizontalDimension));
            })
        })
    }

    AddClass(verticalLayer: string, horizontalLayer: string, classFile: IFile): void {
        ((this.root.Content[verticalLayer] as Directory).Content[horizontalLayer] as Directory).Add(classFile);
    }

    AddInterface(verticalDimension: string, interfaceFile: IFile): void {
        ((this.root.Content[verticalDimension] as Directory).Content[this.interfaceDirectory] as Directory).Add(interfaceFile);
    }

    GetRoot(): Directory {
        return this.root;
    }


}