import { JsonParser } from "../../interfaces/JsonParser";
import { JsonDiagram } from "../../../Diagram/interfaces/JsonDiagram";
import { JsonNuml } from "../../../Numl/interfaces/JsonNuml";
import { Property } from "../../../Numl/interfaces/Property";
import { Constructor } from "../../../Numl/interfaces/Constructor";
import { Method } from "../../../Numl/interfaces/Method";
import { Diagram } from "../../../Diagram/interfaces/Diagram";
import { DefaultDiagram } from "../../../Diagram/model/impl/DefaultDiagram";
import { DefaultHorizontalLayer } from "../../../Layer/model/impl/DefaultHorizontalLayer";
import { DefaultVerticalLayer } from "../../../Layer/model/impl/DefaultVerticalLayer";
import { DefaultNuml } from "../../../Numl/model/impl/DefaultNuml";
import { DefaultEnum } from "../../../Enum/model/impl/DefaultEnum";
import { DefaultViewDefinition } from "../../../ViewDefinition/model/DefaultViewDefinition";
import { DefaultType } from "../../../Type/model/impl/DefaultType";
import { Numl } from "../../../Numl/interfaces/Numl";
import { EventMember } from "../../../EventMember/interfaces/EventMember";

export class DefaultJsonParser implements JsonParser {
  ParseToJsonString(diagram: Diagram): string {
    return JSON.stringify(this.ParseToJson(diagram), null, 2);
  }

  ParseDiagramsToJsonString(diagrams: { [key: string]: Diagram }): string {
    return JSON.stringify(this.ParseDiagramsToJson(diagrams));
  }

  ParseFromJsonString(diagram: string): Diagram {
    return this.ParseFromJson(JSON.parse(diagram) as JsonDiagram);
  }

  ParseDiagramsFromJson(diagrams: {
    [key: string]: JsonDiagram;
  }): { [key: string]: Diagram } {
    let ds: JsonDiagram[] = Object.values(diagrams);
    let json: { [key: string]: Diagram } = {};
    ds.forEach((d) => {
      try {
        json[d.Name] = this.ParseFromJson(d);
      } catch (err) {
        console.log((err as Error).message);
      }
    });
    return json;
  }

  ParseDiagramsFromJsonString(diagrams: string): { [key: string]: Diagram } {
    let jsonDiagrams: { [key: string]: JsonDiagram } = JSON.parse(diagrams) as {
      [key: string]: JsonDiagram;
    };
    return this.ParseDiagramsFromJson(jsonDiagrams);
  }

  ParseFromJson(diagram: JsonDiagram): Diagram {
    const cd: Diagram = new DefaultDiagram(diagram.Name);
    if (diagram.Numles) {
      diagram.Numles.forEach((numl: JsonNuml) => {
        let cl: Numl = new DefaultNuml(numl.Name, numl.Expands, numl.Position);
        numl.Member.forEach((member: string) => {
          cl.AddMemberByString(member);
        });
        cd.AddNuml(cl);
      });
    }
    if (diagram.Views) {
      diagram.Views.forEach((v) =>
        cd.AddView(
          new DefaultViewDefinition(
            v.Name,
            v.Position,
            v.Dependencies.map((s) => new DefaultType(s))
          )
        )
      );
    }
    diagram.Enums.forEach((e) =>
      cd.AddEnum(new DefaultEnum(e.Name, e.Position, e.Values))
    );
    diagram.Layers.HorizontalLayers.forEach((l) =>
      cd.AddHorizontalLayer(new DefaultHorizontalLayer(l.Name, l.Height))
    );
    diagram.Layers.VerticalLayers.forEach((l) =>
      cd.AddVerticalLayer(new DefaultVerticalLayer(l.Name, l.Width))
    );
    return cd;
  }

  ParseToJson(diagram: Diagram): JsonDiagram {
    return {
      Name: diagram.Name,
      Numles: diagram.Numles.map(
        (arrangeableRelationalNuml: Numl): JsonNuml => {
          return {
            Name: arrangeableRelationalNuml.Name,
            Position: arrangeableRelationalNuml.Position,
            Member: arrangeableRelationalNuml.Properties.map(
              this.propertyToString.bind(this)
            )
              .concat(
                arrangeableRelationalNuml.Events.map(
                  this.eventToString.bind(this)
                )
              )
              .concat(
                arrangeableRelationalNuml.Constructors.map(
                  this.ctorToString.bind(this)
                )
              )
              .concat(
                arrangeableRelationalNuml.Methods.map(
                  this.methodToString.bind(this)
                )
              ),
            Expands: arrangeableRelationalNuml.Expands,
          };
        }
      ),
      Views: diagram.Views.map((v) => {
        return {
          Name: v.Name,
          Dependencies: v.Dependencies.map((t) => t.FullType),
          Position: v.Position,
        };
      }),
      Enums: diagram.Enums.map((e) => {
        return { Name: e.Name, Values: e.Values, Position: e.Position };
      }),
      Layers: {
        HorizontalLayers: diagram.HorizontalLayers.map((l) => {
          return { Height: l.Height, Name: l.Name };
        }),
        VerticalLayers: diagram.VerticalLayers.map((l) => {
          return { Width: l.Width, Name: l.Name };
        }),
      },
    };
  }

  ParseDiagramsToJson(diagrams: {
    [key: string]: Diagram;
  }): { [key: string]: JsonDiagram } {
    let ds: Diagram[] = Object.values(diagrams);
    let json: { [key: string]: JsonDiagram } = {};
    ds.forEach((d) => {
      json[d.Name] = this.ParseToJson(d);
    });
    return json;
  }

  private propertyToString(prop: Property): string {
    return prop.Name + ": " + prop.ReturnType.FullType;
  }
  private ctorToString(ctor: Constructor): string {
    if (ctor.ConcreteGenerics.length > 0) {
      return (
        ctor.Name +
        this.paramsToString(ctor.ParamTypes.map((t) => t.FullType)) +
        "<" +
        ctor.ConcreteGenerics.map((t) => t.FullType).join(",") +
        ">"
      );
    }
    return (
      ctor.Name + this.paramsToString(ctor.ParamTypes.map((t) => t.FullType))
    );
  }
  private eventToString(event: EventMember): string {
    return (
      event.Name +
      " <= " +
      this.paramsToString(event.ParamTypes.map((t) => t.FullType))
    );
  }
  private methodToString(method: Method): string {
    return (
      method.Name +
      this.paramsToString(method.ParamTypes.map((t) => t.FullType)) +
      ": " +
      method.ReturnType.FullType
    );
  }
  private paramsToString(params: string[]): string {
    let s: string = "(";
    params.forEach((p: string, i: number) => {
      if (i > 0) {
        s += ", ";
      }
      s += p;
    });
    s += ")";
    return s;
  }
}
