import { DefaultObserveable } from "../../../Environment/model/DefaultObserveable";
import { VerticalLayer } from "../../interfaces/VerticalLayer";
import { ObserveableVerticalLayer } from "../../interfaces/ObserveableVerticalLayer";



export class DefaultObserveableVerticalLayer extends DefaultObserveable<VerticalLayer> implements ObserveableVerticalLayer {

    Width: number = this.original.Width;
    Name: string = this.original.Name;
    public readonly Id:string = this.original.Id;

    Rename(name: string): void {
        this.original.Rename(name);
        this.raiseNext(this.original);
    }

    ResizeWidth(newWidth: number): void {
        this.original.ResizeWidth(newWidth)
        this.raiseNext(this.original)
    }

}