import { DragHandler } from "../interface/DragHandler";
import { Datapoint } from "../../Diagram/interfaces/Datapoints";
import { Observer } from "../interface/Observer";



export class DefaultDragHandler implements DragHandler {
    private mousePosition: Datapoint = { x: 0, y: 0 }

    constructor(private observer: Observer<Datapoint>) { }


    handleDrag(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        event.preventDefault();
        this.mousePosition = {
            x: event.clientX,
            y: event.clientY
        };
        document.onmouseup = this.stopDrag.bind(this);
        document.onmousemove = this.move.bind(this);
    }

    move(event: MouseEvent): void {
        const newPosition = {
            x: event.clientX,
            y: event.clientY
        }
        const delta = {
            x: this.mousePosition.x - newPosition.x,
            y: this.mousePosition.y - newPosition.y
        }
        this.mousePosition = newPosition;
        this.observer.Next(delta);
    }

    stopDrag(): void {
        document.onmouseup = null;
        document.onmousemove = null;
        this.observer.Complete();
    }
}