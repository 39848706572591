import React from "react";
import "./EventMember.css";

import { IsDeletable } from "../../Numl/view/IsDeletable";
import { IsChangeable } from "../../Numl/interfaces/IsChangeable";
import SingleLineEditor from "../../Controls/view/SingleLineEditor/SingleLineEditor";
import Params from "../../Numl/elements/Params/Params";
import { EventMember } from "../interfaces/EventMember";

interface IProps extends IsDeletable, IsChangeable {
  event: EventMember;
}

export default (props: IProps): JSX.Element => (
  <SingleLineEditor
    Delete={props.Delete}
    OnDidChange={props.Change}
    Title={
      <div className="Property">
        <div>{props.event.Name}&lt;=</div>
        <Params params={props.event.ParamTypes} />
      </div>
    }
    preSet={
      props.event.Name +
      " <= (" +
      props.event.ParamTypes.map((p) => p.FullType).join(", ") +
      ")"
    }
  />
);
