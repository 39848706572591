import React from "react";
import "./ReturnType.css";
import TypeView from "../Type/Type";
import { Type } from "../../../Type/interfaces/Type";

// tslint:disable-next-line: interface-name
interface Props {
    type: Type;
}

export default (props:Props): JSX.Element => <div className="ReturnType">:&nbsp;<TypeView type={props.type} /></div>;