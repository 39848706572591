import { Diagram } from "../../interfaces/Diagram";
import { DefaultObserveable } from "../../../Environment/model/DefaultObserveable";
import { HorizontalLayer } from "../../../Layer/interfaces/HorizontalLayer";
import { VerticalLayer } from "../../../Layer/interfaces/VerticalLayer";
import { Observer } from "../../../Environment/interface/Observer";
import { ObserveableHorizontalLayer } from "../../../Layer/interfaces/ObserveableHorizontalLayer";
import { DefaultObserveableHorizontalLayer } from "../../../Layer/model/impl/DefaultObserveableHorizontalLayer";
import { ObserveableVerticalLayer } from "../../../Layer/interfaces/ObserveableVerticalLayer";
import { DefaultObserveableVerticalLayer } from "../../../Layer/model/impl/DefaultObserveableVerticalLayer";
import { ObserveableDiagram } from "../../interfaces/ObserveableDiagram";
import { Datapoint } from "../../interfaces/Datapoints";
import { Section } from "../../interfaces/Sectrion";
import { Enum } from "../../../Enum/interfaces/Enum";
import { ObserveableEnum } from "../../../Enum/interfaces/ObserveableEnum";
import { DefaultObserveableEnum } from "../../../Enum/model/impl/DefaultObserveableEnum";
import { ViewDefinition } from "../../../ViewDefinition/interfaces/ViewDefinition";
import { ObserveableViewDefinition } from "../../../ViewDefinition/interfaces/ObserveableViewDefinition";
import { DefaultObserveableViewDefinition } from "../../../ViewDefinition/model/DefaultObserveableEnum";
import { Numl } from "../../../Numl/interfaces/Numl";
import { ObserveableNuml } from "../../../Numl/interfaces/ObserveableNuml";
import { DefaultObserveableNuml } from "../../../Numl/model/impl/DefaultObserveableNuml";


export class DefaultObserveableDiagram extends DefaultObserveable<Diagram> implements ObserveableDiagram {

    public get Numles(): Numl[] {
        return this.diagram.Numles.map(c => {
            if ((c as ObserveableNuml).IsObserveable) {
                (c as ObserveableNuml).Attach(this.observer);
                return c;
            } else {
                const oc: ObserveableNuml = new DefaultObserveableNuml(c);
                oc.Attach(this.observer);
                return oc;
            }
        });
    }

    public get Enums(): Enum[] {
        return this.diagram.Enums.map(e => {
            if ((e as ObserveableEnum).IsObserveable) {
                (e as ObserveableEnum).Attach(this.observer)
                return e;
            } else {
                const oe: ObserveableEnum = new DefaultObserveableEnum(e);
                oe.Attach(this.observer);
                return oe;
            }
        })
    }

    public get Views(): ViewDefinition[] {
        return this.diagram.Views.map(e => {
            if ((e as ObserveableViewDefinition).IsObserveable) {
                (e as ObserveableViewDefinition).Attach(this.observer)
                return e;
            } else {
                const oe: ObserveableViewDefinition = new DefaultObserveableViewDefinition(e);
                oe.Attach(this.observer);
                return oe;
            }
        })
    }

    public get Name(): string { return this.diagram.Name; }
    public get HorizontalLayers(): HorizontalLayer[] {
        return this.diagram.HorizontalLayers.map(l => {
            if ((l as ObserveableHorizontalLayer).IsObserveable) {
                (l as ObserveableHorizontalLayer).Attach(this.observer);
                return l;
            } else {
                const ol: ObserveableHorizontalLayer = new DefaultObserveableHorizontalLayer(l);
                ol.Attach(this.observer);
                return ol;
            }
        });
    }
    public get VerticalLayers(): VerticalLayer[] {
        return this.diagram.VerticalLayers.map(l => {
            if ((l as ObserveableVerticalLayer).IsObserveable) {
                (l as ObserveableVerticalLayer).Attach(this.observer);
                return l;
            } else {
                const ol: ObserveableVerticalLayer = new DefaultObserveableVerticalLayer(l);
                ol.Attach(this.observer);
                return ol;
            }
        });
    }


    private observer: Observer<any> = {
        Complete: () => { },
        Next: () => this.raiseNext(this),
        Error: () => { }
    }

    constructor(private diagram: Diagram) {
        super(diagram)
    }

    public RemoveHorizontalLayer(layer: HorizontalLayer) {
        this.diagram.RemoveHorizontalLayer(layer);
        this.raiseNext(this);
    }
    public AddHorizontalLayer(layer: HorizontalLayer) {
        this.diagram.AddHorizontalLayer(layer);
        this.raiseNext(this);
    }
    public SwapHorizontalLayerToNext(index: number) {
        this.diagram.SwapHorizontalLayerToNext(index);
        this.raiseNext(this);
    }
    public SwapHorizontalLayerToPrev(index: number) {
        this.diagram.SwapHorizontalLayerToPrev(index);
        this.raiseNext(this);
    }



    public RemoveVerticalLayer(layer: VerticalLayer) {
        this.diagram.RemoveVerticalLayer(layer);
        this.raiseNext(this);
    }
    public AddVerticalLayer(layer: VerticalLayer) {
        this.diagram.AddVerticalLayer(layer);
        this.raiseNext(this);
    }
    public SwapVerticalLayerToPrev(index: number) {
        this.diagram.SwapVerticalLayerToPrev(index);
        this.raiseNext(this);
    }
    public SwapVerticalLayerToNext(index: number) {
        this.diagram.SwapVerticalLayerToNext(index);
        this.raiseNext(this);
    }


    AddEnum(aEnum: Enum): void {
        if ((aEnum as ObserveableEnum).IsObserveable) {
            this.diagram.AddEnum(aEnum);
        } else {
            const oe = new DefaultObserveableEnum(aEnum);
            oe.Attach(this.observer);
            this.diagram.AddEnum(oe);
        }
        this.raiseNext(this);
    }
    RemoveEnum(aEnum: Enum): void {
        if ((aEnum as ObserveableEnum).IsObserveable) {
            (aEnum as ObserveableEnum).Detach(this.observer);
        }
        this.diagram.RemoveEnum(aEnum);
        this.raiseNext(this);
    }


    AddView(view: ViewDefinition): void {
        if ((view as ObserveableViewDefinition).IsObserveable) {
            this.diagram.AddView(view);
        } else {
            const oe = new DefaultObserveableViewDefinition(view);
            oe.Attach(this.observer);
            this.diagram.AddView(oe);
        }
        this.raiseNext(this);
    }
    RemoveView(view: ViewDefinition): void {
        if ((view as ObserveableViewDefinition).IsObserveable) {
            (view as ObserveableViewDefinition).Detach(this.observer);
        }
        this.diagram.RemoveView(view);
        this.raiseNext(this);
    }

    public Rename(name: string): void {
        this.diagram.Rename(name);
        this.raiseNext(this);
    }


    public AddNuml(numl: Numl): void {
        if ((numl as ObserveableNuml).IsObserveable) {
            (numl as ObserveableNuml).Detach(this.observer);
        }
        this.diagram.AddNuml(numl);
        this.raiseNext(this);
    }

    public DeleteNuml(numl: Numl): void {
        this.diagram.DeleteNuml(numl);
        this.raiseNext(this);
    }

    public IsValidType: (type: string) => boolean = this.diagram.IsValidType;
    public GetSection: (datapoint: Datapoint) => Section = this.diagram.GetSection;
    public FindNumlByName: (name: string) => Numl | undefined = this.diagram.FindNumlByName;
    public FindEnumByName: (name: string) => Enum | undefined = this.diagram.FindEnumByName;
    public FindViewByName: (name: string) => ViewDefinition | undefined = this.diagram.FindViewByName;
}