import { ArrangeableRelationalNuml } from "../../interfaces/ArrangeableRelationalNuml";
import { RelationalProperty } from "../../interfaces/RelationalProperty";
import { RelationalMethod } from "../../interfaces/RelationalMethod";
import { RelationalConstructor } from "../../interfaces/RelationalConstructor";
import { Property } from "../../interfaces/Property";
import { Constructor } from "../../interfaces/Constructor";
import { Method } from "../../interfaces/Method";
import { Datapoint } from "../../../Diagram/interfaces/Datapoints";
import { DefaultObserveable } from "../../../Environment/model/DefaultObserveable";
import { ObserveableArrangeableRelationalNuml } from "../../interfaces/ObserveableArrangeableRelationalNuml";
import { RelationalEventMember } from "../../../EventMember/interfaces/RelationalEventMember";

export class DefaultObserveableArrangeableRelationalNuml
  extends DefaultObserveable<ArrangeableRelationalNuml>
  implements ObserveableArrangeableRelationalNuml {
  public get Name(): string {
    return this.numl.Name;
  }
  public get Properties(): RelationalProperty[] {
    return this.numl.Properties;
  }
  public get Events(): RelationalEventMember[] {
    return this.numl.Events;
  }
  public get Constructors(): RelationalConstructor[] {
    return this.numl.Constructors;
  }
  public get Methods(): RelationalMethod[] {
    return this.numl.Methods;
  }
  public get Expands(): string[] {
    return this.numl.Expands;
  }

  public AddMember(member: Property | Constructor | Method): void {
    this.numl.AddMember(member);
    this.raiseNext(this);
  }
  public AddMemberByString(s: string): void {
    this.numl.AddMemberByString(s);
    this.raiseNext(this);
  }

  public get Position(): Datapoint {
    return this.numl.Position;
  }
  public get PositionOfCenter(): Datapoint {
    return this.numl.PositionOfCenter;
  }
  public get ExtendingDockingPoint(): Datapoint {
    return this.numl.ExtendingDockingPoint;
  }
  public get ExtendedDockingPoint(): Datapoint {
    return this.numl.ExtendedDockingPoint;
  }

  public Id: string = this.numl.Id;

  constructor(private numl: ArrangeableRelationalNuml) {
    super(numl);
  }

  public SwapPropertyToPrev(index: number): void {
    this.numl.SwapPropertyToPrev(index);
    this.raiseNext(this);
  }
  public SwapEventToPrev(index: number): void {
    this.numl.SwapEventToPrev(index);
    this.raiseNext(this);
  }
  public SwapMethodToPrev(index: number): void {
    this.numl.SwapMethodToPrev(index);
    this.raiseNext(this);
  }
  public SwapConstructorToPrev(index: number): void {
    this.numl.SwapConstructorToPrev(index);
    this.raiseNext(this);
  }
  public SwapPropertyToNext(index: number): void {
    this.numl.SwapPropertyToNext(index);
    this.raiseNext(this);
  }
  public SwapEventToNext(index: number): void {
    this.numl.SwapEventToNext(index);
    this.raiseNext(this);
  }
  public SwapMethodToNext(index: number): void {
    this.numl.SwapMethodToNext(index);
    this.raiseNext(this);
  }
  public SwapConstructorToNext(index: number): void {
    this.numl.SwapConstructorToNext(index);
    this.raiseNext(this);
  }

  public Rename(newName: string): void {
    this.numl.Rename(newName);
    this.raiseNext(this);
  }

  public DeleteMember(member: Property | Constructor | Method): void {
    this.numl.DeleteMember(member);
    this.raiseNext(this);
  }

  /**
   * Does not raise event Next!
   * @param x new x
   * @param y new y
   */
  public MoveTo(x: number, y: number): void {
    this.numl.MoveTo(x, y);
  }

  public RaiseNext() {
    if ((this.numl as any).RaisNext) {
      (this.numl as any).RaisNext();
    }
    this.raiseNext(this);
  }
}
