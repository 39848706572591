import React from "react";
import "./TabView.css";

import { TabElement } from "../interfaces/TabElement";

interface IProps {
  tabs: TabElement[];
}
interface IState {
  showingTab: number;
}

export default class TabView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { showingTab: 0 };
  }
  render() {
    return (
      <div className="TabView">
        <div className="header">
          {this.props.tabs.map((e, i) => (
            <div
              key={i}
              className={
                "headElement" + (i === this.state.showingTab ? " chosen" : "")
              }
              onClick={() => this.setState({ showingTab: i })}
            >
              {e.title}
            </div>
          ))}
        </div>
        <div className="body">
          {this.props.tabs[this.state.showingTab].content}
        </div>
      </div>
    );
  }
}
