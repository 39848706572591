import { Numl } from "../../interfaces/Numl";
import { Property } from "../../interfaces/Property";
import { Method } from "../../interfaces/Method";
import { EventMember } from "../../../EventMember/interfaces/EventMember";
import { Constructor } from "../../interfaces/Constructor";
import { MemberType } from "../../interfaces/MemberType";
import { MemberParser } from "./MemberParser";
import { ArrayHelper } from "../../../ArrayHelper/static/impl/ArrayHelper";
import { Datapoint } from "../../../Diagram/interfaces/Datapoints";

export class DefaultNuml implements Numl {
  public static NextIdentifier = 1;

  public get Properties(): Property[] {
    return [...this.properties];
  }

  public get Events(): EventMember[] {
    return [...this.events];
  }
  public get Methods(): Method[] {
    return [...this.methods];
  }
  public get Constructors(): Constructor[] {
    return [...this.constructors];
  }
  public get Id(): string {
    return this.id;
  }

  public get Position(): Datapoint {
    return this.position;
  }

  private properties: Property[] = [];
  private events: EventMember[] = [];
  private methods: Method[] = [];
  private constructors: Constructor[] = [];
  private id: string;

  constructor(
    public Name: string,
    public Expands: string[] = [],
    private position: Datapoint = { x: 0, y: 0 }
  ) {
    this.id = "numl-" + DefaultNuml.NextIdentifier++;
  }
  SwapPropertyToPrev = (index: number) =>
    ArrayHelper.SwapToPrev(this.properties, index);
  SwapEventToPrev = (index: number) =>
    ArrayHelper.SwapToPrev(this.events, index);
  SwapConstructorToPrev = (index: number) =>
    ArrayHelper.SwapToPrev(this.constructors, index);
  SwapMethodToPrev = (index: number) =>
    ArrayHelper.SwapToPrev(this.methods, index);

  // SwapPropertyToNext = (index: number) => ArrayHelper.SwapToNext(this.properties, index);
  SwapPropertyToNext(index: number) {
    ArrayHelper.SwapToNext(this.properties, index);
  }
  SwapEventToNext = (index: number) =>
    ArrayHelper.SwapToNext(this.events, index);
  SwapConstructorToNext = (index: number) =>
    ArrayHelper.SwapToNext(this.constructors, index);
  SwapMethodToNext = (index: number) =>
    ArrayHelper.SwapToNext(this.methods, index);

  public AddMember(
    member: Constructor | Property | Method | EventMember
  ): void {
    switch (member.Type) {
      case MemberType.Constructor:
        this.constructors.push(member as Constructor);
        break;
      case MemberType.Property:
        this.properties.push(member as Property);
        break;
      case MemberType.Method:
        this.methods.push(member as Method);
        break;
      case MemberType.Event:
        this.events.push(member as EventMember);
        break;
      default:
        throw new Error("unknown member");
    }
  }

  public Rename(newName: string): void {
    const splits: string[] = newName.trim().split(":");
    let expands: string[] = [];
    if (splits[1]) {
      expands = splits[1].split(",").map((s: string) => s.trim());
    }
    this.Name = splits[0];
    this.Expands = expands;
  }

  public AddMemberByString(s: string): void {
    let member:
      | Property
      | Constructor
      | Method
      | EventMember = MemberParser.ParseFromString(s);
    this.AddMember(member);
  }

  public MoveTo(x: number, y: number) {
    this.position.x = x;
    this.position.y = y;
  }

  public DeleteMember(member: Property | Constructor | Method): void {
    switch (member.Type) {
      case MemberType.Property:
        const prop: Property = member as Property;
        this.properties = this.properties.filter(
          (e) => e.Name !== prop.Name || e.ReturnType !== prop.ReturnType
        );
        break;
      case MemberType.Constructor:
        const ctor: Constructor = member as Constructor;
        this.constructors = this.constructors.filter(
          (e) => e.Name !== ctor.Name || e.ParamTypes !== ctor.ParamTypes
        );
        break;
      case MemberType.Method:
        const method: Method = member as Method;
        // tslint:disable-next-line: max-line-length
        this.methods = this.methods.filter(
          (e) =>
            e.Name !== method.Name ||
            e.ParamTypes !== method.ParamTypes ||
            e.ReturnType !== method.ReturnType
        );
        break;
    }
  }
}
