import React from "react";
import "./Information.css";
import LogEntry from "./LogEntry";

export default () => {
  const changelog: { version: string; updates: string[] }[] = [
    {
      version: "Next Feature",
      updates: ["Next will be better videos."],
    },
    {
      version: "28.04.2021 v0.11.0",
      updates: [
        "Spacing for , Privacy Policy, How to NUML, Datenschutzerklärung and Impressum returned",
        "The menu did get a new order.",
      ],
    },
    {
      version: "27.04.2021 v0.11.0",
      updates: ["Fixed concrete generic"],
    },
    {
      version: "26.04.2021 v0.10.0",
      updates: [
        "Provided examples and their code snippets",
        "Fiexed class name in TypeScript, Java and C# export",
      ],
    },
    {
      version: "24.04.2021 v0.9.0",
      updates: ["Improved design in diagram"],
    },
    {
      version: "23.04.2021 v0.8.0",
      updates: ["Added written documentation"],
    },
    {
      version: "20.04.2021 v0.7.0",
      updates: [
        "Changelog returned",
        "Refactored C# export.",
        "Supporting '()=> void' as a type.",
        "Fixed a jumping bug while moving a layer.",
        "Fixed package reference in java export",
        "Fixed arrow points",
      ],
    },
    {
      version: "01.04.2021 v0.6.0",

      updates: ["Big visual refactoring"],
    },
    {
      version: "09.03.2021 v0.5.0",

      updates: ["Fixed Java export"],
    },
    {
      version: "06.03.2021 v0.4.0",

      updates: ["Added Java export"],
    },
    {
      version: "01.03.2021 v0.3.0",
      updates: ["Added typescript-react export"],
    },
    {
      version: "20.02.2021 v0.2.0",
      updates: [
        "Now the Feature Site is shown as Startpage.",

        "Moved text input of horizontal layer to make it fully visible for the user",

        "The text input for renaming the horizontal layer was out of the window. This input field is moved to a visible space.",

        "The video was only in the firefox-browser played in the other browsers the video was downloaded.",

        "Z-indices was a mess. I fixed that for the diagram element.",

        "Constantly rerender while dragging a numl-element did cause performance issues.",
      ],
    },

    {
      version: "16.02.2021 First Release",
      updates: [
        "Hello, this is the first release. As you can see, there is still a lot to be done. That's why I only ask you to report game stoppers at first. If possible, I try to bring out an update every week. I am currently only running this project on a part-time basis. With donations, you enable me to get ahead with my work faster.",

        " About other features please write an email to: feature-request@peni4142.com",

        "About issues and bugs please write an email to: issues@peni4142.com",
      ],
    },
  ];
  return (
    <div className="mainContent">
      <div className="hero terra-cotta">
        <h1>Changelog</h1>
        <h3>Stay updated</h3>
      </div>
      <div className="indented section">
        {changelog.map((e) => (
          <LogEntry version={e.version} upadates={e.updates} />
        ))}
      </div>
    </div>
  );
};
