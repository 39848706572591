import React from "react";
import { Action } from "../../Controls/interfaces/Action";
import PageBuilder from "../../Page/view/PageBuilder";
import "./Button.css";

// tslint:disable-next-line: interface-name
interface Props {
  backgroundColor?: string;
  color?: string;
  Action: Action;
}

export default (props: Props): JSX.Element => (
  <div
    className="Button"
    onClick={() => {
      try {
        props.Action.Action(undefined);
      } catch (err) {
        PageBuilder.ShowError(err.message);
      }
    }}
  >
    {props.Action.Label}
  </div>
);

