import { Directory } from "../../interfaces/Directory";
import { FileStructureElement } from "../../interfaces/FilestructureElement";
import JSZip from "jszip";
import { IFile } from "../../interfaces/IFile";



export class DefaultDirectory implements Directory {
    public readonly IsDirectory = true;
    public readonly IsFile = false;

    public Content: { [key: string]: FileStructureElement } = {};

    constructor(public Name: string) { }

    Add(element: FileStructureElement) {
        this.Content[element.Name] = element;
    }

    Zip(root: JSZip = new JSZip()): JSZip {
        const keys: string[] = Object.keys(this.Content);
        keys.forEach(key => {
            const element: FileStructureElement = this.Content[key];
            if (element.IsFile) {
                root.file(element.Name, (element as IFile).Content);
            } else if (element.IsDirectory) {
                (element as Directory).Zip(root.folder(element.Name) as JSZip);
            }
        })
        return root;
    }
}