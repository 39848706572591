import { IFile } from "../../../FileStructure/interfaces/IFile";
import { DefaultFile } from "../../../FileStructure/model/impl/DefaultFile";
import { Type } from "../../../Type/interfaces/Type";
import { ViewExportInformation } from "../../interfaces/ViewExportInformation";
import { ViewFileCreator } from "../../interfaces/ViewFileCreator";


export class ReactFileCreator implements ViewFileCreator {

    private generateIProps(dependencies: Type[]) {
        const usedNames: string[] = [];

        return "interface IProps {\n\t" + dependencies.map(d => {
            const suggestedName = d.BaseType;
            let paramName = suggestedName;
            for (let i: number = 1; usedNames.includes(paramName); i++) {
                paramName = suggestedName + i;
            }
            usedNames.push(paramName);
            let optional: string = "";
            if (d.IsOptional) {
                optional = "?"
            }
            return paramName + optional + ": " + d.FullType;
        }).join(",\n\t") + "\n}\n"
    }

    CreateViewFile(exportInformation: ViewExportInformation): IFile {
        const content =
            `import React from "react"


`+ this.generateIProps(exportInformation.Depdencies) + `

interface IState { }


export default class `+ exportInformation.Name + ` extends React.Component<IProps, IState> {

    /**
     *
     */
    constructor(props: IProps) {
        super(props);
    }

    render() {
        return (
            <div>

            </div>
        )
    }
}`
        return new DefaultFile(exportInformation.Name + ".tsx", content);
    }

}