import { ArrangeableRelationalNuml } from "../../../Numl/interfaces/ArrangeableRelationalNuml";
import { ArrowProvider } from "../../../Arrow/interfaces/ArrowProvider";
import { ArrowVisitor } from "../../../Numl/viewmodel/impl/ArrowVisitor";
import { Arrow } from "../../../Arrow/interfaces/Arrow";
import { DefaultNuml } from "../../../Numl/model/impl/DefaultNuml";
import { ViewableDiagram } from "../../interfaces/ViewableDiagram";
import { Diagram } from "../../interfaces/Diagram";
import { HorizontalLayer } from "../../../Layer/interfaces/HorizontalLayer";
import { VerticalLayer } from "../../../Layer/interfaces/VerticalLayer";
import { DefaultArrangeableRelationalNuml } from "../../../Numl/viewmodel/impl/DefaultArrangeableRelarionalNuml";
import { Enum } from "../../../Enum/interfaces/Enum";
import { DefaultEnum } from "../../../Enum/model/impl/DefaultEnum";
import { Datapoint } from "../../interfaces/Datapoints";
import { ViewDefinition } from "../../../ViewDefinition/interfaces/ViewDefinition";
import { DefaultViewDefinition } from "../../../ViewDefinition/model/DefaultViewDefinition";
import { ViewableLayers } from "../../../Layer/interfaces/ViewableLayers";
import { DefaultViewableLayers } from "../../../Layer/viewmodel/DefaultViewableLayers";

export class DefaultViewableDiagram implements ViewableDiagram {
  public get HorizontalLayers(): HorizontalLayer[] {
    return this.diagram.HorizontalLayers;
  }
  public get VerticalLayers(): VerticalLayer[] {
    return this.diagram.VerticalLayers;
  }
  public GetNumles(): ArrangeableRelationalNuml[] {
    return this.diagram.Numles.map(
      (n) => new DefaultArrangeableRelationalNuml(n)
    );
  }
  public GetArrows(): Arrow[] {
    return this.arrowProvider.GetArrows();
  }
  public GetEnums(): Enum[] {
    return this.diagram.Enums;
  }
  public GetViews(): ViewDefinition[] {
    return this.diagram.Views;
  }

  public Name: string = this.diagram.Name;

  public Layers: ViewableLayers;

  private arrowProvider: ArrowProvider;

  constructor(private diagram: Diagram) {
    this.arrowProvider = new ArrowVisitor(diagram);
    this.Layers = new DefaultViewableLayers(diagram);
  }

  SwapHorizontalLayerToPrev = (index: number) =>
    this.diagram.SwapHorizontalLayerToPrev(index);
  SwapVerticalLayerToPrev = (index: number) =>
    this.diagram.SwapVerticalLayerToPrev(index);
  SwapHorizontalLayerToNext = (index: number) =>
    this.diagram.SwapHorizontalLayerToNext(index);
  SwapVerticalLayerToNext = (index: number) =>
    this.diagram.SwapVerticalLayerToNext(index);

  public AddHorizontalLayer = (layer: HorizontalLayer) =>
    this.diagram.AddHorizontalLayer(layer);
  public AddVerticalLayer = (layer: VerticalLayer) =>
    this.diagram.AddVerticalLayer(layer);

  public RemoveHorizontalLayer = (layer: HorizontalLayer) =>
    this.diagram.RemoveHorizontalLayer(layer);
  public RemoveVerticalLayer = (layer: VerticalLayer) =>
    this.diagram.RemoveVerticalLayer(layer);

  public IsValidType = this.diagram.IsValidType.bind(this);
  public DeleteNuml = (numl: ArrangeableRelationalNuml) =>
    this.diagram.DeleteNuml(numl);

  private addEnum = (aEnum: Enum) => this.diagram.AddEnum(aEnum);
  public AddEnumByString(s: string, position?: Datapoint) {
    const aEnum: Enum = new DefaultEnum(s, { x: 50, y: 50 });
    this.addEnum(aEnum);
    if (position) {
      aEnum.Position = position;
    }
  }
  public DeleteEnum: (aEnum: Enum) => void = (aEnum: Enum) =>
    this.diagram.RemoveEnum(aEnum);

  private addView = (view: ViewDefinition) => this.diagram.AddView(view);
  public AddViewByString(s: string, position?: Datapoint) {
    const view: ViewDefinition = new DefaultViewDefinition(s, { x: 50, y: 50 });
    this.addView(view);
    if (position) {
      view.Position = position;
    }
  }
  public DeleteView: (view: ViewDefinition) => void = (view: ViewDefinition) =>
    this.diagram.RemoveView(view);

  private addNuml = (numl: ArrangeableRelationalNuml) =>
    this.diagram.AddNuml(numl);
  public AddNumlByString(s: string, position?: Datapoint): void {
    const splits: string[] = s.trim().split(":");
    let expands: string[] = [];
    if (splits[1]) {
      expands = splits[1].split(",").map((s: string) => s.trim());
    }
    let numl: ArrangeableRelationalNuml = new DefaultArrangeableRelationalNuml(
      new DefaultNuml(splits[0], expands, position)
    );
    this.addNuml(numl);
  }
}
