import { Datapoint } from "../../Diagram/interfaces/Datapoints";
import { ViewableDependency } from "../interfaces/ViewableDependency";

export class DefaultViewableDependency implements ViewableDependency {

    public get PositionOfCenter(): Datapoint { return this.positionOfCenter }

    public get Name(): string { return this.name; }

    constructor(private name: string, private positionOfCenter: Datapoint) { }
    
    public Rename(name: string): void {
        this.name = name;
    }

}