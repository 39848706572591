import { ArrayHelper } from "../../../ArrayHelper/static/impl/ArrayHelper";
import { Section } from "../../../Diagram/interfaces/Sectrion";
import { Members } from "../../../Exporter/interfaces/Members";
import { ImportInformation } from "../../../Numl/interfaces/ImportInformation";
import { Numl } from "../../../Numl/interfaces/Numl";
import { MutableNuml } from "../../../Numl/model/impl/MutableNuml";
import { Type } from "../../../Type/interfaces/Type";
import { DefaultType } from "../../../Type/model/impl/DefaultType";
import { ClassExportInformation } from "../../interfaces/ClassExportInformation";



export class ClassExportInformationBuilder {

    constructor(private numl: Numl,
        private section: Section,
        private getImportInformation: ((element: string) => ImportInformation | undefined),
        private getNumlToExpand: ((name: Type) => Numl | undefined)) {

    }

    replaceType(type: Type, prevType: Type, newType: Type): Type {
        // @ts-ignore
        return new DefaultType(type.FullType.replace(new RegExp("\\b" + prevType.FullType + "\\b", "g"), newType.FullType));
    }


    replaceTypesOnNuml(numl: Numl, prevType: Type, newType: Type): Numl {
        const copy: Numl = new MutableNuml(numl);
        // @ts-ignore
        copy.Expands = copy.Expands.map(s => s.replace(new RegExp("\\b" + prevType.FullType + "\\b", "g"), newType.FullType));

        const replaceFoo: (t: Type) => Type = (t: Type) => this.replaceType(t, prevType, newType);
        copy.Properties = copy.Properties.map(p => {
            p.ReturnType = replaceFoo(p.ReturnType);
            return p;
        });
        copy.Methods = copy.Methods.map(m => {
            m.ParamTypes = m.ParamTypes.map(replaceFoo);
            m.ReturnType = replaceFoo(m.ReturnType);
            return m;
        });

        copy.Constructors = copy.Constructors.map(c => {
            c.ParamTypes = c.ParamTypes.map(replaceFoo);
            return c;
        });
        return copy
    }

    getMehtsAndProps(numl: Numl): Members {
        const numls: (Numl | undefined)[] = numl.Expands.map(n => this.getNumlToExpand(new DefaultType(n)));
        const expandTypes: Type[] = numl.Expands.map(n => new DefaultType(n));
        const members: Members = {
            Properties: numl.Properties,
            Methods: numl.Methods
        }
        numls.forEach((n, i) => {
            if (n) {
                let newNuml: Numl = new MutableNuml(n);
                const actualType = new DefaultType(n.Name);
                actualType.FurtherTypes.forEach((aft, index) => {
                    newNuml = this.replaceTypesOnNuml(newNuml, aft, expandTypes[i].FurtherTypes[index])
                })
                members.Properties = members.Properties.concat(newNuml.Properties);
                members.Methods = members.Methods.concat(newNuml.Methods);
            }
            return undefined;
        })
        return members;
    }

    private getImports(c: ClassExportInformation): ImportInformation[] {
        const nonGenericTypes: string[] = [];
        const mixedTpes: Type[] = [...c.Properties.map(p => p.ReturnType)].concat([...c.Constructors.flatMap(ctor => ctor.ParamTypes)]).concat([...c.Methods.flatMap(m => {
            const t: Type[] = [...m.ParamTypes];
            t.push(m.ReturnType);
            return t;
        })]);

        function addType(t: Type) {
            nonGenericTypes.push(t.BaseType);
            t.FurtherTypes.forEach(addType);
        }
        mixedTpes.forEach(addType);

        return nonGenericTypes.filter(ArrayHelper.UniqueFilter).map(this.getImportInformation).filter(i => i) as ImportInformation[];
    }

    GetClassExports(): ClassExportInformation[] {
        let exportInformation: ClassExportInformation = {
            Name: "",
            HorizontalDimension: this.section.HorizontalDimension,
            VerticalDimension: this.section.VerticalDimension,
            Expands: this.numl.Expands,
            Imports: [],
            Methods: this.numl.Methods,
            Properties: this.numl.Properties,
            Constructors: []
        };


        const constructors = ArrayHelper.GroupBy(this.numl.Constructors, "Name");
        return constructors.map(ctors => {
            let copy:Numl = new MutableNuml(this.numl);
            let numlType: Type = new DefaultType(copy.Name);
            ctors[0].ConcreteGenerics.forEach((t,i) =>{
                copy = this.replaceTypesOnNuml(copy,  numlType.FurtherTypes[i], t);
                numlType.FurtherTypes[i] = t;
            });
            copy.Name = numlType.FullType;
            const members: Members = this.getMehtsAndProps(copy);
            exportInformation.Name = copy.Name;
            exportInformation.Properties = members.Properties;
            exportInformation.Methods = members.Methods;
            const ex: ClassExportInformation = { ...exportInformation, ...{ Constructors: ctors } }
            ex.Imports = this.getImports(ex);
            return ex;
        });
    }
}