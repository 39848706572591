import { ArrayHelper } from "../../../ArrayHelper/static/impl/ArrayHelper";
import { Datapoint } from "../../../Diagram/interfaces/Datapoints";
import { Enum } from "../../interfaces/Enum";



export class DefaultEnum implements Enum {

    public readonly Id: string;
    private static nextIdentifier: number = 1;

    public get Values(): string[] {
        return [...this.values];
    }
    public get Name(): string {
        return this.name;
    }

    constructor(private name: string, public Position: Datapoint, private values: string[] = []) {
        this.Id = "enum-" + DefaultEnum.nextIdentifier++;
    }

    SwapValueToPrev = (index: number) => ArrayHelper.SwapToPrev(this.values, index);
    SwapValueToNext = (index: number) => ArrayHelper.SwapToNext(this.values, index);

    Add(s: string): void {

        if (/^s*$/.test(s)) {
            throw new Error("Value can not be empty");
        }
        if (this.values.includes(s)) {
            throw new Error("Value " + s + " is alread included");
        }
        this.values.push(s);
    }

    Remove(s: string): void {
        this.values = this.values.filter(str => str !== s);
    }

    Change(prev: string, newStr: string) {
        if (prev === newStr) {
            return
        }
        this.Add(newStr);
        this.Remove(prev);
    }

    MoveTo(x: number, y: number) {
        this.Position.x = x;
        this.Position.y = y;
    }



    Rename(name: string): void {
        this.name = name;
    }

}