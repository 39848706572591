import { Datapoint } from "../../../Diagram/interfaces/Datapoints";
import { Diagram } from "../../../Diagram/interfaces/Diagram";
import { HasPosition } from "../../../Diagram/interfaces/HasPosition";
import { Section } from "../../../Diagram/interfaces/Sectrion";
import { ElementType } from "../../../Environment/enums/ElementType";
import { HasName } from "../../../Environment/interface/HasName";
import { ExportBuilder } from "../../../FileStructure/interfaces/ExportBuilder";
import { FileStructureElement } from "../../../FileStructure/interfaces/FilestructureElement";
import { DefaultExportBuilder } from "../../../FileStructure/model/impl/DefaultExportBuilder";
import { ImportInformation } from "../../../Numl/interfaces/ImportInformation";
import { Type } from "../../../Type/interfaces/Type";
import { DefaultType } from "../../../Type/model/impl/DefaultType";
import { Exporter2 } from "../../interfaces/Exporter2";
import { FileCreator2 } from "../../interfaces/FileCreator2";
import { ClassExportInformationBuilder } from "./ClassExportInformationBuilder";
import { DefaultEnumExportInformation } from "./DefaultEnumExportInformation";
import { DefaultInterfaceExportInformation } from "./DefaultInterfaceExportInformation";
import { DefaultViewExportInformtation } from "./DefaultViewExportInformtation";


interface HasNameAndPosition extends HasName, HasPosition { }

export class DefaultExporter2 implements Exporter2 {

    constructor(private diagram: Diagram, private fileCreator: FileCreator2, private interfaces: string,
        private manipulateDimensionNames: (s: string) => string = (s) => s) {

    }

    private getSection(position: Datapoint): Section {
        let section: Section = this.diagram.GetSection(position)
        return {
            HorizontalDimension: section.HorizontalDimension.replace(/\s+/g, "_"),
            VerticalDimension: section.VerticalDimension.replace(/\s+/g, "_")
        }
    }

    private foo(element: HasNameAndPosition, type?: ElementType): ImportInformation {
        return {
            Name: new DefaultType(element.Name).BaseType,
            Type: type,
            ...this.getSection(element.Position)
        }
    }

    private getImportInfortmation(t: Type) {
        let r: any = this.diagram.Numles.find(n => new DefaultType(n.Name).TechnicalType === t.TechnicalType)
        if (r) {
            return this.foo(r, ElementType.Interface);
        }
        r = this.diagram.Enums.find(e => e.Name === t.FullType);
        if (r) {
            return this.foo(r, ElementType.Enum);
        }
        return undefined;
    }

    GetExport(): FileStructureElement {
        const exportBuilder: ExportBuilder = new DefaultExportBuilder(this.diagram.Name, this.interfaces, this.diagram.VerticalLayers.map(l => this.manipulateDimensionNames(l.Name)), this.diagram.HorizontalLayers.map(l => this.manipulateDimensionNames(l.Name)))

        // enums
        this.diagram.Enums.map(e => new DefaultEnumExportInformation(this.foo(e), e.Values)).forEach(exportInfo => {
            exportBuilder.AddClass(this.manipulateDimensionNames(exportInfo.VerticalDimension), this.manipulateDimensionNames(exportInfo.HorizontalDimension), this.fileCreator.CreateEnumFile(exportInfo))
        });

        // views
        this.diagram.Views.map(v => new DefaultViewExportInformtation(this.foo(v), v.Dependencies, (t => this.getImportInfortmation(t))
        )).forEach(exportInfo => {
            exportBuilder.AddClass(this.manipulateDimensionNames(exportInfo.VerticalDimension), this.manipulateDimensionNames(exportInfo.HorizontalDimension), this.fileCreator.CreateViewFile(exportInfo))
        });

        // interfaces
        this.diagram.Numles.map(n => new DefaultInterfaceExportInformation(n, this.getSection(n.Position), (element: string) => this.getImportInfortmation(new DefaultType(element)))).forEach(exportInfo => {
            exportBuilder.AddInterface(this.manipulateDimensionNames(exportInfo.VerticalDimension), this.fileCreator.CreateInterfaceFile(exportInfo))
        });

        // classes
        const classExportInformationBuilder: ClassExportInformationBuilder[] = this.diagram.Numles.map(n => new ClassExportInformationBuilder(n, this.getSection(n.Position),
            (e) => this.getImportInfortmation(new DefaultType(e)), (t: Type) => this.diagram.Numles.find(nu => new DefaultType(nu.Name).TechnicalType === t.TechnicalType)));

        classExportInformationBuilder.flatMap(cEI => cEI.GetClassExports()).forEach((exportInfo) => {
            exportBuilder.AddClass(this.manipulateDimensionNames(exportInfo.VerticalDimension), this.manipulateDimensionNames(exportInfo.HorizontalDimension), this.fileCreator.CreateClassFile(exportInfo))
        });

        return exportBuilder.GetRoot();
    }
}