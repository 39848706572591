import React from "react";
import { Arrow } from "../../Arrow/interfaces/Arrow";
import ArrowVisual from "../../Arrow/view/Arrow";

interface IProps {
  Arrows: Arrow[];
}

export default (props: IProps): JSX.Element => {
  function visualizeArrows(): JSX.Element[] {
    const visualizedArrows: JSX.Element[] = [];
    props.Arrows.forEach((arrow: Arrow, i: number) => {
      visualizedArrows.push(<ArrowVisual key={"Arrow" + i} {...arrow} />);
    });
    return visualizedArrows;
  }
  return <div>{visualizeArrows()}</div>;
};

