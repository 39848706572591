import React from "react";
import "./Diagram.css";
import NumlView from "../../../Numl/view/Numl/Numl";
import { ArrangeableRelationalNuml } from "../../../Numl/interfaces/ArrangeableRelationalNuml";
import { ViewableDiagram } from "../../interfaces/ViewableDiagram";
import Layers from "../../../Layer/view/Layer/Layers";
import EnumView from "../../../Enum/view/Enum";
import { Diagram } from "../../interfaces/Diagram";
import { Observer } from "../../../Environment/interface/Observer";
import { DefaultObserveableDiagram } from "../../model/impl/DefaultObserveableDiagram";
import { DefaultViewableDiagram } from "../../viewmodel/impl/DefaultViewableDiagram";
import { Datapoint } from "../../interfaces/Datapoints";
import { Action } from "../../../Controls/interfaces/Action";
import Arrows from "../../../Arrow/view/Arrows";
import PageBuilder from "../../../Page/view/PageBuilder";
import { DiagramItem } from "../../enums/DiagramItem";
import ChoiceButtonList from "../../../Controls/view/ChoiceButton/ChoiceButtonList";
import AddItemm from "../AddItem/AddItem";
import { Environment } from "../../../Environment/model/Environment";
import { ObserveableArrangeableRelationalNuml } from "../../../Numl/interfaces/ObserveableArrangeableRelationalNuml";
import { DefaultObserveableArrangeableRelationalNuml } from "../../../Numl/viewmodel/impl/DefaultObserveableArrangeableRelationalNuml";
import { ObserveableDiagram } from "../../interfaces/ObserveableDiagram";
import { ObserveableEnum } from "../../../Enum/interfaces/ObserveableEnum";
import { DefaultObserveableEnum } from "../../../Enum/model/impl/DefaultObserveableEnum";
import { Enum } from "../../../Enum/interfaces/Enum";
import { ViewDefinition } from "../../../ViewDefinition/interfaces/ViewDefinition";
import { ObserveableViewDefinition } from "../../../ViewDefinition/interfaces/ObserveableViewDefinition";
import { DefaultObserveableViewDefinition } from "../../../ViewDefinition/model/DefaultObserveableEnum";
import ViewDefinitionView from "../../../ViewDefinition/view/ViewDefinition";

interface IProps {
  diagram: Diagram;
  save: (diagram: Diagram) => void;
}

interface IState {
  diagram: ViewableDiagram;
}

export default class DiagramView extends React.Component<IProps, IState> {
  private observableDiagram: ObserveableDiagram;
  constructor(props: IProps) {
    super(props);
    this.observableDiagram = new DefaultObserveableDiagram(props.diagram);
    this.state = {
      diagram: new DefaultViewableDiagram(this.observableDiagram),
    };
    let observer: Observer<Diagram> = {
      Complete: () => {},
      Error: () => {},
      Next: (diagram: Diagram) => {
        this.props.save(diagram);
        this.setState({ diagram: new DefaultViewableDiagram(diagram) });
        this.forceUpdate();
      },
    };
    this.observableDiagram.Attach(observer);
  }

  private visualizeNumles(clazzes: ArrangeableRelationalNuml[]): JSX.Element[] {
    const visualizedNumles: JSX.Element[] = [];
    clazzes.forEach((numl: ArrangeableRelationalNuml, i: number) => {
      let observeable: ObserveableArrangeableRelationalNuml;
      if ((numl as ObserveableArrangeableRelationalNuml).IsObserveable) {
        observeable = numl as ObserveableArrangeableRelationalNuml;
      } else {
        observeable = new DefaultObserveableArrangeableRelationalNuml(numl);
      }
      visualizedNumles.push(
        <div key={i}>
          <NumlView
            key={numl.Id}
            numl={observeable}
            Delete={() => {
              this.state.diagram.DeleteNuml(numl);
            }}
          />
        </div>
      );
    });
    return visualizedNumles;
  }

  private visualizeEnums(enums: Enum[]): JSX.Element[] {
    return enums.map((e) => {
      let observeable: ObserveableEnum;
      if ((e as ObserveableEnum).IsObserveable) {
        observeable = e as ObserveableEnum;
      } else {
        observeable = new DefaultObserveableEnum(e);
      }
      return (
        <EnumView
          key={e.Id}
          enum={observeable}
          Delete={() => this.props.diagram.RemoveEnum(e)}
        />
      );
    });
  }

  private visualizeViews(view: ViewDefinition[]): JSX.Element[] {
    return view.map((v) => {
      let observeable: ObserveableViewDefinition;
      if ((v as ObserveableViewDefinition).IsObserveable) {
        observeable = v as ObserveableViewDefinition;
      } else {
        observeable = new DefaultObserveableViewDefinition(v);
      }
      return (
        <ViewDefinitionView
          key={v.Id}
          view={observeable}
          Delete={() => this.props.diagram.RemoveView(v)}
        />
      );
      // return <EnumView enum={observeable} Delete={() => this.props.diagram.RemoveEnum(e)} />
    });
  }

  private handleContextMenu(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    event.preventDefault();
    event.stopPropagation();
    let mousePosition: Datapoint = {
      x: window.scrollX + event.clientX,
      y: window.scrollY + event.clientY,
    };
    let actions: Action[] = [
      {
        Label: "NUML",
        Action: () => this.openAddItemWindow(DiagramItem.Numl, mousePosition),
      },
      {
        Label: "Enum",
        Action: () => this.openAddItemWindow(DiagramItem.Enum, mousePosition),
      },
      {
        Label: "View",
        Action: () => this.openAddItemWindow(DiagramItem.View, mousePosition),
      },
    ];
    let closeFoo: () => void = PageBuilder.AddWindow(
      <ChoiceButtonList Actions={actions} />,
      "Add",
      mousePosition,
      75
    );
    let extendCloseFoo = (foo: (...params: any) => void) =>
      Environment.CombineFoos(closeFoo, foo);
    actions.forEach((a) => (a.Action = extendCloseFoo(a.Action)));
    PageBuilder.AddCloseListener(closeFoo);
  }

  private openAddItemWindow(itemType: DiagramItem, position: Datapoint) {
    const p: {
      foo(s: string): void;
      title: string;
    } = {
      foo: () => {},
      title: "",
    };
    switch (itemType) {
      case DiagramItem.Enum:
        p.foo = (s) => this.state.diagram.AddEnumByString(s, position);
        p.title = "Add Enum";
        break;
      case DiagramItem.Numl:
        p.foo = (s) => this.state.diagram.AddNumlByString(s, position);
        p.title = "Add Numl";
        break;
      case DiagramItem.View:
        p.foo = (s) => this.state.diagram.AddViewByString(s, position);
        p.title = "Add View";
    }
    let closeFoo: () => void = PageBuilder.OpenPenetratingWindow(
      <AddItemm AddItem={(s: string) => p.foo(s)} />,
      p.title,
      300
    );
    let extendCloseFoo = (foo: (...params: any) => void) =>
      Environment.CombineFoos(closeFoo, foo);
    p.foo = extendCloseFoo(p.foo);
  }

  render(): JSX.Element {
    return (
      <div className="body" onContextMenu={(e) => this.handleContextMenu(e)}>
        {this.visualizeNumles(this.state.diagram.GetNumles())}
        <Arrows Arrows={this.state.diagram.GetArrows()} />
        {this.visualizeEnums(this.state.diagram.GetEnums())}
        {this.visualizeViews(this.state.diagram.GetViews())}
        <Layers Layers={this.state.diagram.Layers} />
      </div>
    );
  }
}
