import { VerticalLayer } from "../../interfaces/VerticalLayer";




export class DefaultVerticalLayer implements VerticalLayer {

    private static NextId = 1;

    public get Name() { return this.name }
    public get Width() { return this.width }
    public readonly Id: string;

    constructor(private name: string, private width: number = 0) {
        this.Id = "vertical-layer-" + DefaultVerticalLayer.NextId++;
    }

    public Rename(name: string) {
        this.name = name;
    }

    public ResizeWidth(newWidth: number) {
        this.width = newWidth;
    }
}