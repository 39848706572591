import React from "react";
import { AddCircle } from "../../../VisualElements/view/Elements";
import "./Layer.css";
import Layer from "./VerticalLayer";
import { VerticalLayer } from "../../interfaces/VerticalLayer";
import AddItemm from "../../../Diagram/view/AddItem/AddItem";
import { Environment } from "../../../Environment/model/Environment";
import PageBuilder from "../../../Page/view/PageBuilder";
import Button from "../../../Button/view/Button";

interface IProps {
  layers: VerticalLayer[];
  height: number;
  AddLayer(name: string): void;
  DeleteLayer(layer: VerticalLayer): void;
  SwapToPrevLayer(index: number): void;
  SwapToNextLayer(index: number): void;
}

export default (props: IProps) => {
  function AddVerticalLayer() {
    const p: {
      foo(s: string): void;
      title: string;
    } = {
      foo: (s: string) => {
        props.AddLayer(s);
      },
      title: "Add Vertical Layer",
    };
    let closeFoo: () => void = PageBuilder.OpenPenetratingWindow(
      <AddItemm AddItem={(s: string) => p.foo(s)} />,
      p.title,
      300
    );
    let extendCloseFoo = (foo: (...params: any) => void) =>
      Environment.CombineFoos(closeFoo, foo);
    p.foo = extendCloseFoo(p.foo);
  }
  return (
    <div className="Layers VerticalLayers" style={{ height: props.height }}>
      {props.layers.map((l, index) => (
        <Layer
          swapToNext={() => props.SwapToNextLayer(index)}
          swapToPrev={() => props.SwapToPrevLayer(index)}
          layer={l}
          delete={() => props.DeleteLayer(l)}
        />
      ))}
      <div className="LayersButton" style={{ float: "left", height: "100%" }}>
        <Button
          Action={{
            Label: <AddCircle color="white" />,
            Action: () => AddVerticalLayer(),
          }}
        />
      </div>
    </div>
  );
};

