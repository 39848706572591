
import { ImportInformation } from "../../../Numl/interfaces/ImportInformation";
import { Type } from "../../../Type/interfaces/Type";
import { ViewExportInformation } from "../../interfaces/ViewExportInformation";



export class DefaultViewExportInformtation implements ViewExportInformation{

    
    public VerticalDimension: string;
    public HorizontalDimension: string;
    public Name: string;
    public get Imports(): ImportInformation[]{
        return this.Depdencies.map(this.getImportInformation).filter(t=>t) as ImportInformation[];
    }


    constructor(importInformation:ImportInformation, public Depdencies:Type[] ,  private getImportInformation:((element:Type)=> ImportInformation | undefined)) {
        
        this.VerticalDimension = importInformation.VerticalDimension;
        this.HorizontalDimension = importInformation.HorizontalDimension;
        this.Name = importInformation.Name;
    }
}