import React from "react";
import { MdCancel, MdEdit } from "react-icons/md";
import "../../@types/material-icons-react/index.d.ts";
// @ts-ignore
import MaterialIcon from "material-icons-react";
import { Environment } from "../../Environment/model/Environment";
import "./Element.css";

// tslint:disable-next-line: interface-name
interface Props {
  Action?: () => void;
  color?: string;
}

export const Pencil = (props: Props): JSX.Element => (
  <div
    className="Element Pencil"
    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
      if (props.Action) {
        Environment.preventDefault(event);
        props.Action();
      }
    }}
  >
    <MdEdit color="Orange" className="icons" />
  </div>
);

export const Cancel = (props: Props): JSX.Element => (
  <div
    className="Element Cancel"
    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
      if (props.Action) {
        Environment.preventDefault(event);
        props.Action();
      }
    }}
  >
    <MdCancel color="red" className="icons" />
  </div>
);

export const AddCircle = (props: Props): JSX.Element =>
  body({
    icon: <MaterialIcon icon="add" color={props.color} />,
    additionalClass: "AddCircle",
    action: props.Action,
  });

export const BigBack = (props: Props): JSX.Element =>
  body({
    icon: <MaterialIcon icon="arrow_left" />,
    additionalClass: "Back",
    action: props.Action,
  });

function body(props: {
  icon: JSX.Element;
  additionalClass: string;
  action?: () => void;
}): JSX.Element {
  return (
    <div
      className={"Element " + props.additionalClass}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        if (props.action) {
          Environment.preventDefault(event);
          props.action();
        }
      }}
    >
      {props.icon}
    </div>
  );
}

