import React from "react";
import Button from "../../Button/view/Button";
import ChoiceButton from "../../Controls/view/ChoiceButton/ChoiceButton";
// @ts-ignore
import MaterialIcon from "material-icons-react";

import { BigBack } from "../../VisualElements/view/Elements";
import { Diagram } from "../interfaces/Diagram";

interface IProps {
  diagram: Diagram;
  ExportActions: { Label: string; Action: () => void }[];
  backAktion(): void;
}

interface IState {}

export default class DiagramHeader extends React.Component<IProps, IState> {
  private element: HTMLElement | null = null;
  private getElement(): HTMLElement {
    if (!this.element) {
      this.element = document.getElementById("DiagramHeaderId");
    }
    return this.element as HTMLElement;
  }
  componentDidMount() {
    document.addEventListener("scroll", () => {
      const element = this.getElement();
      element.style.top = "-" + window.scrollY + "px";
    });
    /*
		$(window).scroll(function(event) {
   $("#blue-box").css("margin-left", 400-$(document).scrollLeft());
}); */
  }

  render() {
    return (
      <div
        id="DiagramHeaderId"
        style={{
          position: "fixed",
          height: "85px",
          top: "0px",
          left: "0px",
          right: "0px",
        }}
        className="DiagramView Menu header"
      >
        <div className="left">
          <Button
            Action={{
              Label: (
                <div className="queer">
                  <BigBack Action={() => this.props.backAktion()} />{" "}
                  <h2>Back</h2>
                </div>
              ),
              Action: () => this.props.backAktion(),
            }}
          />
        </div>
        <div>
          <h1>{this.props.diagram.Name}</h1>
        </div>
        <div className="right">
          <ChoiceButton
            ListOfActions={this.props.ExportActions}
            Label={
              <div className="queer">
                <MaterialIcon icon="download" />
                <h2>"Export"</h2>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}
