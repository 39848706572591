import React from "react";
import Video from "../../Video/view/Video";

// // @ts-ignore
// import vid from './how-to-youtube.mp4';

interface IProps {
  autorun?: boolean;
}

export default (props: IProps) => {
  // let viewableNuml: ArrangeableRelationalNuml = new DefaultArrangeableRelationalNuml("Interface<Generic>:Extending")
  // let prop: Property = {
  //     Name: new DefaultType("Property Name"),
  //     ReturnType: new DefaultType("ReturnType<Generic>"),
  //     Type: MemberType.Property
  // }
  // viewableNuml.AddMember(prop)

  // let ctor: Constructor = {
  //     Name: new DefaultType("Constructor/Class Name"),
  //     ParamTypes: [new DefaultType("Param1"), new DefaultType("Param2")],
  //     Type: MemberType.Constructor
  // }
  // viewableNuml.AddMember(ctor)

  // let meth: Method = {
  //     Name: new DefaultType("Method Name"),
  //     ReturnType: new DefaultType("Returning Type <Could, Be, Generic>"),
  //     ParamTypes: [new DefaultType("Param1"), new DefaultType("Param2")],
  //     Type: MemberType.Property
  // }
  // viewableNuml.AddMember(meth)

  // 1920 1080

  let width = 800;
  let height = (1080 / 1920) * width;
  let pathVideo: string = "/how-to.mp4";

  return (
    <div className="indented section">
      <Video
        width={width}
        heigth={height}
        videoSrc={pathVideo}
        autorun={props.autorun}
      />
    </div>
  );
};
