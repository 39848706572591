import React from "react";
import "./MethodVisual.css";

import Params from "../../elements/Params/Params";
import ReturnType from "../../elements/ReturnType/ReturnType";
import { IsDeletable } from "../IsDeletable";
import { IsChangeable } from "../../interfaces/IsChangeable";
import SingleLineEditor from "../../../Controls/view/SingleLineEditor/SingleLineEditor";
import { Method } from "../../interfaces/Method";

interface IProps extends IsDeletable, IsChangeable {
  method: Method;
}

export default (props: IProps): JSX.Element => (
  <SingleLineEditor
    Delete={props.Delete}
    OnDidChange={props.Change}
    Title={
      <div className="Method">
        <div>{props.method.Name}</div>
        <Params params={props.method.ParamTypes} />
        <ReturnType type={props.method.ReturnType} />
      </div>
    }
    preSet={
      props.method.Name +
      "(" +
      props.method.ParamTypes.map((t) => t.FullType).join(", ") +
      "): " +
      props.method.ReturnType.FullType
    }
  />
);
