import React from "react";
import "./PropertyVisual.css";


import { IsDeletable } from "../IsDeletable";
import { IsChangeable } from "../../interfaces/IsChangeable";
import SingleLineEditor from "../../../Controls/view/SingleLineEditor/SingleLineEditor";
import ReturnType from "../../elements/ReturnType/ReturnType"
import { Property } from "../../interfaces/Property";


interface IProps extends IsDeletable, IsChangeable {
    property: Property;
}


export default (props: IProps): JSX.Element =>
    <SingleLineEditor Delete={props.Delete}
        OnDidChange={props.Change}
        Title={<div className="Property">
            <div>{props.property.Name}</div>
            <ReturnType type={props.property.ReturnType} />
        </div>}
        preSet={props.property.Name + ": " + props.property.ReturnType.FullType}

    />

