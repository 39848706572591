import React from "react";
import "./Arrow.css";

import { RelationType } from "../../Diagram/interfaces/RelationType";
import { Arrow } from "../interfaces/Arrow";

interface IProps extends Arrow {}

interface IBorderstyle {
  borderTopStyle: string;
  borderRightStyle: string;
  borderBottomStyle: string;
  borderLeftStyle: string;
}

export default (props: IProps): JSX.Element => {
  let left: number =
    props.Source.x < props.Target.x ? props.Source.x : props.Target.x;
  let top: number =
    props.Source.y < props.Target.y ? props.Source.y : props.Target.y;

  let width: number = Math.abs(props.Source.x - props.Target.x);
  let height: number = Math.abs(props.Source.y - props.Target.y) / 2;

  let borderStyleTopDiv: IBorderstyle;
  let borderStyleBottomDiv: IBorderstyle;

  let borderStyle: string;
  switch (props.RelationType) {
    case RelationType.Dependency:
      borderStyle = "groove";
      break;
    case RelationType.Inheritance:
      borderStyle = "solid";
      break;
    case RelationType.Instantiate:
      borderStyle = "dotted";
      break;
  }

  let sourcePointIsInLeftTop: boolean =
    props.Source.x === left && props.Source.y === top;
  let targetPointIsInLeftTop: boolean =
    props.Target.x === left && props.Target.y === top;

  if (sourcePointIsInLeftTop || targetPointIsInLeftTop) {
    borderStyleTopDiv = {
      borderTopStyle: "none",
      borderRightStyle: "none",
      borderBottomStyle: borderStyle,
      borderLeftStyle: borderStyle,
    }; // "RightTop";
    borderStyleBottomDiv = {
      borderTopStyle: borderStyle,
      borderRightStyle: borderStyle,
      borderBottomStyle: "none",
      borderLeftStyle: "none",
    }; // "LeftBottom";
  } else {
    borderStyleTopDiv = {
      borderTopStyle: "none",
      borderRightStyle: borderStyle,
      borderBottomStyle: borderStyle,
      borderLeftStyle: "none",
    }; // "RightBottom";
    borderStyleBottomDiv = {
      borderTopStyle: borderStyle,
      borderRightStyle: "none",
      borderBottomStyle: "none",
      borderLeftStyle: borderStyle,
    }; // "LeftTop";
  }

  let bothDiv: any = {
    width,
    height,
    left,
    position: "absolute",
  };
  let topDiv: any = { top, ...borderStyleTopDiv, ...bothDiv };
  let bottomDiv: any = {
    top: top + height,
    ...borderStyleBottomDiv,
    ...bothDiv,
  };

  return (
    <div>
      <div className="Arrow" style={topDiv} />

      <div className="Arrow" style={bottomDiv} />
    </div>
  );
};

