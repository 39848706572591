export class ArrayHelper {
  public static SwapToPrev(arr: any[], index: number) {
    this.OutOfRangeCheck(arr, index);
    if (index === 0) {
      return;
    }
    let cached: any = arr[index];
    arr[index] = arr[index - 1];
    arr[index - 1] = cached;
  }

  public static SwapToNext(arr: any[], index: number) {
    this.OutOfRangeCheck(arr, index);
    if (index === arr.length - 1) {
      return;
    }
    let cached: any = arr[index];
    arr[index] = arr[index + 1];
    arr[index + 1] = cached;
  }

  public static UniqueFilter(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  public static GroupBy<T>(array: T[], property: string): T[][] {
    function selectProperty(obj: any, prop: string) {
      prop.split(".").forEach((s) => (obj = obj[s]));
      return obj;
    }
    const arr: any[] = array;
    const obj: { [key: string]: any[] } = {};
    arr.forEach((a) => {
      if (obj[selectProperty(a, property)]) {
        obj[selectProperty(a, property)].push(a);
      } else {
        obj[selectProperty(a, property)] = [a];
      }
    });
    return Object.values(obj);
  }

  public static Join(elements: any[], element: any): any {
    if (elements.length < 1) {
      return [];
    }
    const result: any[] = [elements[0]];
    for (let i: number = 1; i < elements.length; i++) {
      result.push(element);
      result.push(elements[i]);
    }
    return result;
  }

  private static OutOfRangeCheck(arr: any[], index: number) {
    if (index < 0 || index >= arr.length) {
      throw new RangeError("index is out of Range of the array");
    }
  }
}

