import JSZip from "jszip";
import React from "react";
import ReactDOM from "react-dom";
import { Action } from "../../Controls/interfaces/Action";
import { Datapoint } from "../../Diagram/interfaces/Datapoints";
import { Diagram } from "../../Diagram/interfaces/Diagram";
import DiagramView from "../../Diagram/view/Diagram/Diagram";
import DiagramHeader from "../../Diagram/view/DiagramHeader";
import { Exporter } from "../../Exporter/interfaces/Exporter";
import { CSharpFileCreator } from "../../Exporter2/model/impl/CSharpFileCreator";
import { Directory } from "../../FileStructure/interfaces/Directory";
import { JsonParser } from "../../JsonParser/interfaces/JsonParser";
import { DefaultJsonParser } from "../../JsonParser/model/impl/DefaultJsonParser";
import Menu from "../../Menu/view/Menu";
import OWindow from "../../Window/view/Window";
import { saveAs } from "file-saver";
// @ts-ignore
import "./PageBuilder.css";
import Error from "../../Error/view/Error";
import { Exporter2 } from "../../Exporter2/interfaces/Exporter2";
import { DefaultExporter2 } from "../../Exporter2/model/impl/DefaultExporter2";
import { TypeScriptFileCreator } from "../../Exporter2/model/impl/TypeScripteFileCreator";
import { ReactFileCreator } from "../../Exporter2/model/impl/ReactFileCreator";
import { JavaFileCreator } from "../../Exporter2/model/impl/JavaFileCreator";

export default class PageBuilder {
  constructor(private menuPoints: Action[]) {
    let div: HTMLDivElement = document.getElementById("root") as HTMLDivElement;
    div.onclick = () => PageBuilder.InvokeClosing();
  }

  private static closingFoos: (() => void)[] = [];
  static AddCloseListener(closingFoo: () => void) {
    this.closingFoos.push(closingFoo);
  }
  private static InvokeClosing() {
    while (this.closingFoos.length > 0) {
      (this.closingFoos.pop() as () => void)();
    }
  }

  public static OpenPenetratingWindow(
    element: JSX.Element,
    title: string,
    width: number
  ) {
    let body: HTMLBodyElement = document.getElementsByTagName("body")[0];
    let aDiv: HTMLDivElement = document.createElement("div");
    body.appendChild(aDiv);

    PageBuilder.AddCloseListener(() => aDiv.remove());
    ReactDOM.render(
      <div
        style={{
          backgroundColor: "#FFFFFF60",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1300,
        }}
      >
        <div
          style={{
            backgroundColor: "#FFF",
            position: "fixed",
            top: "200px",
            left: "50%",
            marginLeft: -(width / 2),
            zIndex: 1400,
          }}
        >
          <OWindow
            title={title}
            width={width}
            position="static"
            element={element}
            close={() => aDiv.remove()}
          />
        </div>
      </div>,
      aDiv
    );

    return () => aDiv.remove();
  }

  public static ShowError(errorMessag: string) {
    this.AddWindow(
      <Error errorMessage={errorMessag} />,
      "Error",
      { x: 200, y: 800 },
      800,
      "Error"
    );
  }

  public static AddWindow(
    element: JSX.Element,
    title: string,
    postion?: Datapoint,
    width?: number,
    className?: string
  ): () => void {
    let body: HTMLBodyElement = document.getElementsByTagName("body")[0];
    let aDiv: HTMLDivElement = document.createElement("div");
    body.appendChild(aDiv);

    PageBuilder.AddCloseListener(() => aDiv.remove());
    ReactDOM.render(
      <OWindow
        title={title}
        width={width}
        element={element}
        close={() => aDiv.remove()}
        positioning={postion}
        className={className}
      />,
      aDiv
    );

    return () => aDiv.remove();
  }

  public DiagramPage(
    diagram: Diagram,
    save: (diagram: Diagram) => void,
    backAktion: () => void
  ) {
    const cSharpExporter: Exporter = new DefaultExporter2(
      diagram,
      new CSharpFileCreator(diagram.Name),
      "Interfaces"
    );
    const typeScriptExporter: Exporter2 = new DefaultExporter2(
      diagram,
      new TypeScriptFileCreator(new ReactFileCreator()),
      "interfaces"
    );
    const javaExporter: Exporter2 = new DefaultExporter2(
      diagram,
      new JavaFileCreator(diagram.Name.toLowerCase()),
      "interfaces",
      (s) => s.toLowerCase()
    );

    function ExportJson(): void {
      const jp: JsonParser = new DefaultJsonParser();
      console.log("Export Json pressed");
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(jp.ParseToJsonString(diagram))
      );
      element.setAttribute("download", diagram.Name + ".json");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }

    function ExportToCsharp(): void {
      const cSharpExptort: Directory = cSharpExporter.GetExport() as Directory;
      const zip: JSZip = cSharpExptort.Zip();

      zip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, cSharpExptort.Name + ".zip");
      });
    }

    function ExportToJava(): void {
      const javaExport: Directory = javaExporter.GetExport() as Directory;
      const zip: JSZip = javaExport.Zip();

      zip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, javaExport.Name + ".zip");
      });
    }

    function ExportToTypeScript(): void {
      const typeScriptExport: Directory = typeScriptExporter.GetExport() as Directory;
      const zip: JSZip = typeScriptExport.Zip();

      zip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, typeScriptExport.Name + ".zip");
      });
    }
    const ExportActions: { Label: string; Action: () => void }[] = [
      {
        Action: () => ExportJson(),
        Label: "JSON",
      },
      {
        Action: () => ExportToJava(),
        Label: "Java",
      },
      {
        Action: () => ExportToCsharp(),
        Label: "CSharp",
      },
      {
        Action: () => ExportToTypeScript(),
        Label: "TypeScript",
      },
    ];

    return this.Page(
      <div className="Diagram">
        <DiagramHeader
          diagram={diagram}
          ExportActions={ExportActions}
          backAktion={backAktion}
        />
        <DiagramView diagram={diagram} save={(d: Diagram) => save(d)} />
      </div>
    );
  }

  public Page(
    mainContent: JSX.Element /*,
    title: string | JSX.Element,
    topRight?: JSX.Element,
    leftTopContent?: JSX.Element*/
  ) {
    return (
      <div className="MasterPage">
        <div className="mainContent">{mainContent}</div>
      </div>
    );
  }

  public PageWithMenu(
    mainContent: JSX.Element,
    title: string | JSX.Element,
    extraContent?: JSX.Element
  ) {
    return this.Page(
      <div className="Page WithMenu">
        <div className="menu">
          <Menu menuPoints={this.menuPoints} />
        </div>
        <div className="mainContent">{mainContent}</div>
      </div>
    );
  }
}
