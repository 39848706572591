import React from "react";
import { Action } from "../../Controls/interfaces/Action";
import Button from "../../Button/view/Button";
import "../../Menu/view/Menu.css";


interface IProps {
    menuPoints: Action[]
}


export default (props: IProps) =>
    <div className="Menu">
        {props.menuPoints.map((p, i) => <Button key={i} Action={{
            Label: p.Label,
            Action: p.Action
        }} backgroundColor="green" color="white" />)}
    </div>
