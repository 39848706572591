import { Datapoint } from "../../../Diagram/interfaces/Datapoints";
import { DefaultObserveable } from "../../../Environment/model/DefaultObserveable";
import { Enum } from "../../interfaces/Enum";
import { ObserveableEnum } from "../../interfaces/ObserveableEnum";




export class DefaultObserveableEnum extends DefaultObserveable<Enum> implements ObserveableEnum {
    public get Values(): string[] { return this.aEnum.Values }
    public get Position(): Datapoint { return this.aEnum.Position };
    public get Id(): string { return this.aEnum.Id }
    public get Name(): string { return this.aEnum.Name }

    constructor(private aEnum: Enum) {
        super(aEnum);
    }
    SwapValueToPrev(index: number): void {
        this.aEnum.SwapValueToPrev(index);
        this.raiseNext(this);
    }
    SwapValueToNext(index: number): void {
        this.aEnum.SwapValueToNext(index);
        this.raiseNext(this);
    }

    Add(s: string): void {
        this.aEnum.Add(s);
        this.raiseNext(this);
    }
    Change(prev: string, newStr: string): void {
        this.aEnum.Change(prev, newStr);
        this.raiseNext(this);
    }


      /**
     * Does not raise event Next!
     * @param x new x
     * @param y new y
     */    
    MoveTo(x: number, y: number) {
        this.aEnum.MoveTo(x, y);
    }

    public RaiseNext() {
        this.raiseNext(this);
    }

    Remove(s: string): void {
        this.aEnum.Remove(s);
        this.raiseNext(this);
    }

    Rename(name: string): void {
        this.aEnum.Rename(name);
        this.raiseNext(this);
    }
}