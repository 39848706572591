import React from "react";

import "./Header.css";

interface IProps {
    left?: JSX.Element | string,
    center?: JSX.Element | string,
    right?: JSX.Element | string
}

export default (props: IProps): JSX.Element =>
    <div className="Header">
        <div className="left">
            {props.left}
        </div>
        <div className="center">
            {props.center}
        </div>
        <div className="right">
            {props.right}
        </div>
    </div>