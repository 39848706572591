import { Datapoint } from "../../Diagram/interfaces/Datapoints";
import { Type } from "../../Type/interfaces/Type";
import { ViewableDependency } from "../interfaces/ViewableDependency";
import { ViewableViewDefinition } from "../interfaces/ViewableViewDefinition";
import { ViewDefinition } from "../interfaces/ViewDefinition";
import { DefaultViewableDependency } from "./DefaultViewableDependency";



export class DefaultViewableViewDefinition implements ViewableViewDefinition {
    public get PositionOfCenter(): Datapoint {
        return {
            x: this.viewDefinition.Position.x + (this.descriptiveData.width / 2),
            y: this.viewDefinition.Position.y + 20
        }
    }

    constructor(private viewDefinition: ViewDefinition) {
        this.descriptiveData = { Position: viewDefinition.Position, width: 100 };
    }

    SwapValueToPrev(index: number): void {
        this.viewDefinition.SwapValueToPrev(index);
    }
    SwapValueToNext(index: number): void {
        this.viewDefinition.SwapValueToNext(index);
    }

    public get Position(): Datapoint { return this.viewDefinition.Position; }
    public get Dependencies(): ViewableDependency[] {
        return this.viewDefinition.Dependencies
            .map((s, i) => new DefaultViewableDependency(s.FullType, { x: this.PositionOfCenter.x, y: this.PositionOfCenter.y + 29 + 23.5 * i }));
    }
    public get Id(): string { return this.viewDefinition.Id; }
    public get Name(): string { return this.viewDefinition.Name; }


    Add(s: string): void {
        this.viewDefinition.Add(s);
    }
    ChangeDependency(prev: Type, newStr: string): void {
        this.viewDefinition.ChangeDependency(prev, newStr);
    }
    Remove(s: Type): void {
        this.viewDefinition.Remove(s);
    }
    Rename(name: string): void {
        this.viewDefinition.Rename(name);
    }

    private descriptiveData: { Position: Datapoint, width: number };

    private element: HTMLElement | null = null;
    private getElement(): HTMLElement {
        if (this.element) {
            this.descriptiveData.width = this.element.offsetWidth;
            return this.element;
        }
        this.element = document.getElementById(this.Id);
        if (this.element) {
            this.descriptiveData.width = this.element.offsetWidth;
            return this.element;
        } else {
            throw new Error("Element with Id " + this.Id + " not found");
        }
    }

    MoveTo(x: number, y: number): void {
        this.element = this.getElement();
        this.element.style.left = x + "px";
        this.element.style.top = y + "px";

        this.viewDefinition.MoveTo(x, y);
    }
}