import { HorizontalLayer } from "../../interfaces/HorizontalLayer";




export class ResizeListenerHoriszontalLayer implements HorizontalLayer {

    constructor(private layer: HorizontalLayer, private beforeResize: (diff: number) => void) { }

    Rename(name: string): void {
        this.layer.Rename(name);
    }

    public get Height(): number { return this.layer.Height; }
    public get Id(): string { return this.layer.Id; }
    public get Name(): string { return this.layer.Name; }

    ResizeHeight(newHeight: number): void {
        this.beforeResize(this.Height - newHeight);
        this.layer.ResizeHeight(newHeight);
    }
}