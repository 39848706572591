


export class Environment {
    public static CombineFoos<T>(foo1: () => void, foo2: (...prarams: any) => T): (...params: any) => T {
        return (...params: any) => {
            foo1();
            return foo2(...params);
        }
    }

    public static preventDefault(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
    }
}