import React from "react";
import { MultiFileCreator } from "../../FileViewer/interfaces/MultiFileCreator";
import { DefaultMultiFileCreator } from "../../FileViewer/model/DefaultMultiFileCreator";
import { Numl } from "../../Numl/interfaces/Numl";
import { IFile } from "../../FileStructure/interfaces/IFile";
import FileView from "../../FileStructure/view/FileView";
import { CSharpFileCreator } from "../../Exporter2/model/impl/CSharpFileCreator";
import { JavaFileCreator } from "../../Exporter2/model/impl/JavaFileCreator";
import { TypeScriptFileCreator } from "../../Exporter2/model/impl/TypeScripteFileCreator";
import { ReactFileCreator } from "../../Exporter2/model/impl/ReactFileCreator";
import TabView from "../../TabView/view/TabView";
import { TabElement } from "../../TabView/interfaces/TabElement";
import { Enum } from "../../Enum/interfaces/Enum";
import { ViewDefinition } from "../../ViewDefinition/interfaces/ViewDefinition";

interface IProps {
  diagramElements: (Numl | Enum | ViewDefinition)[];
}

export default (props: IProps) => {
  const fc: MultiFileCreator = new DefaultMultiFileCreator([
    new CSharpFileCreator("Numl"),
    new JavaFileCreator("numl"),
    new TypeScriptFileCreator(new ReactFileCreator()),
  ]);

  function visualizeFiles(fs: IFile[]): JSX.Element {
    return (
      <div>
        {fs.map((f) => (
          <FileView file={f} />
        ))}
      </div>
    );
  }

  let csharpFiles: IFile[] = [];
  let javaFiles: IFile[] = [];
  let typeScriptFiles: IFile[] = [];

  props.diagramElements.forEach((e) => {
    const files: IFile[][] = fc.CreateFiles(e);
    csharpFiles = csharpFiles.concat(files[0]);
    javaFiles = javaFiles.concat(files[1]);
    typeScriptFiles = typeScriptFiles.concat(files[2]);
  });
  const tabElements: TabElement[] = [
    {
      title: "C#",
      content: visualizeFiles(csharpFiles),
    },
    {
      title: "Java",
      content: visualizeFiles(javaFiles),
    },
    {
      title: "TypeScript",
      content: visualizeFiles(typeScriptFiles),
    },
  ];

  return <TabView tabs={tabElements} />;
};
