import React from "react";
import "./Numl.css";
import "../../../DragableItem/view/DragableItem.css";
import SingleLineEditor from "../../../Controls/view/SingleLineEditor/SingleLineEditor";
import PropertyVisual from "../Property/PropertyVisual";
import EventVisual from "../../../EventMember/view/EventMember";
import MethodVisual from "../Method/MethodVisual";
import ConstructorVisual from "../Constructor/ConstructorVisual";
import Expands from "../../elements/Expands/Expands";
import LazyInput from "../../../Controls/view/LazyInput/LazyInput";
import { Property } from "../../interfaces/Property";
import { Method } from "../../interfaces/Method";
import { Constructor } from "../../interfaces/Constructor";
import { Datapoint } from "../../../Diagram/interfaces/Datapoints";
import { DragHandler } from "../../../Environment/interface/DragHandler";
import { DefaultDragHandler } from "../../../Environment/handler/DefaultDragHandler";
import { DatapointObserver } from "../../../Environment/model/DatapointObserver";
import ReorderableMember from "../ReorderableMember/ReorderableMember";
import { ObserveableArrangeableRelationalNuml } from "../../interfaces/ObserveableArrangeableRelationalNuml";
import { DefaultType } from "../../../Type/model/impl/DefaultType";
import { AddCircle } from "../../../VisualElements/view/Elements";
import { EventMember } from "../../../EventMember/interfaces/EventMember";

interface IProps {
  numl: ObserveableArrangeableRelationalNuml;
  Delete: () => void;
}

interface IState {
  mode: string;
}

export default class NumlView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      mode: "grab",
    };
    this.dragHandler = new DefaultDragHandler(
      new DatapointObserver(
        () => {
          this.setState({ mode: "grab" });
          this.props.numl.RaiseNext();
        },
        this.move.bind(this),
        undefined
      )
    );
  }

  private dragHandler: DragHandler;

  visualizeProperties(properties: Property[]): JSX.Element[] {
    const visualizedProperties: JSX.Element[] = [];
    properties.forEach((property: Property, index: number) => {
      visualizedProperties.push(
        <div key={"p" + index}>
          <ReorderableMember
            SwapToNext={() => this.props.numl.SwapPropertyToNext(index)}
            SwapToPrev={() => this.props.numl.SwapPropertyToPrev(index)}
            Member={
              <PropertyVisual
                Delete={() => {
                  this.props.numl.DeleteMember(property);
                  this.forceUpdate();
                }}
                Change={(s: string) => {
                  this.props.numl.DeleteMember(property);
                  this.props.numl.AddMemberByString(s);
                  this.forceUpdate();
                }}
                key={"P:" + property.Name}
                property={property}
              />
            }
          />
        </div>
      );
    });
    return visualizedProperties;
  }

  visualizeEvents(events: EventMember[]): JSX.Element[] {
    const visualizedEvents: JSX.Element[] = [];
    events.forEach((event: EventMember, index: number) => {
      visualizedEvents.push(
        <div key={"e" + index}>
          <ReorderableMember
            SwapToNext={() => this.props.numl.SwapEventToNext(index)}
            SwapToPrev={() => this.props.numl.SwapEventToPrev(index)}
            Member={
              <EventVisual
                Delete={() => {
                  this.props.numl.DeleteMember(event);
                  this.forceUpdate();
                }}
                Change={(s: string) => {
                  this.props.numl.DeleteMember(event);
                  this.props.numl.AddMemberByString(s);
                  this.forceUpdate();
                }}
                key={"E:" + event.Name}
                event={event}
              />
            }
          />
        </div>
      );
    });
    return visualizedEvents;
  }
  visualizeMethods(Methods: Method[]): JSX.Element[] {
    const visualizedMethods: JSX.Element[] = [];
    Methods.forEach((method: Method, index: number) => {
      visualizedMethods.push(
        <div key={"m" + index}>
          <ReorderableMember
            SwapToNext={() => this.props.numl.SwapMethodToNext(index)}
            SwapToPrev={() => this.props.numl.SwapMethodToPrev(index)}
            Member={
              <MethodVisual
                Delete={() => {
                  this.props.numl.DeleteMember(method);
                  this.forceUpdate();
                }}
                Change={(s: string) => {
                  this.props.numl.DeleteMember(method);
                  this.props.numl.AddMemberByString(s);
                  this.forceUpdate();
                }}
                key={"M:" + method.Name}
                method={method}
              />
            }
          />
        </div>
      );
    });
    return visualizedMethods;
  }

  visualizeConstructors(constructors: Constructor[]): JSX.Element[] {
    const visualizedConstructors: JSX.Element[] = [];
    constructors.forEach((ctor: Constructor, index: number) => {
      visualizedConstructors.push(
        <div key={"c" + index}>
          <ReorderableMember
            SwapToNext={() => this.props.numl.SwapConstructorToNext(index)}
            SwapToPrev={() => this.props.numl.SwapConstructorToPrev(index)}
            Member={
              <ConstructorVisual
                Delete={() => {
                  this.props.numl.DeleteMember(ctor);
                  this.forceUpdate();
                }}
                Change={(s: string) => {
                  this.props.numl.DeleteMember(ctor);
                  this.props.numl.AddMemberByString(s);
                  this.forceUpdate();
                }}
                key={"C:" + ctor.Name + index}
                ctor={ctor}
              />
            }
          />
        </div>
      );
    });
    return visualizedConstructors;
  }

  AddMember(member: string): void {
    this.props.numl.AddMemberByString(member);
    this.forceUpdate();
  }

  private offset: Datapoint = {
    x: 0,
    y: 0,
  };

  handleMouseDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    this.offset = {
      x: this.props.numl.Position.x,
      y: this.props.numl.Position.y,
    };
    this.setState({ mode: "grabbing" });
    this.dragHandler.handleDrag(event);
  }

  MoveTo(x: number, y: number): void {
    this.element = this.getElement();
    this.element.style.left = x + "px";
    this.element.style.top = y + "px";
  }

  move(delta: Datapoint): void {
    this.offset.x = this.offset.x - delta.x;
    this.offset.y = this.offset.y - delta.y;

    this.element = this.getElement();
    this.element.style.left = this.offset.x + "px";
    this.element.style.top = this.offset.y + "px";

    this.props.numl.MoveTo(this.offset.x, this.offset.y);
  }

  private element: HTMLElement | null = null;
  private getElement(): HTMLElement {
    if (this.element) {
      return this.element;
    }
    this.element = document.getElementById(this.props.numl.Id);
    if (this.element) {
      return this.element;
    } else {
      throw new Error("Element with Id " + this.props.numl.Id + " not found");
    }
  }

  render(): JSX.Element {
    return (
      <div
        className="Numl DragableItem"
        id={this.props.numl.Id}
        style={{
          left: this.props.numl.Position.x + "px",
          top: this.props.numl.Position.y + "px",
        }}
      >
        <div
          className={"header " + this.state.mode}
          onMouseDown={this.handleMouseDown.bind(this)}
        >
          <SingleLineEditor
            Delete={() => this.props.Delete()}
            OnDidChange={(s: string) => {
              this.props.numl.Rename(s);
              this.forceUpdate();
            }}
            Title={
              <div className="title">
                {this.props.numl.Name}
                <Expands
                  expands={this.props.numl.Expands.map(
                    (s) => new DefaultType(s)
                  )}
                />
              </div>
            }
            preSet={
              this.props.numl.Expands.length === 0
                ? this.props.numl.Name
                : this.props.numl.Name +
                  ": " +
                  this.props.numl.Expands.join(", ")
            }
          />
        </div>
        <div className="section">
          {this.visualizeProperties(this.props.numl.Properties)}
        </div>
        <div className="divider" />
        <div className="section">
          {this.visualizeEvents(this.props.numl.Events)}
        </div>
        <div className="divider" />
        <div className="section">
          {this.visualizeConstructors(this.props.numl.Constructors)}
        </div>
        <div className="divider" />
        <div className="section">
          {this.visualizeMethods(this.props.numl.Methods)}
        </div>
        <LazyInput
          yieldMode={true}
          label={<AddCircle color="white" />}
          onSubmit={this.AddMember.bind(this)}
        />
      </div>
    );
  }
}
