import { MultiFileCreator } from "../interfaces/MultiFileCreator";
import { FileCreator2 } from "../../Exporter2/interfaces/FileCreator2";
import { Enum } from "../../Enum/interfaces/Enum";
import { Numl } from "../../Numl/interfaces/Numl";
import { ViewDefinition } from "../../ViewDefinition/interfaces/ViewDefinition";
import { EnumExportInformation } from "../../Exporter2/interfaces/EnumExportInformation";
import { DefaultEnumExportInformation } from "../../Exporter2/model/impl/DefaultEnumExportInformation";
import { ViewExportInformation } from "../../Exporter2/interfaces/ViewExportInformation";
import { DefaultViewExportInformtation } from "../../Exporter2/model/impl/DefaultViewExportInformtation";
import { Type } from "../../Type/interfaces/Type";
import { InterfaceExpotInformation } from "../../Exporter2/interfaces/InterfaceExportInformation";
import { DefaultInterfaceExportInformation } from "../../Exporter2/model/impl/DefaultInterfaceExportInformation";
import { ClassExportInformationBuilder } from "../../Exporter2/model/impl/ClassExportInformationBuilder";
import { IFile } from "../../FileStructure/interfaces/IFile";
import { ImportInformation } from "../../Numl/interfaces/ImportInformation";

export class DefaultMultiFileCreator implements MultiFileCreator {
  constructor(
    private fileCreators: FileCreator2[],
    private getNumlToExpand: () => Numl | undefined = () => undefined
  ) {}

  private getImportInformation(s: string): ImportInformation | undefined {
    const known: string[] = ["T", "int", "number", "string", "String"];
    if (known.includes(s)) {
      return undefined;
    }
    return {
      Name: s,
      VerticalDimension: this.dimensions.VerticalDimension,
      HorizontalDimension: "Interfaces",
    };
  }
  private dimensions: {
    VerticalDimension: string;
    HorizontalDimension: string;
  } = {
    VerticalDimension: "Documentation",
    HorizontalDimension: "Example",
  };

  CreateFiles(diagramMember: Enum | Numl | ViewDefinition): IFile[][] {
    if ((diagramMember as Enum).Values) {
      const aEnum: Enum = diagramMember as Enum;
      const exportDefinition: EnumExportInformation = new DefaultEnumExportInformation(
        {
          Name: aEnum.Name,
          ...this.dimensions,
        },
        aEnum.Values
      );
      return this.fileCreators.map((fc) => [
        fc.CreateEnumFile(exportDefinition),
      ]);
    }
    if ((diagramMember as Numl).Properties) {
      const numl: Numl = diagramMember as Numl;
      const interfaceExportDefintion: InterfaceExpotInformation = new DefaultInterfaceExportInformation(
        numl,
        this.dimensions,
        (s) => this.getImportInformation(s)
      );
      const classExportInformationBuilder: ClassExportInformationBuilder = new ClassExportInformationBuilder(
        numl,
        this.dimensions,
        (s) => this.getImportInformation(s),
        this.getNumlToExpand
      );
      return this.fileCreators.map((fc) => [
        fc.CreateInterfaceFile(interfaceExportDefintion),
        ...classExportInformationBuilder
          .GetClassExports()
          .map((c) => fc.CreateClassFile(c)),
      ]);
    }
    if ((diagramMember as ViewDefinition).Dependencies) {
      const view: ViewDefinition = diagramMember as ViewDefinition;
      const exportDefinition: ViewExportInformation = new DefaultViewExportInformtation(
        {
          Name: view.Name,
          ...this.dimensions,
        },
        view.Dependencies,
        (t: Type) => {
          return {
            Name: t.FullType,
            ...this.dimensions,
          };
        }
      );
      return this.fileCreators.map((fc) => [
        fc.CreateViewFile(exportDefinition),
      ]);
    }
    return [];
  }
}
