import { Numl } from "../../interfaces/Numl";
import { Property } from "../../interfaces/Property";
import { Method } from "../../interfaces/Method";
import { Constructor } from "../../interfaces/Constructor";
import { ObserveableNuml } from "../../interfaces/ObserveableNuml";
import { DefaultObserveable } from "../../../Environment/model/DefaultObserveable";
import { Datapoint } from "../../../Diagram/interfaces/Datapoints";
import { EventMember } from "../../../EventMember/interfaces/EventMember";

export class DefaultObserveableNuml
  extends DefaultObserveable<Numl>
  implements ObserveableNuml {
  public get Properties(): Property[] {
    return [...this.numl.Properties];
  }
  public get Events(): EventMember[] {
    return [...this.numl.Events];
  }
  public get Methods(): Method[] {
    return [...this.numl.Methods];
  }
  public get Constructors(): Constructor[] {
    return [...this.numl.Constructors];
  }

  public get Id(): string {
    return this.numl.Id;
  }

  public get Name(): string {
    return this.numl.Name;
  }
  public get Expands(): string[] {
    return this.numl.Expands;
  }

  public get Position(): Datapoint {
    return this.numl.Position;
  }

  constructor(private numl: Numl) {
    super(numl);
  }

  MoveTo(x: number, y: number): void {
    this.numl.MoveTo(x, y);
  }
  RaiseNext(): void {
    this.raiseNext(this);
  }

  public SwapPropertyToPrev(index: number): void {
    this.numl.SwapPropertyToPrev(index);
    this.raiseNext(this);
  }
  public SwapEventToPrev(index: number): void {
    this.numl.SwapEventToPrev(index);
    this.raiseNext(this);
  }
  public SwapMethodToPrev(index: number): void {
    this.numl.SwapMethodToPrev(index);
    this.raiseNext(this);
  }
  public SwapConstructorToPrev(index: number): void {
    this.numl.SwapConstructorToPrev(index);
    this.raiseNext(this);
  }
  public SwapPropertyToNext(index: number): void {
    this.numl.SwapPropertyToNext(index);
    this.raiseNext(this);
  }
  public SwapEventToNext(index: number): void {
    this.numl.SwapEventToNext(index);
    this.raiseNext(this);
  }
  public SwapMethodToNext(index: number): void {
    this.numl.SwapMethodToNext(index);
    this.raiseNext(this);
  }
  public SwapConstructorToNext(index: number): void {
    this.numl.SwapConstructorToNext(index);
    this.raiseNext(this);
  }

  public AddMember(
    member: Constructor | Property | Method | EventMember
  ): void {
    this.numl.AddMember(member);
    this.raiseNext(this);
  }

  public Rename(newName: string): void {
    this.numl.Rename(newName);
    this.raiseNext(this);
  }

  public AddMemberByString(s: string): void {
    this.numl.AddMemberByString(s);
    this.raiseNext(this);
  }

  public DeleteMember(
    member: Property | Constructor | Method | EventMember
  ): void {
    this.numl.DeleteMember(member);
    this.raiseNext(this);
  }
}
