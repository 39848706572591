import React from "react";
import ReorderableMember from "../../Numl/view/ReorderableMember/ReorderableMember";
import LazyInput from "../../Controls/view/LazyInput/LazyInput";
import SingleLineEditor from "../../Controls/view/SingleLineEditor/SingleLineEditor";
import { AddCircle } from "../../VisualElements/view/Elements";
import { Datapoint } from "../../Diagram/interfaces/Datapoints";
import { DefaultDragHandler } from "../../Environment/handler/DefaultDragHandler";
import { DragHandler } from "../../Environment/interface/DragHandler";
import { DatapointObserver } from "../../Environment/model/DatapointObserver";
import { ObserveableEnum } from "../interfaces/ObserveableEnum";
import "./Enum.css";

interface IProps {
  enum: ObserveableEnum;
  Delete: () => void;
}
interface IState {
  mode: string;
}
export default class EnumView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      mode: "grab",
    };
    this.dragHandler = new DefaultDragHandler(
      new DatapointObserver(
        () => {
          this.setState({ mode: "grab" });
          this.props.enum.RaiseNext();
        },
        this.move.bind(this),
        undefined
      )
    );
  }
  private dragHandler: DragHandler;
  private offset: Datapoint = {
    x: 0,
    y: 0,
  };

  handleMouseDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    this.offset = {
      x: this.props.enum.Position.x,
      y: this.props.enum.Position.y,
    };
    this.setState({ mode: "grabbing" });
    this.dragHandler.handleDrag(event);
  }

  MoveTo(x: number, y: number): void {
    this.element = this.getElement();
    this.element.style.left = x + "px";
    this.element.style.top = y + "px";
  }

  move(delta: Datapoint): void {
    this.offset.x = this.offset.x - delta.x;
    this.offset.y = this.offset.y - delta.y;

    this.element = this.getElement();
    this.element.style.left = this.offset.x + "px";
    this.element.style.top = this.offset.y + "px";

    this.props.enum.MoveTo(this.offset.x, this.offset.y);
  }

  private element: HTMLElement | null = null;
  private getElement(): HTMLElement {
    if (this.element) {
      return this.element;
    }
    this.element = document.getElementById(this.props.enum.Id);
    if (this.element) {
      return this.element;
    } else {
      throw new Error("Element with Id " + this.props.enum.Id + " not found");
    }
  }

  render() {
    return (
      <div
        className="Enum DragableItem"
        id={this.props.enum.Id}
        style={{
          left: this.props.enum.Position.x + "px",
          top: this.props.enum.Position.y + "px",
        }}
      >
        <div
          className={"header Type " + this.state.mode}
          onMouseDown={this.handleMouseDown.bind(this)}
        >
          <SingleLineEditor
            Delete={() => this.props.Delete()}
            OnDidChange={(s: string) => {
              this.props.enum.Rename(s);
              this.forceUpdate();
            }}
            Title={<div className="title">{this.props.enum.Name}</div>}
            preSet={this.props.enum.Name}
          />
        </div>
        <div className="section">
          {this.props.enum.Values.map((s, index) => (
            <ReorderableMember
              SwapToNext={() => this.props.enum.SwapValueToNext(index)}
              SwapToPrev={() => this.props.enum.SwapValueToPrev(index)}
              Member={
                <SingleLineEditor
                  Delete={() => this.props.enum.Remove(s)}
                  OnDidChange={(value: string) => {
                    this.props.enum.Change(s, value);
                    this.forceUpdate();
                  }}
                  Title={s}
                  preSet={s}
                />
              }
            />
          ))}
        </div>
        <LazyInput
          yieldMode={true}
          label={<AddCircle color="white" />}
          onSubmit={(s: string) => this.props.enum.Add(s)}
        />
      </div>
    );
  }
}
