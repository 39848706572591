import React from "react";
import LazyInput from "../LazyInput/LazyInput";
import { Cancel, Pencil } from "../../../VisualElements/view/Elements";
import "./SingleLineEditor.css"

interface IProps {
    Title: string | JSX.Element;
    Delete: () => void;
    OnDidChange: (value: string) => void
    preSet: string;
}


export default (props: IProps) =>
    <div className="SingleLineEditor">
        <div className="title">
            {props.Title}
        </div>
        <LazyInput
            label={<Pencil />}
            onSubmit={props.OnDidChange}
            preSet={props.preSet}
            yieldMode={false}
        />
        <Cancel Action={() => props.Delete()} />

    </div>