import { Datapoint } from "../../Diagram/interfaces/Datapoints";
import { Enum } from "../interfaces/Enum";
import { ViewableEnum } from "../interfaces/ViewableEnum";



export class DefaultViewableEnum implements ViewableEnum {
    public get PositionOfCenter(): Datapoint {
        return {
            x: this.aEnum.Position.x + (this.descriptiveData.width/2),
            y: this.aEnum.Position.y + 20
        }
    }

    constructor(private aEnum: Enum) {
        this.descriptiveData = { Position: aEnum.Position, width: 100 };
    }

    SwapValueToPrev(index: number): void {
        this.aEnum.SwapValueToPrev(index);
    }
    SwapValueToNext(index: number): void {
        this.aEnum.SwapValueToNext(index);
    }

    public get Position(): Datapoint { return this.aEnum.Position; }
    public get Values(): string[] { return this.aEnum.Values; }
    public get Id(): string { return this.aEnum.Id; }
    public get Name(): string { return this.aEnum.Name; }


    Add(s: string): void {
        this.aEnum.Add(s);
    }
    Change(prev: string, newStr: string): void {
        this.aEnum.Change(prev, newStr);
    }
    Remove(s: string): void {
        this.aEnum.Remove(s);
    }
    Rename(name: string): void {
        this.aEnum.Rename(name);
    }

    private descriptiveData: { Position: Datapoint, width: number };

    private element: HTMLElement | null = null;
    private getElement(): HTMLElement {
        if (this.element) {
            this.descriptiveData.width = this.element.offsetWidth;
            return this.element;
        }
        this.element = document.getElementById(this.Id);
        if (this.element) {
            this.descriptiveData.width = this.element.offsetWidth;
            return this.element;
        } else {
            throw new Error("Element with Id " + this.Id + " not found");
        }
    }

    MoveTo(x: number, y: number): void {
        this.element = this.getElement();
        this.element.style.left = x + "px";
        this.element.style.top = y + "px";

        this.aEnum.MoveTo(x, y);
    }
}