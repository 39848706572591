import { Arrow } from "../../../Arrow/interfaces/Arrow";
import { ArrangeableRelationalNuml } from "../../interfaces/ArrangeableRelationalNuml";
import { RelationalProperty } from "../../interfaces/RelationalProperty";
import { HasCenter } from "../../interfaces/HasCenter";
import { RelationalMethod } from "../../interfaces/RelationalMethod";
import { RelationalConstructor } from "../../interfaces/RelationalConstructor";
import { ArrowProvider } from "../../../Arrow/interfaces/ArrowProvider";
import { IsExtendable } from "../../interfaces/IsExtendable";
import { RelationType } from "../../../Diagram/interfaces/RelationType";
import { Diagram } from "../../../Diagram/interfaces/Diagram";
import { Enum } from "../../../Enum/interfaces/Enum";
import { DefaultViewableEnum } from "../../../Enum/viewmodel/DefaultViewableEnum";
import { ViewDefinition } from "../../../ViewDefinition/interfaces/ViewDefinition";
import { ViewableDependency } from "../../../ViewDefinition/interfaces/ViewableDependency";
import { DefaultViewableViewDefinition } from "../../../ViewDefinition/viewmodel/DefaultViewableViewDefinition";
import { DefaultArrangeableRelationalNuml } from "./DefaultArrangeableRelarionalNuml";
import { DefaultType } from "../../../Type/model/impl/DefaultType";
import { Type } from "../../../Type/interfaces/Type";

export class ArrowVisitor implements ArrowProvider {
  private arrows: Arrow[] = [];
  public GetArrows(): Arrow[] {
    this.arrows = [];
    this.visit();
    return this.arrows;
  }

  constructor(private diagram: Diagram) {}

  protected visit(): void {
    this.visitDiagram(this.diagram);
    this.diagram.Numles.map(
      (n) => new DefaultArrangeableRelationalNuml(n)
    ).forEach((numl) => {
      this.visitNuml(numl);
      numl.Properties.forEach((prop) => this.visitProperty(prop));
      numl.Constructors.forEach((ctor) => this.visitConstructor(ctor));
      numl.Methods.forEach((method) => this.visitMethod(method));
    });
    this.diagram.Views.forEach((view) => {
      new DefaultViewableViewDefinition(
        view
      ).Dependencies.forEach((dependency) => this.visitDependency(dependency));
    });
  }

  private addHorizontalArrow(
    relationType: RelationType,
    Source: HasCenter,
    Target: HasCenter
  ): void {
    this.arrows.push({
      RelationType: relationType,
      Source: Source.PositionOfCenter,
      Target: Target.PositionOfCenter,
    });
  }
  private addVerticalArrow(
    relationType: RelationType,
    Source: IsExtendable,
    Target: IsExtendable
  ): void {
    this.arrows.push({
      RelationType: relationType,
      Source: Source.ExtendedDockingPoint,
      Target: Target.ExtendingDockingPoint,
    });
  }

  private clazzByName: { [name: string]: ArrangeableRelationalNuml } = {};
  private enumByName: { [name: string]: Enum } = {};
  private viewByName: { [name: string]: ViewDefinition } = {};

  protected visitDiagram(diagram: Diagram): void {
    this.clazzByName = {};
    this.enumByName = {};
    this.viewByName = {};
    diagram.Numles.map((n) => new DefaultArrangeableRelationalNuml(n)).forEach(
      (numl: ArrangeableRelationalNuml) => {
        this.clazzByName[new DefaultType(numl.Name).TechnicalType] = numl;
      }
    );
    diagram.Enums.forEach((e: Enum) => {
      this.enumByName[e.Name] = e;
    });
    // diagram.Views.forEach((view: ViewDefinition) => {
    //     this.viewByName[view.Name] = view;
    // })
  }

  protected visitNuml(numl: ArrangeableRelationalNuml): void {
    numl.Expands.forEach((s: string) => {
      const nameAsType: Type = new DefaultType(s);
      if (this.clazzByName[nameAsType.TechnicalType]) {
        this.addVerticalArrow(
          RelationType.Inheritance,
          this.clazzByName[nameAsType.TechnicalType],
          numl
        );
      }
    });
  }

  protected visitDependency(dependency: ViewableDependency): void {
    if (this.clazzByName[new DefaultType(dependency.Name).TechnicalType]) {
      this.addHorizontalArrow(
        RelationType.Dependency,
        this.clazzByName[new DefaultType(dependency.Name).TechnicalType],
        dependency
      );
    } else if (this.enumByName[dependency.Name]) {
      this.addHorizontalArrow(
        RelationType.Dependency,
        new DefaultViewableEnum(this.enumByName[dependency.Name]),
        dependency
      );
    } /* else if (this.viewByName[dependency.Name]) {
            this.addHorizontalArrow(RelationType.Dependency,
                new DefaultViewableViewDefinition(this.viewByName[dependency.Name]),
                dependency);
        }*/
  }

  protected GetFooToAddHorizontalArrowArrows(
    target: HasCenter,
    type: RelationType
  ): (s: string) => void {
    return (s: string) => {
      s = this.cutListBrackets(s);
      if (this.clazzByName[s]) {
        this.addHorizontalArrow(type, this.clazzByName[s], target);
      } else if (this.enumByName[s]) {
        this.addHorizontalArrow(
          type,
          new DefaultViewableEnum(this.enumByName[s]),
          target
        );
      }
    };
  }

  protected visitProperty(property: RelationalProperty): void {
    const foo: (s: string) => void = this.GetFooToAddHorizontalArrowArrows(
      property,
      RelationType.Instantiate
    );
    foo(property.ReturnType.TechnicalType);
    // todo: infini type
    property.ReturnType.FurtherTypes.forEach((s) => foo(s.TechnicalType));
  }

  protected visitConstructor(ctor: RelationalConstructor): void {
    const foo: (s: string) => void = this.GetFooToAddHorizontalArrowArrows(
      ctor,
      RelationType.Dependency
    );
    ctor.ParamTypes.forEach((t) => {
      foo(t.TechnicalType);
      // todo: infini type
      t.FurtherTypes.forEach((s) => foo(s.TechnicalType));
    });
  }

  protected visitMethod(method: RelationalMethod): void {
    const fooInstantiate: (
      s: string
    ) => void = this.GetFooToAddHorizontalArrowArrows(
      method,
      RelationType.Instantiate
    );
    fooInstantiate(method.ReturnType.TechnicalType);
    // todo: infini type
    method.ReturnType.FurtherTypes.forEach((s) =>
      fooInstantiate(s.TechnicalType)
    );

    const fooDependency: (
      s: string
    ) => void = this.GetFooToAddHorizontalArrowArrows(
      method,
      RelationType.Dependency
    );
    method.ParamTypes.forEach((t) => {
      fooDependency(t.TechnicalType);
      // todo: infini type
      t.FurtherTypes.forEach((s) => fooDependency(s.TechnicalType));
    });
  }

  private cutListBrackets(s: string): string {
    s = s.trim();
    if (s.endsWith("[]")) {
      return s.substr(0, s.length - 2);
    }
    return s;
  }
}
