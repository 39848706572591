import {ImArrowDown, ImArrowLeft, ImArrowRight, ImArrowUp} from 'react-icons/im'
import React from "react";

// tslint:disable-next-line: interface-name
interface Props {
    Action: () => void;
    Scale? : number;
}

export const ArrowRight =(props: Props): JSX.Element=>
    <div className="Element Arrow right" onClick={() => props.Action()}>
           <ImArrowRight scale={props.Scale} className="icons" />
    </div>


export const ArrowLeft = (props: Props): JSX.Element =>
    <div className="Element Arrow left" onClick={() => props.Action()}>
           <ImArrowLeft scale={props.Scale} className="icons" />
    </div>

export const ArrowUp =(props: Props): JSX.Element=>
    <div className="Element Arrow up" onClick={() => props.Action()}>
           <ImArrowUp scale={props.Scale} className="icons" />
    </div>


export const ArrowDown = (props: Props): JSX.Element =>
    <div className="Element Arrow down" onClick={() => props.Action()}>
           <ImArrowDown scale={props.Scale} className="icons" />
    </div>
