import React from "react";
import LazyInput from "../../Controls/view/LazyInput/LazyInput";
import { AddCircle } from "../../VisualElements/view/Elements";
import "./ViewDefinition.css";
import { ViewDefinition } from "../interfaces/ViewDefinition";

interface IProps {
  view: ViewDefinition;
}

interface IState {}

export default class DeadViewDefinition extends React.Component<
  IProps,
  IState
> {
  render() {
    return (
      <div
        className="View DragableItem"
        id={this.props.view.Id}
        style={{
          left: this.props.view.Position.x + "px",
          top: this.props.view.Position.y + "px",
        }}
      >
        <div className={"header"}>
          <div className="title">{this.props.view.Name}</div>{" "}
        </div>
        <div className="section Type">
          {this.props.view.Dependencies.map((s, index) => (
            <div key={index}>{s.FullType}</div>
          ))}
        </div>
        <LazyInput
          yieldMode={true}
          label={<AddCircle color="white" />}
          onSubmit={(s: string) => this.props.view.Add(s)}
        />
      </div>
    );
  }
}
