import React from "react";
import { Action } from "../../interfaces/Action";

// tslint:disable-next-line: interface-name
interface Props {
    Action: Action
}

export default (props: Props): JSX.Element => {

    function nestedAction(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        props.Action.Action(undefined);
    }

    return (<div className="ChoiceButtonListItem" onClick={(e) => nestedAction(e)}>
        {props.Action.Label}
    </div>)
}
