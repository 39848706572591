import { Type } from "../../interfaces/Type";
import { TypeType } from "../../enum/TypeType";

export class DefaultType implements Type {
  public get Type(): TypeType {
    return TypeType.DefaultType;
  }
  public get IsGeneric(): boolean {
    return this.FurtherTypes.length !== 0;
  }

  public get IsOptional(): boolean {
    return this.isOptional;
  }

  TechnicalType: string;
  get FullType(): string {
    let fullType: string = this.BaseType;
    if (this.FurtherTypes.length > 0) {
      fullType =
        fullType +
        "<" +
        this.FurtherTypes.map((t) => t.FullType).join(", ") +
        ">";
    }
    if (this.isOptional) {
      fullType += "?";
    }
    return fullType;
  }
  FurtherTypes: Type[];
  BaseType: string;

  private isOptional: boolean = false;

  constructor(s: string) {
    let r: {
      aType: string;
      baseType: string;
      furtherTypes: Type[];
    } = this.parseString(s.trim());
    this.TechnicalType = r.aType;
    this.BaseType = r.baseType;
    this.FurtherTypes = r.furtherTypes;
  }

  private formatFoo(s: string): string {
    let result: string = "";

    for (let i: number = 0, l: number = s.length; i < l; i++) {
      switch (s[i]) {
        case " ":
        case "\t":
          break;
        case ",":
          result += ", ";
          console.log("result: " + result);
          break;
        default:
          result += s[i];
          break;
      }
    }
    return result;
  }

  private parseString(
    s: string
  ): { aType: string; baseType: string; furtherTypes: Type[] } {
    if (s.startsWith("(")) {
      console.log("parseString: " + s);
      return {
        baseType: this.formatFoo(s),
        aType: "foo()",
        furtherTypes: [],
      };
    }
    let aType: string = "";
    const splitter: string = "<,>";
    const brackets: string = "[]{}()";
    const furtherTypes: Type[] = [];
    let subType: string = "";
    let baseType: string = "";
    let deepness: number = 0;
    for (let i: number = 0, l: number = s.length; i < l; i++) {
      while (i < l && !brackets.includes(s[i]) && !splitter.includes(s[i])) {
        aType += s[i++];
      }
      baseType = aType;
      while (i < l) {
        if (s[i] === "<") {
          if (deepness === 0) {
            aType += s[i];
          } else {
            subType += s[i];
          }
          deepness++;
          i++;
          continue;
        } else if (s[i] === ">") {
          deepness--;
          if (deepness === 0) {
            if (subType.length > 0) {
              furtherTypes.push(new DefaultType(subType));
            }
            aType += s[i];
            subType = "";
          }
        } else if (s[i] === ",") {
          if (deepness === 1) {
            aType += s[i];
            furtherTypes.push(new DefaultType(subType));
            subType = "";
            i++;
            continue;
          }
        }
        if (deepness > 0) {
          subType += s[i];
        }
        i++;
      }
    }
    if (aType.endsWith("?")) {
      aType = aType.substr(0, aType.length - 1);
      this.isOptional = true;
    }
    return {
      aType,
      baseType,
      furtherTypes,
    };
  }
}
