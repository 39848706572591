import { Observeable } from "../../Environment/interface/Observeable";
import { Observer } from "../../Environment/interface/Observer";




export class DefaultObserveable<T> implements Observeable<T> {
    private observers: Observer<T>[] = []
    public readonly IsObserveable = true;

    constructor(protected readonly original: T) { }

    Attach(observer: Observer<T>) {
        if (!this.observers.includes(observer)) {
            this.observers.push(observer);
        }
    }
    Detach(observer: Observer<T>) {
        this.observers = this.observers.filter(o => o !== observer);
    }

    protected raiseNext(obj: T) {
        this.observers.forEach(o => o.Next(obj))
    }
    protected raiseError(err: Error) {
        this.observers.forEach(o => o.Error(err))
    }
    protected raiseComplete() {
        this.observers.forEach(o => o.Complete())
    }

}