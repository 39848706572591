import { IFile } from "../../interfaces/IFile";




export class DefaultFile implements IFile {
    public readonly IsDirectory = false;
    public readonly IsFile = true;

    constructor(public Name: string, public Content: string = "") { }
}