import { HorizontalLayer } from "../interfaces/HorizontalLayer";
import { Layers } from "../interfaces/Layers";
import { VerticalLayer } from "../interfaces/VerticalLayer";
import { ViewableLayers } from "../interfaces/ViewableLayers";
import { DefaultHorizontalLayer } from "../model/impl/DefaultHorizontalLayer";
import { DefaultVerticalLayer } from "../model/impl/DefaultVerticalLayer";



export class DefaultViewableLayers implements ViewableLayers {
    public get Width(): number {
        return this.VerticalLayers.map(l => l.Width).reduce((p, c) => p + c, 0)
    }
    public get Height(): number {
        return this.HorizontalLayers.map(l => l.Height).reduce((p, c) => p + c, 0)
    }

    constructor(private layers: Layers) { }

    get HorizontalLayers() { return this.layers.HorizontalLayers; }
    get VerticalLayers() { return this.layers.VerticalLayers; }
    AddHorizontalLayer = (s: string) => { this.layers.AddHorizontalLayer(new DefaultHorizontalLayer(s, 400)) };
    AddVerticalLayer = (s: string) => this.layers.AddVerticalLayer(new DefaultVerticalLayer(s, 400));
    SwapHorizontalLayerToPrev = (index: number) => this.layers.SwapHorizontalLayerToPrev(index);
    SwapVerticalLayerToPrev = (index: number) => this.layers.SwapVerticalLayerToPrev(index);
    SwapHorizontalLayerToNext = (index: number) => this.layers.SwapHorizontalLayerToNext(index);
    SwapVerticalLayerToNext = (index: number) => this.layers.SwapVerticalLayerToNext(index);
    RemoveHorizontalLayer = (layer: HorizontalLayer) => this.layers.RemoveHorizontalLayer(layer);
    RemoveVerticalLayer = (layer: VerticalLayer) => this.layers.RemoveVerticalLayer(layer);
}