import { VerticalLayer } from "../../interfaces/VerticalLayer";




export class ResizeListenerVerticalLayer implements VerticalLayer {

    constructor(private layer: VerticalLayer, private beforeResize: (diff: number) => void) { }

    Rename(name: string): void {
        this.layer.Rename(name);
    }

    public get Width(): number { return this.layer.Width; }
    public get Id(): string { return this.layer.Id; }
    public get Name(): string { return this.layer.Name; }

    ResizeWidth(newHeight: number): void {
        this.beforeResize(this.Width - newHeight);
        this.layer.ResizeWidth(newHeight);
    }
}