import { HorizontalLayer } from "../../interfaces/HorizontalLayer";




export class DefaultHorizontalLayer implements HorizontalLayer {
    private static nextId: number = 1;

    public get Name() { return this.name; }
    public get Height() { return this.height; }
    public readonly Id: string;


    constructor(private name: string, private height: number = 0) {
        this.Id = "horizontal-layer-" + DefaultHorizontalLayer.nextId++;
    }

    public Rename(name: string) {
        this.name = name;
    }

    public ResizeHeight(newHeight: number) {
        this.height = newHeight;
    }
}