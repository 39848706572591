import React from "react";

interface IProps {
  version: string;
  upadates: string[];
}

export default (props: IProps) => {
  const update = (text: string) => <div className="update"> - {text}</div>;
  return (
    <div className="section">
      <div className="version">
        <h2>{props.version}</h2>
      </div>
      <div className="upadates">{props.upadates.map(update)}</div>
    </div>
  );
};
