import { ArrayHelper } from "../../ArrayHelper/static/impl/ArrayHelper";
import { Datapoint } from "../../Diagram/interfaces/Datapoints";
import { Type } from "../../Type/interfaces/Type";
import { DefaultType } from "../../Type/model/impl/DefaultType";
import { ViewDefinition } from "../interfaces/ViewDefinition";




export class DefaultViewDefinition implements ViewDefinition {

    public readonly Id: string;
    private static nextIdentifier: number = 1;

    public get Dependencies(): Type[] {
        return [...this.dependencies];
    }
    public get Name(): string {
        return this.name;
    }

    constructor(private name: string, public Position: Datapoint, private dependencies: Type[] = []) {
        this.Id = "view-" + DefaultViewDefinition.nextIdentifier++;
    }

    SwapValueToPrev = (index: number) => ArrayHelper.SwapToPrev(this.dependencies, index);
    SwapValueToNext = (index: number) => ArrayHelper.SwapToNext(this.dependencies, index);

    Add(s: string): void {
        this.dependencies.push(new DefaultType(s));
    }

    Remove(s: Type): void {
        this.dependencies = this.dependencies.filter(str => str !== s);
    }

    ChangeDependency(prev: Type, newStr: string) {
        if (prev.FullType === newStr) {
            return
        }
        this.Add(newStr);
        this.Remove(prev);
    }

    MoveTo(x: number, y: number) {
        this.Position.x = x;
        this.Position.y = y;
    }



    Rename(name: string): void {
        this.name = name;
    }

}