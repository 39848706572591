import React from "react"
import Button from "../../../Button/view/Button"
import { Action } from "../../../Controls/interfaces/Action";


interface IProps {
    AddItem(item: string): void;
}

interface IState {
    s: string;
}

export default class AddItemm extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            s: "",
        }
        this.action = {
            Label: "Add",
            Action: () => { props.AddItem(this.state.s) }
        }
    }

    private handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {
            this.props.AddItem(this.state.s);
        }
    }
    private readonly action: Action;

    render() {
        return (
            <div>
                <input type="text" value={this.state.s} onKeyUp={(e) => this.handleKeyUp(e)} autoFocus={true} onChange={(e) => this.setState({ s: e.target.value })} />
                <Button Action={this.action} />
            </div>
        )
    }

}