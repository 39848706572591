import React from "react";
import LazyInput from "../../Controls/view/LazyInput/LazyInput";
import { AddCircle } from "../../VisualElements/view/Elements";
import "./Enum.css";
import { Enum } from "../interfaces/Enum";

interface IProps {
  enum: Enum;
}

interface IState {}

export default class DeadEnum extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="Enum DragableItem"
        id={this.props.enum.Id}
        style={{
          left: this.props.enum.Position.x + "px",
          top: this.props.enum.Position.y + "px",
        }}
      >
        <div className={"header Type "}>
          <div className="title">{this.props.enum.Name}</div>
        </div>
        <div className="section">
          {this.props.enum.Values.map((s, index) => (
            <div key={index}>{s}</div>
          ))}
        </div>
        <LazyInput
          yieldMode={true}
          label={<AddCircle color="white" />}
          onSubmit={(s: string) => this.props.enum.Add(s)}
        />
      </div>
    );
  }
}
