import React from "react";
import "./Arrow.css";

import { RelationType } from "../../Diagram/interfaces/RelationType";
import { Arrow } from "../interfaces/Arrow";

interface IProps extends Arrow {}

interface IBorderstyle {
  borderTopStyle: string;
  borderRightStyle: string;
  borderBottomStyle: string;
  borderLeftStyle: string;
}

export default (props: IProps): JSX.Element => {
  let left: number =
    props.Source.x < props.Target.x ? props.Source.x : props.Target.x;
  let top: number =
    props.Source.y < props.Target.y ? props.Source.y : props.Target.y;

  let width: number = Math.abs(props.Source.x - props.Target.x) / 2;
  let height: number = Math.abs(props.Source.y - props.Target.y);

  let borderStyleFirstDiv: IBorderstyle;
  let borderStyleSecondDiv: IBorderstyle;

  let opacity: number = 1;
  let borderStyle: string;
  switch (props.RelationType) {
    case RelationType.Dependency:
      borderStyle = "solid";
      opacity = 0.33;
      break;
    case RelationType.Inheritance:
      borderStyle = "solid";
      break;
    case RelationType.Instantiate:
      borderStyle = "dotted";
      break;
  }

  let sourcePointIsInLeftTop: boolean =
    props.Source.x === left && props.Source.y === top;
  let targetPointIsInLeftTop: boolean =
    props.Target.x === left && props.Target.y === top;

  if (sourcePointIsInLeftTop || targetPointIsInLeftTop) {
    borderStyleFirstDiv = {
      borderTopStyle: borderStyle,
      borderRightStyle: "none",
      borderBottomStyle: "none",
      borderLeftStyle: "none",
    }; // "RightTop";
    borderStyleSecondDiv = {
      borderTopStyle: "none",
      borderRightStyle: "none",
      borderBottomStyle: borderStyle,
      borderLeftStyle: borderStyle,
    }; // "LeftBottom";
  } else {
    // tslint:disable-next-line: max-line-length
    borderStyleFirstDiv = {
      borderTopStyle: "none",
      borderRightStyle: "none",
      borderBottomStyle: borderStyle,
      borderLeftStyle: "none",
    }; // "RightBottom";
    borderStyleSecondDiv = {
      borderTopStyle: borderStyle,
      borderRightStyle: "none",
      borderBottomStyle: "none",
      borderLeftStyle: borderStyle,
    }; // "LeftTop";
  }

  let bothDiv: any = {
    width,
    height,
    top,
    position: "absolute",
  };
  let firstDiv: any = { left, ...borderStyleFirstDiv, ...bothDiv, opacity };
  let secondDiv: any = {
    left: left + width,
    ...borderStyleSecondDiv,
    ...bothDiv,
    opacity,
  };

  return (
    <div>
      <div className="Arrow" style={firstDiv} />

      <div className="Arrow" style={secondDiv} />
    </div>
  );
};

