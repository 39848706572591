import React from "react";
import "./Expands.css";
import TypeView from "../Type/Type";
import { Type } from "../../../Type/interfaces/Type";

// tslint:disable-next-line: interface-name
interface Props {
    expands: Type[];
}


function visualizeParams(expands: Type[]): (JSX.Element | string)[] {
    let visualizedExpands: (JSX.Element | string)[] = [];
    if (expands.length > 0) {
        visualizedExpands.push("\u00A0:\u00A0");
    }
    expands.forEach((param: Type, i: number) => {
        visualizedExpands.push(<TypeView key={i} type={param} />);
    });
    return visualizedExpands;
}

export default (props: Props): JSX.Element => <div className="Expands">{visualizeParams(props.expands)}</div>;



