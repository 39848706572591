import React from "react";
import "./ConstructorVisual.css";

import Params from "../../elements/Params/Params";
import { IsDeletable } from "../IsDeletable";
import { IsChangeable } from "../../interfaces/IsChangeable";
import SingleLineEditor from "../../../Controls/view/SingleLineEditor/SingleLineEditor";
import { Constructor } from "../../interfaces/Constructor";
import { Type } from "../../../Type/interfaces/Type";
import { ArrayHelper } from "../../../ArrayHelper/static/impl/ArrayHelper";

interface IProps extends IsDeletable, IsChangeable {
  ctor: Constructor;
}

export default (props: IProps): JSX.Element => {
  function visualizeConcreteGenereics(types: Type[]): JSX.Element | undefined {
    if (types.length > 0) {
      return (
        <div className="queer">
          &lt;
          {ArrayHelper.Join(
            types.map((t) => <div className="Type">{t.FullType}</div>),
            ", "
          )}
          &gt;
        </div>
      );
    }
  }
  return (
    <SingleLineEditor
      Delete={props.Delete}
      OnDidChange={props.Change}
      Title={
        <div className="Property">
          <div>{props.ctor.Name}</div>
          <Params params={props.ctor.ParamTypes} />
          {visualizeConcreteGenereics(props.ctor.ConcreteGenerics)}
        </div>
      }
      preSet={
        props.ctor.Name +
        "(" +
        props.ctor.ParamTypes.map((t) => t.FullType).join(", ") +
        ")"
      }
    />
  );
};
