import React from "react";
import "./Layer.css";
import { DragHandler } from "../../../Environment/interface/DragHandler";
import { DefaultDragHandler } from "../../../Environment/handler/DefaultDragHandler";
import { Datapoint } from "../../../Diagram/interfaces/Datapoints";
import { DatapointObserver } from "../../../Environment/model/DatapointObserver";
import { HorizontalLayer } from "../../interfaces/HorizontalLayer";
import SingleLineEditor from "../../../Controls/view/SingleLineEditor/SingleLineEditor";
import { ArrowDown, ArrowUp } from "../../../VisualElements/view/Arrows";

interface IProps {
    layer: HorizontalLayer;
    swapToPrev: () => void;
    swapToNext: () => void;
    delete: () => void;
}

export default class HorizontalLayerView extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
        this.height = props.layer.Height;
        this.dragHandler = new DefaultDragHandler(new DatapointObserver(
            () => { this.props.layer.ResizeHeight(this.height) },
            this.resize.bind(this),
            undefined));
    }

    private dragHandler: DragHandler;


    private element: HTMLElement | null = null;
    private getElement(): HTMLElement {
        if (this.element) {
            // this.descriptiveData.width = this.element.offsetWidth;
            return this.element;
        }
        this.element = document.getElementById(this.props.layer.Id);
        if (this.element) {
            // this.descriptiveData.width = this.element.offsetWidth;
            return this.element;
        } else {
            throw new Error("Element with Id " + this.props.layer.Id + " not found");
        }
    }

    private height: number;

    private resize(datapoint: Datapoint) {
        this.element = this.getElement();
        const height: number = parseInt(this.element.style.height, 10);
        this.height = height - datapoint.y;
        this.element.style.height = this.height  + "px";
    }



    private foo(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        this.dragHandler.handleDrag(e)
    }

    render() {
        return <div className="HorizontalLayer Layer">
            <div className="header">
                <div className="left">
                    <ArrowUp Action={() => this.props.swapToPrev()} />
                </div>
                <div className="center">
                    <SingleLineEditor Delete={this.props.delete} OnDidChange={(name: string) => this.props.layer.Rename(name)} Title={this.props.layer.Name} preSet={this.props.layer.Name} />
                </div>
                <div className="right">
                    <ArrowDown Action={() => this.props.swapToNext()} />
                </div>
            </div>
            <div id={this.props.layer.Id} className="body" style={{ height: this.props.layer.Height }} >
                <div className="resizeLine"
                    onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.foo(e)}
                />
            </div>
        </div>;
    }
}