import { DefaultNuml } from "../../model/impl/DefaultNuml";
import { ArrangeableRelationalNuml } from "../../interfaces/ArrangeableRelationalNuml";
import { RelationalProperty } from "../../interfaces/RelationalProperty";
import { RelationalMethod } from "../../interfaces/RelationalMethod";
import { RelationalConstructor } from "../../interfaces/RelationalConstructor";
import { Property } from "../../interfaces/Property";
import { Constructor } from "../../interfaces/Constructor";
import { Method } from "../../interfaces/Method";
import { Datapoint } from "../../../Diagram/interfaces/Datapoints";
import { Numl } from "../../interfaces/Numl";
import { ObserveableNuml } from "../../interfaces/ObserveableNuml";
import { RelationalEventMember } from "../../../EventMember/interfaces/RelationalEventMember";

export class DefaultArrangeableRelationalNuml
  implements ArrangeableRelationalNuml {
  private initialBias: number = 25;
  private bias: number = 10;

  private memberHeight: number = 27;

  private height: number = 20;
  public get Name(): string {
    return this.numl.Name;
  }
  public get Properties(): RelationalProperty[] {
    return this.relationalProperties;
  }
  public get Events(): RelationalEventMember[] {
    return this.relationalEvents;
  }
  public get Constructors(): RelationalConstructor[] {
    return this.relationalConstructors;
  }
  public get Methods(): RelationalMethod[] {
    return this.relationalMethods;
  }
  public get Expands(): string[] {
    return this.numl.Expands;
  }

  public get PositionOfCenter(): Datapoint {
    return {
      x: this.numl.Position.x + this.descriptiveData.width / 2,
      y: this.numl.Position.y + this.height,
    };
  }
  public get ExtendingDockingPoint(): Datapoint {
    return this.PositionOfCenter;
  }
  public get ExtendedDockingPoint(): Datapoint {
    return this.PositionOfCenter;
  }

  public get Position(): Datapoint {
    return this.numl.Position;
  }

  AddMember(member: Property | Constructor | Method): void {
    this.numl.AddMember(member);
    this.updatePoints();
  }
  AddMemberByString(s: string): void {
    this.numl.AddMemberByString(s);
    this.updatePoints();
  }

  private relationalProperties: RelationalProperty[] = [];
  private relationalEvents: RelationalEventMember[] = [];
  private relationalConstructors: RelationalConstructor[] = [];
  private relationalMethods: RelationalMethod[] = [];

  private updatePoints(): void {
    let lastY = this.Position.y + this.initialBias; // 40 10 10
    let x = this.Position.x + this.descriptiveData.width / 2;
    this.relationalProperties = this.numl.Properties.map((p) => {
      lastY += this.memberHeight;
      return {
        Name: p.Name,
        PositionOfCenter: { x: x, y: lastY },
        ReturnType: p.ReturnType,
        Type: p.Type,
      };
    });
    lastY += this.bias;
    this.relationalEvents = this.numl.Events.map((p) => {
      lastY += this.memberHeight;
      return {
        Name: p.Name,
        PositionOfCenter: { x: x, y: lastY },
        ParamTypes: p.ParamTypes,
        Type: p.Type,
      };
    });
    lastY += this.bias;
    this.relationalConstructors = this.numl.Constructors.map((c) => {
      lastY += this.memberHeight;
      return {
        Name: c.Name,
        ParamTypes: c.ParamTypes,
        PositionOfCenter: { x: x, y: lastY },
        Type: c.Type,
        ConcreteGenerics: c.ConcreteGenerics,
      };
    });

    lastY += this.bias;

    this.relationalMethods = this.numl.Methods.map((m) => {
      lastY += this.memberHeight;
      return {
        Name: m.Name,
        PositionOfCenter: { x: x, y: lastY },
        ParamTypes: m.ParamTypes,
        ReturnType: m.ReturnType,
        Type: m.Type,
      };
    });
  }

  MoveTo(x: number, y: number, width?: number) {
    if (width) {
      this.descriptiveData.width = width;
    }
    this.numl.MoveTo(x, y);
  }

  public extendingDockingPoint: Datapoint = { x: 0, y: 0 };
  public extendedDockingPoint: Datapoint = { x: 0, y: 0 };

  private descriptiveData: { width: number };

  public Id: string;

  constructor(numl: Numl | string, Position?: Datapoint) {
    if ((numl as any).AddMember) {
      this.numl = numl as Numl;
    } else {
      let position: Datapoint = { x: 10, y: 10 };
      if (Position) {
        position = Position;
      }
      this.numl = new DefaultNuml(numl as string, [], position);
    }
    this.Id = this.numl.Id;
    this.descriptiveData = { width: 100 };
    this.updatePoints();
  }
  SwapPropertyToPrev(index: number): void {
    this.numl.SwapPropertyToPrev(index);
    this.updatePoints();
  }
  SwapEventToPrev(index: number): void {
    this.numl.SwapEventToPrev(index);
    this.updatePoints();
  }
  SwapMethodToPrev(index: number): void {
    this.numl.SwapMethodToPrev(index);
    this.updatePoints();
  }
  SwapConstructorToPrev(index: number): void {
    this.numl.SwapConstructorToPrev(index);
    this.updatePoints();
  }
  SwapPropertyToNext(index: number): void {
    this.numl.SwapPropertyToNext(index);
    this.updatePoints();
  }
  SwapEventToNext(index: number): void {
    this.numl.SwapEventToNext(index);
    this.updatePoints();
  }
  SwapMethodToNext(index: number): void {
    this.numl.SwapMethodToNext(index);
    this.updatePoints();
  }
  SwapConstructorToNext(index: number): void {
    this.numl.SwapConstructorToNext(index);
    this.updatePoints();
  }

  public Rename(newName: string): void {
    this.numl.Rename(newName);
    this.updatePoints();
  }

  public DeleteMember(member: Property | Constructor | Method): void {
    this.numl.DeleteMember(member);
    this.updatePoints();
  }

  public RaisNext() {
    //@ts-ignore
    if ((this.numl as ObserveableNuml).RaiseNext) {
      (this.numl as ObserveableNuml).RaiseNext();
    }
  }

  private numl: Numl;
}
