import { Property } from "../../interfaces/Property";
import { EventMember } from "../../../EventMember/interfaces/EventMember";
import { Method } from "../../interfaces/Method";
import { Constructor } from "../../interfaces/Constructor";
import { MemberType } from "../../interfaces/MemberType";
import { DefaultType } from "../../../Type/model/impl/DefaultType";

export class MemberParser {
  public static ParseFromString(
    s: string
  ): Property | Constructor | Method | EventMember {
    let bracketIndex: number = s.indexOf("(");
    let backArrowIndex: number = s.indexOf("<=");
    let lastClosingBracketIndex: number = s.lastIndexOf(")");
    let colonIndex: number = s.lastIndexOf(":");

    if (bracketIndex === -1 && colonIndex === -1 && backArrowIndex === -1) {
      const errorMessage: string =
        "A colon ':' and/or a pair of brackets '()' must define a property, constructor, event, or method.";
      throw new Error(errorMessage);
    } else if (bracketIndex === -1) {
      return this.parseProperty(s);
    } else if (backArrowIndex !== -1) {
      return this.parseEvent(s);
    } else if (colonIndex === -1 || colonIndex < lastClosingBracketIndex) {
      return this.parseConstructor(s);
    } else {
      const property: Property = this.parseProperty(s);
      const constructor: Constructor = this.parseConstructor(s);
      return {
        Name: constructor.Name,
        ParamTypes: constructor.ParamTypes,
        Type: MemberType.Method,
        ReturnType: property.ReturnType,
      };
    }
  }

  private static parseConstructor(s: string): Constructor {
    const indexFirstBracket = s.indexOf("(");
    const name: string = s.substr(0, indexFirstBracket);
    const lastIndex: number = s.lastIndexOf(")");
    const params = s.substring(indexFirstBracket + 1, lastIndex);

    return {
      Name: name,
      ParamTypes: this.parseParams(params),
      Type: MemberType.Constructor,
      ConcreteGenerics: new DefaultType(s.substr(lastIndex + 1)).FurtherTypes,
    };
  }

  private static parseEvent(s: string): EventMember {
    const splits: string[] = s.split("<=");
    const indexFirstBracket = s.indexOf("(");
    const lastIndex: number = s.lastIndexOf(")");
    const params = s.substring(indexFirstBracket + 1, lastIndex);
    return {
      Name: splits[0].trim(),
      Type: MemberType.Event,
      ParamTypes: this.parseParams(params),
    };
  }

  private static parseProperty(s: string): Property {
    const splits: string[] = s.split(":");
    return {
      Name: splits[0],
      ReturnType: new DefaultType(splits[splits.length - 1]),
      Type: MemberType.Property,
    };
  }

  private static parseParams(s: string): DefaultType[] {
    s = s.trim();
    const r: string[] = [];
    const lvlUps: string = "<{[(";
    const lvlDowns: string = ">}])";
    let value: string = "";
    let lvl: number = 0;
    for (let i: number = 0, l: number = s.length; i < l; i++) {
      if (s[i] === "," && lvl === 0 && value.length > 0) {
        r.push(value);
        value = "";
      } else if (lvlUps.includes(s[i])) {
        lvl++;
        value += s[i];
      } else if (lvlDowns.includes(s[i])) {
        lvl--;
        value += s[i];
      } else if (s[i] === "=" && s[i + 1] === ">") {
        value += s[i++];
        value += s[i];
      } else {
        value += s[i];
      }
    }
    if (value.length > 0) {
      r.push(value);
    }
    return r.map((s) => new DefaultType(s));
  }
}
