import React, { ReactElement } from "react";
import PageBuilder from "../../../Page/view/PageBuilder";
import "./ChoiceButton.css"

import ChoiceButtonList from "./ChoiceButtonList";


interface IProps {
    Label: string | ReactElement;
    ListOfActions: { Label: string, Action: () => void }[]
}

interface IState {
    expanded: boolean;
}

export default class ChoiceButton extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { expanded: false };
    }

    handleOnClick(): void {
        if (!this.state.expanded) {
            PageBuilder.AddCloseListener(() => setTimeout(() => this.close(), 100));
        }
        this.setState({ expanded: !this.state.expanded })
    }

    extendAction(foo: () => void): () => void {
        return () => {
            this.handleOnClick()
            foo();
        }
    }

    close() {
        this.setState({ expanded: false })
    }

    private listOfActions: { Label: string, Action: () => void }[] = this.props.ListOfActions.map(action => { return { Label: action.Label, Action: this.extendAction(action.Action) } })


    render(): JSX.Element {
        if (this.state.expanded) {
            return (
                <div className="Choice Button">
                    <div onClick={() => this.handleOnClick()}>
                        {this.props.Label}
                    </div>
                    <ChoiceButtonList Actions={this.listOfActions} />
                </div>);
        } else {
            return (
                <div className="Choice Button" onClick={() => this.handleOnClick()}>
                    {this.props.Label}
                </div>);
        }

    }

}