import React from "react";
import { Type } from "../../../Type/interfaces/Type";
import "./Type.css";

interface IProps {
    type: Type;
}


export default (props: IProps): JSX.Element => {
    return (
        <div className={"Type valid"}>
            { props.type.FullType.replace(/\s/g, "\u00A0") }
        </div >
    );
};