import React from "react";
import { Documentation } from "../interfaces/Documentation";
import DeadNumlView from "../../Numl/view/Numl/DeadNuml";
import DeadEnum from "../../Enum/view/DeadEnum";
import { DefaultNuml } from "../../Numl/model/impl/DefaultNuml";
import { RelationType } from "../../Diagram/interfaces/RelationType";
import HorizontalArrow from "../../Arrow/view/HorizontalArrow";
import { Numl } from "../../Numl/interfaces/Numl";
import { DefaultEnum } from "../../Enum/model/impl/DefaultEnum";
import DeadViewDefinition from "../../ViewDefinition/view/DeadViewDefinition";
import { DefaultViewDefinition } from "../../ViewDefinition/model/DefaultViewDefinition";
import { DefaultType } from "../../Type/model/impl/DefaultType";
import { ViewDefinition } from "../../ViewDefinition/interfaces/ViewDefinition";
import { Enum } from "../../Enum/interfaces/Enum";
import CodeViewer from "../../CodeViewer/view/CodeViewer";

const enums: Enum[] = [
  new DefaultEnum("Enum", { x: 100, y: 10 }, ["Value1", "Value2"]),
];
const views: ViewDefinition[] = [
  new DefaultViewDefinition(
    "View",
    { x: 100, y: 10 },
    ["Dependency1", "Dependency2"].map((d) => new DefaultType(d))
  ),
];
const numls: Numl[] = [
  // 0
  new DefaultNuml("OtherInterface<T, string>", [], {
    x: 50,
    y: 0,
  }),
  // 1
  new DefaultNuml("Interface<T>", ["OtherInterface<T, string>"], {
    x: 0,
    y: 150,
  }),
  // 2
  (function () {
    let numl: Numl = new DefaultNuml("Interface<T>", [], {
      x: 10,
      y: 0,
    });
    numl.AddMemberByString("Class<Generic>(Type1, Type2)<ConcretizeGeneric>");
    numl.AddMemberByString("Prop: T");
    return numl;
  })(),
  //3
  (function () {
    let numl: Numl = new DefaultNuml("Interface", [], {
      x: 50,
      y: 0,
    });
    numl.AddMemberByString("Name<T>(T, string):T");
    return numl;
  })(),
  //4
  (function () {
    let numl: Numl = new DefaultNuml("Interface", [], {
      x: 50,
      y: 0,
    });
    numl.AddMemberByString("Name:Type");
    return numl;
  })(),
  //4
  (function () {
    let numl: Numl = new DefaultNuml("Interface", [], {
      x: 50,
      y: 0,
    });
    numl.AddMemberByString("Property:Type");
    numl.AddMemberByString("Constructor()");
    numl.AddMemberByString("Constructor(Type)");
    numl.AddMemberByString("OtherConstructor(Type)");
    numl.AddMemberByString("Foo(ParamType1):ReturnTypeType");
    return numl;
  })(),
];

export const DocumentationInfo: Documentation = {
  header: "NUML",
  sections: [
    {
      header: "General",
      articles: [
        {
          header: "Diagram Participants",
          paragraphs: [
            "The diagram represents the layer model. The domain dimension is horizontal, and the technical dimension is vertical. At the end of each dimension, you can add a new domain layer on the right and a technical layer at the bottom.",
            "By right-clicking in a diagram, you can add an enum-, a view- or a NUML-definition",
            /*"Enum",
            "NUML",
            "View",*/
          ],
        },
      ],
    },
    {
      header: "Enum",
      articles: [
        {
          header: "Values",
          paragraphs: [
            "An enum is variable on its name and values. So you have to give the enum a name, and you can add and remove values.",
          ],
          example: (
            <div style={{ position: "relative" }}>
              <DeadEnum enum={enums[0]} />
            </div>
          ),
          codeSnippets: <CodeViewer diagramElements={[enums[0]]} />,
        },
      ],
    },
    {
      header: "View",
      articles: [
        {
          header: "Dependencies",
          paragraphs: [
            "A view is variable on its name and dependencies. So you have to give the view a name, and you can add and remove dependencies.",
            "In Java and C#, the view will resolve in a standard class where the dependencies will be parameters in the constructor.",

            "In TypeScript, there is actual React used for the view components. The dependency will be added to the properties of the interface IProps.",
          ],
          example: (
            <div style={{ position: "absolute" }}>
              <DeadViewDefinition view={views[0]} />
            </div>
          ),
          codeSnippets: <CodeViewer diagramElements={[views[0]]} />,
        },
      ],
    },
    {
      header: "NUML",
      articles: [
        {
          header: "Name - Interface",
          paragraphs: [
            "The name will resolve to the name of the interface.",
            "As you are probably used to, you can make the interface generic by using '<identifier>'.",
            "Like in C#, you can extend an interface by using a colon. That will look like 'Interface: OtherInterface'",
            "And of course, you can combine it all: 'Interface<T>: OtherInterface<T, string>'",
          ],
          example: (
            <div style={{ position: "absolute", height: 1000 }}>
              <DeadNumlView numl={numls[0]} />
              <DeadNumlView numl={numls[1]} />
              <HorizontalArrow
                RelationType={RelationType.Dependency}
                Source={{ x: 150, y: 10 }}
                Target={{ x: 150, y: 160 }}
              />
            </div>
          ),
          codeSnippets: <CodeViewer diagramElements={[numls[0], numls[1]]} />,
        },
        {
          header: "Constructor/Class",
          paragraphs: [
            "The minimum syntax for adding a Constructor/Class is 'Name()'. It is not possible to use a colon for inheritance. If you used a colon here, it would identify that as a method, and your inheritance would be the return type.",
            "In the planning process is no inheritance for classes/constructors intended, but you can add that fast to the code's implementation process.",
            "Constructors/Classes with the same name will resolve together in one class.",
            "You can add params by adding Types separated by a comma in the brackets. You do not have to define any names for the parameters. A definition with two parameters looks like 'Name(TypeOne, TypeTwo)'.",
            "There are two types of generics. You will probably be used to the first type. A definition would look like the name <GenericIdentifier>(). With that definition, you can use the identifier in the properties and methods. I have called the second type 'concrete generic', because this concretizes the generic from the interface. Therefore the generic definition is after the brackets for the parameters. Which will look like name ()<ConcreteGeneric> For example, if you have a defined generic interface 'Interface<T>' and you add a constructor/class as 'Class()<string>', the generated class will implement the interface with 'string' as the generic.",
            "Even if you probably would not have the case where you will define everything, but it will look like: 'Class<ClassGeneric>(Type1, Type2)<ConcretizeGenericInterface>'.",
          ],
          example: (
            <div style={{ position: "absolute", height: 1000 }}>
              <DeadNumlView numl={numls[2]} />
            </div>
          ),
          codeSnippets: <CodeViewer diagramElements={[numls[2]]} />,
        },
        {
          header: "Method",
          paragraphs: [
            "A standard implementation looks like 'Name(ParamType1, ParamType2):ReturnType'. If there is no return type defined, then it will be recognized as a constructor/class. Like in the params for the constructor, you do not have to define parameter names!",
            "Generic should here work as well 'Name<GenericIdentifier>(GenericIdentifier): GenericIdentifier'",
          ],
          example: (
            <div style={{ position: "absolute", height: 1000 }}>
              <DeadNumlView numl={numls[3]} />
            </div>
          ),
          codeSnippets: <CodeViewer diagramElements={[numls[3]]} />,
        },
        {
          header: "Property",
          paragraphs: [
            "You can add Properties to a NUML definition. The syntax is like 'Name: Type'. 'Title: string' as an example.",
          ],
          example: (
            <div style={{ position: "absolute", height: 1000 }}>
              <DeadNumlView numl={numls[4]} />
            </div>
          ),
          codeSnippets: <CodeViewer diagramElements={[numls[4]]} />,
        },
        {
          header: "Full example of NUML",
          paragraphs: [
            "A typical example with a property, two classes, three constructors, and one method can look.",
          ],
          example: (
            <div style={{ position: "absolute", height: 1000 }}>
              <DeadNumlView numl={numls[5]} />
            </div>
          ),
          codeSnippets: <CodeViewer diagramElements={[numls[5]]} />,
        },
      ],
    },
  ],
};
