import React from "react";
import { Environment } from "../../../Environment/model/Environment";
import PageBuilder from "../../../Page/view/PageBuilder";
import "./LazyInput.css";

interface IProps {
    onSubmit: (input: string) => void;
    label: string | JSX.Element;
    preSet?: string;
    yieldMode: boolean;
}

interface IState extends IProps {
    value: string;
    isActive: boolean;
}

export default class LazyInput extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = { ...props, ...{ value: props.preSet ? props.preSet : "", isActive: false } };
    }

    activate(event: React.MouseEvent<HTMLDivElement>): void {
        Environment.preventDefault(event)
        this.setState({ isActive: true });
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ value: event.target.value });
    }

    submit(): void {
        try{
            this.props.onSubmit(this.state.value);
        } catch (err){
            PageBuilder.ShowError(err.message);
        }
        if (this.props.yieldMode) {
            this.setState({ value: "" });
        } else {
            this.setState({ preSet: this.state.value, isActive: false })
        }
    }

    handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
        if (event.key === "Enter") {
            this.submit();
        }
    }
    handleBlur(event: React.FocusEvent<HTMLInputElement>): void {
        if (event.target.value === "") {
            this.reset();
        }
    }

    reset(): void {
        this.setState({ isActive: false, value: "" });
    }

    render(): JSX.Element {
        if (this.state.isActive) {
            return (
                <div className="LazyInput Active">
                    <input type="text" autoFocus={true}
                        value={this.state.value}
                        onChange={this.handleChange.bind(this)}
                        onKeyPress={this.handleKeyPress.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                    />
                    <div className="exit" onClick={this.reset.bind(this)}>X</div>
                </div>
            );
        } else {
            return (
                <div className="LazyInput InActive" onClick={(event: React.MouseEvent<HTMLDivElement>) => this.activate(event)}>
                    <div style={{ margin: "auto" }}>
                        {this.props.label}
                    </div>
                </div>
            );
        }

    }
}