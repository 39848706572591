import React from "react";
import SingleLineEditor from "../../Controls/view/SingleLineEditor/SingleLineEditor";

import "./Thumbnail.css"

interface IProps {
    Title: string;
    Delete(): void;
    Rename(s: string): void;
    OnClick(): void;
}

export default (props: IProps) =>
    <div className="Thumbnail"
        onClick={() => props.OnClick()}
    >
        <SingleLineEditor Title={props.Title} preSet={props.Title}
            Delete={() => props.Delete()}
            OnDidChange={(s: string) => props.Rename(s)}
        />
        <div className="picture">
            <div className="fakePicture">
                Thumbnail
            </div>
        </div>
    </div>