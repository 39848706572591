import React from "react";

import "./Video.css";

interface IProps {
  videoSrc: string;
  width?: number;
  heigth?: number;
  autorun?: boolean;
}

interface IState {
  isClicked: boolean;
}

export default class Video extends React.Component<IProps, IState> {
  /**
   *
   */
  constructor(props: IProps) {
    super(props);
    this.state = { isClicked: props.autorun ? true : false };
  }

  render() {
    let style = { height: this.props.heigth, width: this.props.width };
    if (!this.state.isClicked) {
      return (
        <div style={style} onClick={() => this.setState({ isClicked: true })}>
          <div className="fakethumbnail">
            <div className="overlay">
              <span>How to ...</span>
              <div className="play-button">
                <span className="material-icons">play_arrow</span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={style}>
          <video style={style} controls autoPlay src={this.props.videoSrc} />
        </div>
      );
    }
  }
}
