import React from "react";
import "./Layer.css";
import { VerticalLayer } from "../../interfaces/VerticalLayer";
import SingleLineEditor from "../../../Controls/view/SingleLineEditor/SingleLineEditor";
import { DefaultDragHandler } from "../../../Environment/handler/DefaultDragHandler";
import { DatapointObserver } from "../../../Environment/model/DatapointObserver";
import { DragHandler } from "../../../Environment/interface/DragHandler";
import { Datapoint } from "../../../Diagram/interfaces/Datapoints";
import { ArrowLeft, ArrowRight } from "../../../VisualElements/view/Arrows";


interface IProps {
    layer: VerticalLayer;
    swapToPrev: () => void;
    swapToNext: () => void;
    delete: () => void;
}

export default class VerticalLayerView extends React.Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
        this.width = props.layer.Width;
        this.dragHandler = new DefaultDragHandler(new DatapointObserver(
            () => this.props.layer.ResizeWidth(this.width),
            this.resize.bind(this),
            undefined));
    }

    private dragHandler: DragHandler;


    private element: HTMLElement | null = null;
    private getElement(): HTMLElement {
        if (this.element) {
            // this.descriptiveData.width = this.element.offsetWidth;
            return this.element;
        }
        this.element = document.getElementById(this.props.layer.Id);
        if (this.element) {
            // this.descriptiveData.width = this.element.offsetWidth;
            return this.element;
        } else {
            throw new Error("Element with Id " + this.props.layer.Id + " not found");
        }
    }

    private width: number;
    private resize(datapoint: Datapoint) {
        this.element = this.getElement();
        const width: number = parseInt(this.element.style.width, 10);
        this.width = width - datapoint.x;
        this.element.style.width = this.width + "px";
    }


    private foo(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        this.dragHandler.handleDrag(e)
    }

    render() {
        return (<div className="VerticalLayer Layer" >
            <div className="header">
                <div className="left">
                    <ArrowLeft Action={() => this.props.swapToPrev()} />
                </div>
                <div className="center">
                    <SingleLineEditor Delete={this.props.delete} OnDidChange={(name: string) => this.props.layer.Rename(name)} Title={this.props.layer.Name} preSet={this.props.layer.Name} />
                </div>
                <div className="right">
                    <ArrowRight Action={() => this.props.swapToNext()} />
                </div>
            </div>
            <div id={this.props.layer.Id} className="body" style={{ width: this.props.layer.Width }}>
                <div className="resizeLine"
                    onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.foo(e)}
                />
            </div>
        </div >
        );
    }
}
