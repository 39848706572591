import { ArrayHelper } from "../../../ArrayHelper/static/impl/ArrayHelper";
import { ElementType } from "../../../Environment/enums/ElementType";
import { IFile } from "../../../FileStructure/interfaces/IFile";
import { DefaultFile } from "../../../FileStructure/model/impl/DefaultFile";
import { Constructor } from "../../../Numl/interfaces/Constructor";
import { ImportInformation } from "../../../Numl/interfaces/ImportInformation";
import { Method } from "../../../Numl/interfaces/Method";
import { Property } from "../../../Numl/interfaces/Property";
import { Type } from "../../../Type/interfaces/Type";
import { DefaultType } from "../../../Type/model/impl/DefaultType";
import { ClassExportInformation } from "../../interfaces/ClassExportInformation";
import { EnumExportInformation } from "../../interfaces/EnumExportInformation";
import { ExportInformation } from "../../interfaces/ExportInformation";
import { FileCreator2 } from "../../interfaces/FileCreator2";
import { InterfaceExpotInformation } from "../../interfaces/InterfaceExportInformation";
import { ViewExportInformation } from "../../interfaces/ViewExportInformation";

export class CSharpFileCreator implements FileCreator2 {
  private fileExtension = ".cs";

  constructor(private diagramName: string) {}

  CreateInterfaceFile(exportInformation: InterfaceExpotInformation): IFile {
    let content: string = this.stringifyImports(exportInformation.Imports);
    content +=
      "namespace " +
      this.diagramName +
      "." +
      exportInformation.VerticalDimension +
      ".Interfaces\n{\n";
    if (exportInformation.Expands.length === 0) {
      content += "\n\tpublic interface " + exportInformation.Name + "\n\t{\n";
    } else {
      content +=
        "\n\tpublic interface " +
        exportInformation.Name +
        " : " +
        exportInformation.Expands.join(", ") +
        "\n\t{\n";
    }
    content += this.propsInterfaceBlock(exportInformation.Properties);
    content += this.stringifyInterfaceMethods(exportInformation.Methods);
    content += "\n\t}\n}\n";

    return new DefaultFile(this.toFileName(exportInformation.Name), content);
  }
  CreateEnumFile(exportInformation: EnumExportInformation): IFile {
    let content: string = this.GetNamespaceString(exportInformation);
    content += "\n\tpublic enum " + exportInformation.Name + "\n\t{\n\t\t";
    content += exportInformation.Values.join(",\n\t\t");
    content += "\n\t}\n}\n";

    return new DefaultFile(this.toFileName(exportInformation.Name), content);
  }

  CreateClassFile(exportInformation: ClassExportInformation): IFile {
    let content = this.stringifyImports(exportInformation.Imports);
    content += this.GetNamespaceString(exportInformation);
    content +=
      "\n\tinternal class " +
      exportInformation.Constructors[0].Name +
      " : " +
      exportInformation.Name +
      "\n\t{\n";
    content += this.propsClassBlock(exportInformation.Properties);
    content += this.strigifyContstuctors(exportInformation.Constructors);
    content += this.stringifyClassMethods(exportInformation.Methods);
    content += "\n\t}\n}\n";

    return new DefaultFile(
      this.toFileName(exportInformation.Constructors[0].Name),
      content
    );
  }

  CreateViewFile(exportInformation: ViewExportInformation): IFile {
    let content: string = this.stringifyImports(exportInformation.Imports);
    content += this.GetNamespaceString(exportInformation);
    content += "\n\tpublic class " + exportInformation.Name + "\n\t{\n";
    content +=
      "\n\t\tpublic " +
      new DefaultType(exportInformation.Name).BaseType +
      this.stringifyParams(exportInformation.Depdencies) +
      " {  }";

    content += "\n\t}\n}\n";
    return new DefaultFile(this.toFileName(exportInformation.Name), content);
  }

  GetNamespaceString(exportInformation: ExportInformation): string {
    return (
      "namespace " +
      this.diagramName +
      "." +
      exportInformation.VerticalDimension +
      "." +
      exportInformation.HorizontalDimension +
      "\n{\n"
    );
  }

  private propsInterfaceBlock(props: Property[]): string {
    if (props.length === 0) {
      return "";
    }
    return (
      "\n\t\t" +
      props
        .map((p) => p.ReturnType.FullType + " " + p.Name + " { get; }")
        .join("\n\t\t") +
      "\n"
    );
  }

  private propsClassBlock(props: Property[]): string {
    if (props.length === 0) {
      return "";
    }
    return (
      "\n\t\t" +
      props
        .map((p) => "public " + p.ReturnType.FullType + " " + p.Name + ";")
        .join("\n\t\t") +
      "\n"
    );
  }

  private stringifyImports(imports: ImportInformation[]): string {
    return (
      "using System;\n" +
      imports
        .map((i) => {
          let horizontaldimension: string = i.HorizontalDimension;
          if (i.Type === ElementType.Interface) {
            horizontaldimension = "Interfaces";
          }
          return (
            "using " +
            this.diagramName +
            "." +
            i.VerticalDimension +
            "." +
            horizontaldimension +
            ";"
          );
        })
        .filter(ArrayHelper.UniqueFilter)
        .join("\n") +
      "\n\n"
    );
  }

  private toFileName(name: string): string {
    return new DefaultType(name).BaseType + this.fileExtension;
  }

  private stringifyInterfaceMethods(methods: Method[]): string {
    return (
      "\n" +
      methods
        .map(
          (m) =>
            "\t\t" +
            m.ReturnType.FullType +
            " " +
            m.Name +
            this.stringifyParams(m.ParamTypes) +
            ";"
        )
        .join("\n") +
      "\n"
    );
  }

  private stringifyClassMethods(methods: Method[]): string {
    return (
      "\n" +
      methods
        .map(
          (m) =>
            "\n\t\tpublic " +
            m.ReturnType.FullType +
            " " +
            m.Name +
            this.stringifyParams(m.ParamTypes) +
            "\n\t\t{\n\t\t\tthrow new NotImplementedException();\n\t\t}\n"
        )
        .join("\n") +
      "\n"
    );
  }

  private strigifyContstuctors(ctors: Constructor[]): string {
    return (
      "\n" +
      ctors
        .map(
          (c) =>
            "\t\t" + c.Name + this.stringifyParams(c.ParamTypes) + " {  }\n"
        )
        .join("\n") +
      "\n"
    );
  }

  private stringifyParams(params: Type[]) {
    const usedParamNames: string[] = params.map((p) => p.BaseType);
    const stringifiedParams: string[] = params.map((p) => {
      let baseName: string = p.BaseType[0].toLowerCase() + p.BaseType.substr(1);
      let paramName: string = baseName;
      for (let i: number = 1; usedParamNames.includes(paramName); i++) {
        paramName = baseName + i;
      }
      usedParamNames.push(paramName);
      let param: string = p.FullType + " " + paramName;
      if (p.IsOptional) {
        param += " = null";
      }
      return param;
    });
    return "(" + stringifiedParams.join(", ") + ")";
  }
}
