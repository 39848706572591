import React from "react";
import "./FileView.css";
import { IFile } from "../interfaces/IFile";
import { CopyBlock, dracula } from "react-code-blocks";
interface IProps {
  file: IFile;
}

export default (props: IProps) => {
  /* function fileExtensionToLanguage(s: string): string {
    switch (s) {
      case ".cs":
        return "csharp";

      default:
        return s.substr(1);
    }
  }*/
  const fileName: string = props.file.Name;
  const fileExtension: string = fileName.substr(fileName.lastIndexOf("."));
  const language: string = fileExtension.substr(1);

  console.log("file extension: " + language);

  return (
    <div className="FileView">
      <div className="fileName">{fileName}</div>
      <CopyBlock
        text={props.file.Content}
        language={language}
        theme={dracula}
      />
    </div>
  );
};
